<template>
	<v-tooltip top color="var(--gris-secondary)" transition="none">
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				id="hide-all-selected-areas"
				text
				class="maphideallselectedareas"
				@click="maphideallselectedareas"
				:disabled="selectedAreas.length === 0"
				v-bind="attrs"
				v-on="on"
			>
				<v-icon class="fw-bold" size="12">fas fa-broom </v-icon>
			</v-btn>
		</template>
		<span class="fs-regular">{{ $t('map.hideallselectedareas') }}</span>
	</v-tooltip>
</template>

<script>
import ol2map from '@/components/map/sections/map/subcomponents/ol2map';
import constants from '@/utils/constants';

export default {
	name: 'maphideallselectedareas',
	props: {
		width: {
			default: 32
		},
		source: {
			type: String,
			default: 'others'
		}
	},
	computed: {
		selectedAreas() {
			return this.$store.getters.getSelectedAreas;
		}
	},
	methods: {
		maphideallselectedareas() {
			this.$store.commit('clearSelectedArea');
			const areaNotificationLayer = ol2map.getLayerById(constants.AREA_NOTIFICATION);
			if (areaNotificationLayer && areaNotificationLayer.getSource) {
				const source = areaNotificationLayer.getSource();
				if (source && source.getFeatures().length > 0) {
					source.clear();
					this.$puiNotify.info(this.$t('map.areaAllDeactivatedSelected'));
				}
			}
		}
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../styles/eiffel-variables.pcss';

.maptools {
	& .maphideallselectedareas {
		margin: 0px;
		width: 32px;
		min-width: 32px !important;
		height: 32px;
		background-color: let(--negative);
	}
}
</style>
