import { search, getAreas, getDatasourcetype, isSuperAdmin, permissionsObject } from '@/api/common';

export default {
	methods: {
		async reloadFullStore() {
			let userProps = await this.storeUserProperties();
			let store = await this.reloadCriticalStore();
			//console.log('reloadFullStore', userProps, store);
			return { userProps, store };
		},
		async reloadCriticalStore() {
			let superAdmin = isSuperAdmin(this.session.profiles[0]);
			this.storeUserProperties();
			let result = await Promise.all([
				this.storeActiveAreas(this.userProperties),
				this.storeActiveDatasources(this.userProperties),
				this.storeActiveParameters(this.userProperties.organizationid, superAdmin),
				this.storeParameterStatus(superAdmin),
				this.storeIcons(),
				this.storeHasDevices(this.userProperties, superAdmin)
				/* this.storeHasAqiPoints(this.userProperties, superAdmin), */
				/* this.storeHasLayers(this.userProperties, superAdmin), */
			]);

			//console.log('result critical mappanel', result);
			return result;
		},
		async reloadSecondaryStore() {
			this.storeFormFilters();
			this.storeSeverities();
			this.storeActiveSatelliteParameters();
			this.storeActions();
			this.storeActionsLogs();
			return;
		},
		async storeUserProperties() {
			const filterUser = {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'usr', op: 'eq', data: this.session.usr },
					{ field: 'idiom', op: 'eq', data: this.session.language }
				]
			};

			const body = {
				model: 'pmusers',
				filter: filterUser,
				rows: 1
			};

			const { data } = await search(body);
			data.data.length > 0 ? this.$store.commit('setUserProperties', data.data[0]) : this.$store.commit('setUserProperties', null);

			return data.data;
		},
		async storeRefreshTime() {
			let filter = null;
			if (!isSuperAdmin(this.session.profiles[0])) {
				filter = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'pmorganizationid', op: 'eq', data: this.session.organizationid },
						{ field: 'refreshtime', op: 'gt', data: 0 }
					]
				};
			}
			const body = {
				model: 'pmrefreshtime',
				filter: filter,
				rows: 1
			};

			const { data } = await search(body);
			if (data.data[0]) {
				var deliveryperiod = data.data[0].refreshtime;
			} else {
				deliveryperiod = null;
			}
			//console.log('refresh time deliveryperiod', deliveryperiod, data.data, body);
			this.$store.commit('setRefreshTime', deliveryperiod);
			return !!data.data[0];
		},
		async storeActiveAreas(userProperties) {
			let result = await getAreas(this.session.language, userProperties);
			this.$store.commit('setAreas', result.data);
			//console.log('mappanel areas', result);
			return true;
		},
		async storeActiveDatasources(userProperties) {
			let result = await getDatasourcetype(this.session.language, userProperties);
			this.$store.commit('setDatasourcetype', result.data);
			//console.log('mappanel datasources', result);
			return true;
		},
		async storeActiveParameters(organizationid, superAdmin) {
			var rulesFilter = [];

			if (superAdmin) {
				rulesFilter = [{ field: 'disabled', op: 'eq', data: 0 }];
			} else {
				rulesFilter = [
					{ field: 'pmorganizationid', op: 'eq', data: organizationid },
					{ field: 'disabled', op: 'eq', data: 0 }
				];
			}

			let body = {
				model: 'vluppmparameterdatasourcetype',
				filter: {
					groups: [],
					groupOp: 'and',
					rules: rulesFilter
				},
				rows: 1000
			};
			let { data } = await search(body);
			//console.log('After get parameters mapp', data.data, superAdmin, organizationid);
			if (data) {
				data.data.sort((a, b) => {
					const acronymA = a.acronym.toUpperCase();
					const acronymB = b.acronym.toUpperCase();
					if (acronymA < acronymB) {
						return -1;
					}
					if (acronymA > acronymB) {
						return 1;
					}
					return 0; // Objects have the same acronym, no need to change their order
				});

				//console.log('After get parameters', data.data);
				this.$store.commit('setParameters', data.data);
			} else {
				this.$store.commit('setParameters', []);
				//console.log('no parameter data');
			}

			return true;
		},
		async storeActiveSatelliteParameters() {
			let rulesFilter = [{ field: 'disabled', op: 'eq', data: 0 }];

			let body = {
				model: 'pmsatelliteparameter',
				filter: {
					groups: [],
					groupOp: 'and',
					rules: rulesFilter
				},
				rows: 1000
			};
			let { data } = await search(body);
			//console.log('After get SAT parameters mapp', data.data);
			if (data) {
				data.data.sort((a, b) => {
					const acronymA = a.acronym.toUpperCase();
					const acronymB = b.acronym.toUpperCase();
					if (acronymA < acronymB) {
						return -1;
					}
					if (acronymA > acronymB) {
						return 1;
					}
					return 0; // Objects have the same acronym, no need to change their order
				});

				//console.log('After get parameters', data.data);
				this.$store.commit('setSatelliteParameters', data.data);
			} else {
				this.$store.commit('setSatelliteParameters', []);
				//console.log('no parameter data');
			}

			return true;
		},
		async storeParameterStatus(superAdmin) {
			let filter;
			if (!superAdmin) {
				filter = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid },
						{ field: 'disabled', op: 'eq', data: 0 }
					]
				};
			} else {
				filter = {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'disabled', op: 'eq', data: 0 }]
				};
			}

			const body = {
				model: 'pmstatus',
				filter: filter,
				rows: 1000
			};

			const { data } = await search(body);
			this.$store.commit('setParameterStatus', data.data);
			//console.log('store parameter status', data.data);
			return true;
		},
		async storeHasDevices(userProperties, superAdmin) {
			var filterDevice = null;
			if (!superAdmin) {
				filterDevice = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'pmorganizationid', op: 'eq', data: userProperties.organizationid },
						{ field: 'disabled', op: 'eq', data: 0 }
					]
				};
			}

			let body = {
				model: 'vlupdevicespatialmodel',
				filter: filterDevice,
				rows: 1000
			};
			let { data } = await search(body);
			//this.$store.commit('setDevices', data.data);
			console.log('setDevicesData', data.data);
			this.$store.commit('setDevicesData', data.data);
			this.$store.commit('setHasDevices', Object.keys(data.data).length > 0);

			return true;
		},
		async storeHasAqiPoints(userProperties, superAdmin) {
			if (this.userProperties.showaqi) {
				var filterAqiPoint = null;
				if (!superAdmin) {
					filterAqiPoint = {
						groups: [],
						groupOp: 'and',
						rules: [
							{ field: 'pmorganizationid', op: 'eq', data: userProperties.organizationid },
							{ field: 'disabled', op: 'eq', data: 0 }
						]
					};
				}

				let body = {
					model: 'pmaqipoints',
					filter: filterAqiPoint,
					rows: 1000
				};
				let { data } = await search(body);
				this.$store.commit('setAqiPoints', data.data);
				this.$store.commit('setHasAqiPoints', Object.keys(data.data).length > 0);
				return true;
			} else {
				return false;
			}
		},
		async storeHasLayers(userProperties, superAdmin) {
			let filter = null;
			if (!superAdmin) {
				filter = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'pmorganizationid', op: 'eq', data: userProperties.organizationid },
						{ field: 'disabled', op: 'eq', data: 0 },
						{ field: 'datasourcetypedisabled', op: 'eq', data: 0 }
					]
				};
			}

			let body = {
				model: 'pmspatialtables',
				filter: filter,
				rows: 1000
			};
			let { data } = await search(body);
			this.$store.commit('setHasLayers', data.data.length > 0);

			return true;
		},
		async storeFormFilters() {
			let bodyFilter = {
				model: 'pmfiltercondition',
				rows: 1000,
				filter: {
					groups: [],
					groupOp: 'and',
					rules: []
				}
			};
			let bodyAreaDef = {
				model: 'pmareadefinition',
				rows: 1000,
				filter: {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }]
				}
			};

			let dataFilter = await search(bodyFilter);
			let dataDefinition = await search(bodyAreaDef);
			//console.log('After get data - Filter', dataFilter.data.data);
			//console.log('After get data - Area Def', dataDefinition.data.data);
			this.$store.commit('setFormFilters', dataFilter.data.data);
			this.$store.commit('setAreaDefinitions', dataDefinition.data.data);
			return true;
		},
		async storeSeverities() {
			let filter = {
				groups: [],
				groupOp: 'and',
				rules: []
			};

			let body = {
				model: 'pmseverity',
				rows: 1000,
				filter: filter
			};

			let { data } = await search(body);
			//console.log('After get data - Severity', data.data, body);
			this.$store.commit('setSeverities', data.data);
			return true;
		},
		async storeIcons() {
			let body = {
				model: 'vluppmicons',
				filter: {},
				rows: 1000
			};
			let { data } = await search(body);
			//console.log('After get ALL icons: ', data.data);
			this.$store.commit('setIcons', data.data);
			return data;
		},
		async storeActions() {
			let body = {
				model: 'vluppmactionsdatasourcetype',
				filter: {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }],
					rows: 1000
				}
			};
			let { data } = await search(body);
			//console.log('After get data - Actions', data.data);
			this.$store.commit('setActions', data.data);
		},
		async storeActionsLogs() {
			let body = {
				model: 'pmactionslog',
				filter: {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }],
					rows: 1000
				}
			};
			let { data } = await search(body);
			//console.log('After get data - Actions Logs', data.data);
			this.$store.commit('setActionsLogs', data.data);
		},
		storePermissions() {
			this.$store.commit('setPermissions', permissionsObject(this.$store.getters.getSession.functionalities));
		}
	}
};
