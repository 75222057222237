<template>
	<v-tooltip top :color="tooltipColor" transition="none" v-if="$store.getters.getPermissions['APP_EXCEL_EXPORT']">
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				class="pa-1 elevation-0 export-btn"
				:class="spacingClass"
				:color="bgColor"
				@click="
					() => {
						$emit('customEvent', 'test');
						if (advancedPropsObj) {
							downloadExcelAdvanced();
						} else if (mobilePropsObj) {
							downloadExcelMobile();
						} else {
							option && option.series.length > 1 ? downloadExcelMulti() : downloadExcel();
						}
					}
				"
				:height="height"
				:min-width="width"
				v-bind="attrs"
				v-on="on"
				:disabled="saving || !isExportable"
			>
				<i v-if="!saving" class="fas fa-file-export" :style="{ color: iconColor }"></i>
				<v-progress-circular v-else indeterminate style="width: 24px" :style="{ color: iconColor }"></v-progress-circular>
			</v-btn>
		</template>
		<span class="fs-regular">{{ $t('map.exportExcel') }}</span>
	</v-tooltip>
</template>

<script>
import { formatTimestamp, getStatusProperties } from '@/api/common';
import HistoricChartMixin from '@/mixins/HistoricChartMixin';
import { trackEvent } from '@/plugins/matomo';

import constants from '@/utils/constants';
const ExcelJS = require('exceljs');
export default {
	name: 'EchartExportExcel',
	mixins: [HistoricChartMixin],
	props: {
		option: {
			required: true
		},
		designOption: {
			type: Object,
			required: true
		},
		pollutant: {
			type: Object,
			required: false
		},
		dateInterval: {
			required: true
		},
		tooltipColor: {
			type: String,
			default: 'var(--gris-secondary)'
		},
		bgColor: {
			type: String,
			default: 'var(--azul-primary-100)'
		},
		iconColor: {
			type: String,
			default: '#fff'
		},
		height: {
			default: 38
		},
		width: {
			default: 42
		},
		chartInstance: {
			type: Object,
			required: false
		},
		spacingClass: {
			type: String,
			default: 'mb-1'
		},
		advancedPropsObj: {
			type: Object
			/* {categories: [], values: {}} */
		},
		mobilePropsObj: {
			type: Object
		},
		isExportable: {
			type: Boolean,
			default: true,
			required: false
		},
		zoomedProps: {
			type: Array,
			required: false
		}
	},
	data() {
		return {
			saving: false,
			advancedProps: null,
			mobileProps: null
		};
	},
	methods: {
		async downloadExcel() {
			console.log('download EXCEL', this.option, this.zoomedProps, this.designOption);
			this.saving = true;
			if (this.option) {
				let ws_data = [];
				let headerRow = [];
				let periodRow = [];
				let columnsConfig = [];
				let categories = null;
				let appData = this.$store.getters.getApplication;
				// Acceder a los datos del gráfico
				let option = this.option;
				if (this.zoomedProps && this.zoomedProps.length > 0) {
					if (this.zoomedProps.length == 4) {
						option = this[this.zoomedProps[0]](this.zoomedProps[1], this.zoomedProps[2], this.zoomedProps[3]);
					} else if (this.zoomedProps.length == 1) {
						option = this.zoomedProps[0];
					} else {
						option = this[this.zoomedProps[0]](this.zoomedProps[1], this.zoomedProps[2], this.zoomedProps[3], this.zoomedProps[4]);
					}
				}
				const seriesData = option.series[0].data;
				const seriesName = option.series[0].name;
				const parameterStatus = this.pollutant
					? this.$store.getters.getParameterStatus.find((status) => status.pmparameterid === this.pollutant.pmparameterid)
					: false;
				// Set date period
				if (Array.isArray(this.dateInterval)) {
					// HISTORIC NOTIFICATION: START DATE <-> END DATE
					periodRow = [
						this.$t('common.period') + ':',
						formatTimestamp(this.dateInterval[0], this.userProperties) +
							' - ' +
							formatTimestamp(this.dateInterval[1], this.userProperties)
					];
				} else if (this.designOption.id == 'TAREA') {
					// TIDE AREA CHARTS
					if (this.dateInterval == 0) {
						periodRow = [this.$t('common.period') + ':', formatTimestamp(Date.now(), this.userProperties).substring(0, 10)];
					} else {
						let d = new Date();
						d.setDate(d.getDate() - this.dateInterval);
						periodRow = [
							this.$t('common.period') + ':',
							formatTimestamp(d.valueOf(), this.userProperties).substring(0, 10) +
								' - ' +
								formatTimestamp(Date.now(), this.userProperties).substring(0, 10)
						];
					}
				} else {
					// HISTORIC CHART: (NOW - DATE INTERVAL) <-> NOW
					let d = new Date();
					d.setDate(d.getDate() - this.dateInterval);
					periodRow = [
						this.$t('common.period') + ':',
						formatTimestamp(d.valueOf(), this.userProperties) + ' - ' + formatTimestamp(Date.now(), this.userProperties)
					];
				}

				switch (this.designOption.id) {
					case constants.VISUALIZATIONS.TIME_SERIES:
					case constants.VISUALIZATIONS.HISTOGRAM:
					case constants.VISUALIZATIONS.AREA_CHART:
					case constants.VISUALIZATIONS.TIDE_AREA_CHART:
						categories = Array.isArray(option.xAxis) ? option.xAxis[0].data : option.xAxis.data;
						if (parameterStatus && Object.hasOwn(seriesData[0], 'itemStyle')) {
							// VISUALIZATION - WITH STATUS RANGES

							headerRow = [
								this.$t('rasterTools.date'),
								seriesName ? seriesName : 'Value',
								this.$t('form.pmstatus'),
								this.$t('pmstatus.color')
							];
							columnsConfig = [
								{
									key: headerRow[0].toLowerCase(),
									width: 26,
									style: { font: { name: 'Montserrat Medium' } }
								},
								{
									key: headerRow[1],
									width: 32,
									style: { font: { name: 'Montserrat Medium' } }
								},
								{
									key: headerRow[2],
									width: 24,
									style: { font: { name: 'Montserrat Medium' } }
								},
								{
									key: headerRow[3],
									width: 14,
									style: { font: { name: 'Montserrat Medium' } }
								}
							];
							ws_data = [
								...categories.map((category, index) => {
									return [
										category,
										seriesData[index].value,
										this.getStatusInRange(seriesData[index], parameterStatus),
										seriesData[index].itemStyle.color
									];
								})
							];
						} else {
							// VISUALIZATION - NO STATUS RANGES
							headerRow = [this.$t('rasterTools.date'), seriesName];
							columnsConfig = [
								{
									key: headerRow[0],
									width: 26,
									style: { font: { name: 'Montserrat Medium' } }
								},
								{
									key: headerRow[1],
									width: 32,
									style: { font: { name: 'Montserrat Medium' } }
								},
								{
									width: 24
								},
								{
									width: 14
								}
							];
							// TIDE AREA CHART MAPPING
							if (this.designOption.id == constants.VISUALIZATIONS.TIDE_AREA_CHART) {
								categories = this.option.yAxis.data.map((item) => item.date);
								ws_data = [...categories.map((category, index) => [category, seriesData[index].value])];
							} else {
								ws_data = [...categories.map((category, index) => [category, seriesData[index].value])];
							}
						}

						break;
					case constants.VISUALIZATIONS.FREQUENCY_HISTOGRAM:
						categories = option.xAxis.data;
						// FREQUENCY HISTOGRAM
						headerRow = [this.$t('panels.notifications.interval'), this.$t('map.items')];
						columnsConfig = [
							{
								key: headerRow[0],
								width: 18,
								style: { font: { name: 'Montserrat Medium' } }
							},
							{
								key: headerRow[1],
								width: 18,
								style: { font: { name: 'Montserrat Medium' } }
							},
							{
								width: 18
							},
							{
								width: 18
							}
						];
						ws_data = [...categories.map((category, index) => [category, seriesData[index]])];
						break;
					case constants.VISUALIZATIONS.FREQUENCY_PIE:
						categories = seriesData.map((item) => item.name);
						// FREQUENCY PIE CHART
						headerRow = [this.$t('panels.notifications.interval'), this.$t('map.items')];
						columnsConfig = [
							{
								key: headerRow[0],
								width: 18,
								style: { font: { name: 'Montserrat Medium' } }
							},
							{
								key: headerRow[1],
								width: 18,
								style: { font: { name: 'Montserrat Medium' } }
							},
							{
								width: 18
							},
							{
								width: 18
							}
						];
						ws_data = [...categories.map((category, index) => [category, seriesData[index].value])];
						break;
					case constants.VISUALIZATIONS.HEATMAP:
						seriesData.reverse();
						categories = seriesData.map((item) => (item[0] < 10 ? '0' + item[0] : item[0]) + ':00');
						// HEATMAP
						headerRow = [this.$t('rasterTools.date'), this.$t('panels.device.hour'), seriesName];
						columnsConfig = [
							{
								key: headerRow[0],
								width: 18,
								style: { font: { name: 'Montserrat Medium' } }
							},
							{
								key: headerRow[1],
								width: 18,
								style: { font: { name: 'Montserrat Medium' } }
							},
							{
								key: headerRow[2],
								width: 32,
								style: { font: { name: 'Montserrat Medium' } }
							},
							{
								width: 14
							}
						];

						ws_data = [...categories.map((category, index) => [seriesData[index][1], category, seriesData[index][2]])];

						ws_data.sort((a, b) => {
							if (a[1] < b[1]) {
								return -1;
							}
							if (a[1] > b[1]) {
								return 1;
							}
							return 0;
						});
						ws_data.sort((a, b) => {
							if (a[0] < b[0]) {
								return -1;
							}
							if (a[0] > b[0]) {
								return 1;
							}
							return 0;
						});
						break;
				}

				// Create Workbook and add Worksheet
				const workbook = new ExcelJS.Workbook();
				const worksheet = workbook.addWorksheet(this.$t('panels.configpannel.vectorialLayers'));

				// Format title region (logo and app name)
				worksheet.mergeCells('A1:A4');
				worksheet.mergeCells('B1:E4');
				const headerCell = worksheet.getCell('B1');
				headerCell.value = appData.appname;
				headerCell.font = { name: 'Montserrat Black', size: 16, bold: true };
				headerCell.alignment = { vertical: 'middle', horizontal: 'center' };
				// Add period row values
				worksheet.getRow(5).values = periodRow;
				worksheet.mergeCells('B5:E5');
				// Add headers row values
				worksheet.getRow(6).values = headerRow;
				worksheet.columns = columnsConfig;
				// Insertar data starting on row 7
				worksheet.addRows(ws_data);
				console.log('worksheet data', ws_data);
				if (ws_data[0].length > 3) {
					ws_data.forEach((item, index) => {
						const cell = worksheet.getCell(`D${index + 7}`);
						cell.value = item[3];
						cell.font = { color: { argb: 'FF' + item[3].substring(1, 7) }, name: 'Montserrat Medium' };
					});
				}
				// Format & Style
				worksheet.getRow(5).font = { name: 'Montserrat', size: 12, bold: true };
				worksheet.getRow(6).font = { name: 'Montserrat Black', size: 12, bold: true };
				worksheet.getRow(6).alignment = { vertical: 'middle', horizontal: 'center' };
				worksheet.getCell('B5').alignment = { vertical: 'middle', horizontal: 'center' };

				if (appData) {
					const response = await fetch(appData.urlbase + appData.iconroute);
					//const response = await fetch(logo_multireload); // LOCAL TESTING
					const arrayBuffer = await response.arrayBuffer();
					const imageId = workbook.addImage({
						buffer: Buffer.from(arrayBuffer),
						extension: 'png'
					});

					worksheet.addImage(imageId, {
						tl: { col: 0.1, row: 0.1 }, // Slightly offset to avoid overlap
						ext: { width: 75, height: 75 } // Adjust size to fit within the cell
					});
				}

				// Agregar la imagen de la visualización
				if (this.chartInstance && typeof this.chartInstance.getDataURL === 'function') {
					//Agregar fondo blanco a las celdas que contendrán la imagen
					for (let row = 7; row <= 21; row++) {
						for (let col = 5; col <= 16; col++) {
							const cell = worksheet.getCell(row, col);
							cell.fill = {
								type: 'pattern',
								pattern: 'solid',
								fgColor: { argb: 'FFFFFFFF' } // Fondo blanco
							};
						}
					}
					const image = this.chartInstance.getDataURL();
					const imageId = workbook.addImage({
						base64: image,
						extension: 'png'
					});
					worksheet.addImage(imageId, {
						tl: { col: 4, row: 6 },
						br: { col: 16, row: 21 }
					});
				} else {
					console.error('chartInstance is not available or getDataURL is not a function');
					console.log('chartInstance is not available or getDataURL is not a function');
				}

				// Generate file
				const buffer = await workbook.xlsx.writeBuffer();
				const blob = new Blob([buffer], { type: 'application/octet-stream' });
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.download = this.pollutant
					? this.pollutant.name + '_' + this.designOption.name + '_' + formatTimestamp(Date.now(), this.userProperties) + '.xlsx'
					: this.designOption.name + '_' + formatTimestamp(Date.now(), this.userProperties) + '.xlsx';
				link.click();
				window.URL.revokeObjectURL(url);
				this.saving = false;
				trackEvent('Exportar', 'Exportar Excel', link.download);
				link.remove();
			}
		},
		async downloadExcelMulti() {
			console.log('download EXCEL MULTI', this.option, this.zoomedProps, this.designOption);
			this.saving = true;
			if (this.option && this.designOption) {
				let ws_data = [];
				let headerRow = [];
				let periodRow = [];
				let columnsConfig = [];
				let categories = null;
				let appData = this.$store.getters.getApplication;

				// Acceder a los datos del gráfico
				let option = this.option;
				if (this.zoomedProps && this.zoomedProps.length > 0) {
					if (this.zoomedProps.length == 4) {
						option = this[this.zoomedProps[0]](this.zoomedProps[1], this.zoomedProps[2], this.zoomedProps[3]);
					} else if (this.zoomedProps.length == 1) {
						option = this.zoomedProps[0];
					}
				}

				const optionSeries = option.series.filter((serie) => serie.type !== 'pie');
				let imageOffset = optionSeries.length;
				// Set date period
				if (Array.isArray(this.dateInterval)) {
					// HISTORIC NOTIFICATION: START DATE <-> END DATE
					periodRow = [
						this.$t('common.period') + ':',
						formatTimestamp(this.dateInterval[0], this.userProperties) +
							' - ' +
							formatTimestamp(this.dateInterval[1], this.userProperties)
					];
				} else {
					// HISTORIC CHART: (NOW - DATE INTERVAL) <-> NOW
					let d = new Date();
					d.setDate(d.getDate() - this.dateInterval);
					periodRow = [
						this.$t('common.period') + ':',
						formatTimestamp(d.valueOf(), this.userProperties) + ' - ' + formatTimestamp(Date.now(), this.userProperties)
					];
				}

				categories = Array.isArray(option.xAxis) ? option.xAxis[0].data : option.xAxis.data;

				// VISUALIZATION - NO STATUS RANGES
				headerRow = [this.$t('rasterTools.date')];
				columnsConfig = [
					{
						key: headerRow[0],
						width: 26,
						style: { font: { name: 'Montserrat Medium' } }
					}
				];
				if (this.designOption.id == constants.VISUALIZATIONS.STACKED_SERIES) {
					imageOffset = 2;
					headerRow.push(this.$t('panels.device.hour'), this.pollutant.name);
					columnsConfig.push(
						{
							key: headerRow[1],
							width: 20,
							style: { font: { name: 'Montserrat Medium' } }
						},
						{
							key: headerRow[2],
							width: headerRow[2].length * 2,
							style: { font: { name: 'Montserrat Medium' } }
						}
					);
					optionSeries.forEach((serie) => {
						serie.data.forEach((value, i) => {
							ws_data.push([serie.name, categories[i], value]);
						});
					});

					ws_data.sort((a, b) => {
						if (a[1] < b[1]) {
							return -1;
						}
						if (a[1] > b[1]) {
							return 1;
						}
						return 0;
					});
					ws_data.sort((a, b) => {
						if (a[0] < b[0]) {
							return -1;
						}
						if (a[0] > b[0]) {
							return 1;
						}
						return 0;
					});
				} else {
					optionSeries.forEach((serie) => {
						headerRow.push(serie.name);
						columnsConfig.push({
							key: serie.name,
							width: serie.name.length * 2 + 5,
							style: { font: { name: 'Montserrat Medium' } }
						});
					});

					ws_data = [
						...categories.map((category, index) => {
							let row = [category];
							optionSeries.forEach((serie) => {
								row.push(serie.data[index]);
							});
							return row;
						})
					];
				}

				// Create Workbook and add Worksheet
				const workbook = new ExcelJS.Workbook();
				const worksheet = workbook.addWorksheet(this.$t('panels.configpannel.vectorialLayers'));

				// Format title region (logo and app name)
				worksheet.mergeCells('A1:A4');
				worksheet.mergeCells('B1:F4');
				const headerCell = worksheet.getCell('B1');
				headerCell.value = appData.appname;
				headerCell.font = { name: 'Montserrat Black', size: 16, bold: true };
				headerCell.alignment = { vertical: 'middle', horizontal: 'center' };
				// Add period row values
				worksheet.getRow(5).values = periodRow;
				worksheet.mergeCells('B5:F5');
				// Add headers row values
				worksheet.getRow(6).values = headerRow;
				worksheet.columns = columnsConfig;

				// Insertar data starting on row 7
				worksheet.addRows(ws_data);
				/* if (ws_data[0].length > 2) {
					ws_data.forEach((item, index) => {
						const cell = worksheet.getCell(`D${index + 7}`);
						cell.value = item[3];
						cell.font = { color: { argb: 'FF' + item[3].substring(1, 7) }, name: 'Montserrat Medium' };
					});
				} */
				// Format & Style
				worksheet.getRow(5).font = { name: 'Montserrat', size: 12, bold: true };
				worksheet.getRow(6).font = { name: 'Montserrat Black', size: 12, bold: true };
				worksheet.getRow(6).alignment = { vertical: 'middle', horizontal: 'center' };
				worksheet.getCell('B5').alignment = { vertical: 'middle', horizontal: 'center' };

				if (appData) {
					const response = await fetch(appData.urlbase + appData.iconroute);
					//const response = await fetch(logo_multireload); // LOCAL TESTING
					const arrayBuffer = await response.arrayBuffer();
					const imageId = workbook.addImage({
						buffer: Buffer.from(arrayBuffer),
						extension: 'png'
					});

					worksheet.addImage(imageId, {
						tl: { col: 0.1, row: 0.1 }, // Slightly offset to avoid overlap
						ext: { width: 75, height: 75 } // Adjust size to fit within the cell
					});
				}

				// Agregar la imagen de la visualización
				if (this.chartInstance && typeof this.chartInstance.getDataURL === 'function') {
					//Agregar fondo blanco a las celdas que contendrán la imagen
					for (let row = 7; row <= 21; row++) {
						for (let col = 4 + imageOffset; col <= 15 + imageOffset; col++) {
							const cell = worksheet.getCell(row, col);
							cell.fill = {
								type: 'pattern',
								pattern: 'solid',
								fgColor: { argb: 'FFFFFFFF' } // Fondo blanco
							};
						}
					}
					const image = this.chartInstance.getDataURL();
					const imageId = workbook.addImage({
						base64: image,
						extension: 'png'
					});
					worksheet.addImage(imageId, {
						tl: { col: 3 + imageOffset, row: 6 }, // F4 (0-based index, so F is 5 and 4 is 3)
						br: { col: 15 + imageOffset, row: 21 } // R20 (0-based index, so R is 17 and 20 is 19)
					});
				} else {
					console.error('chartInstance is not available or getDataURL is not a function');
					console.log('chartInstance is not available or getDataURL is not a function');
				}

				// Generate file
				const buffer = await workbook.xlsx.writeBuffer();
				const blob = new Blob([buffer], { type: 'application/octet-stream' });
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;

				link.download = this.pollutant
					? this.pollutant.name + '_' + this.designOption.name + '_' + formatTimestamp(Date.now(), this.userProperties) + '.xlsx'
					: this.designOption.name + '_' + formatTimestamp(Date.now(), this.userProperties) + '.xlsx';

				link.click();
				window.URL.revokeObjectURL(url);
				this.saving = false;
				trackEvent('Exportar', 'Exportar Excel', link.download);
				link.remove();
			} else {
				console.error('Error: Incorrect data for Excel Export');
			}
		},
		async downloadExcelAdvanced() {
			console.log('download EXCEL ADVANCED', this.option, this.zoomedProps, this.designOption);
			this.saving = true;
			this.advancedProps = this.advancedPropsObj;
			if (this.option && this.designOption) {
				let ws_data = [];
				let headerRow = [];
				let periodRow = [];
				let columnsConfig = [];
				let categories = null;
				let appData = this.$store.getters.getApplication;

				// Acceder a los datos del gráfico
				let option = this.option;
				if (this.zoomedProps && this.zoomedProps.length > 0) {
					if (this.zoomedProps.length == 4) {
						option = this[this.zoomedProps[0]](this.zoomedProps[1], this.zoomedProps[2], this.zoomedProps[3]);
					} else if (this.zoomedProps.length == 1) {
						option = this.zoomedProps[0];
					}
				}
				const optionSeries = option.series.filter((serie) => serie.type !== 'pie');
				let imageOffset = optionSeries.length;
				// Set date period
				console.log('debug advanced dateInterval', this.dateInterval);
				if (Array.isArray(this.dateInterval)) {
					// HISTORIC NOTIFICATION: START DATE <-> END DATE

					periodRow = [
						this.$t('common.period') + ':',
						formatTimestamp(this.dateInterval[0], this.userProperties) +
							' - ' +
							formatTimestamp(this.dateInterval[1], this.userProperties)
					];
				} else {
					// HISTORIC CHART: (NOW - DATE INTERVAL) <-> NOW
					let d = new Date();
					d.setDate(d.getDate() - this.dateInterval);
					periodRow = [
						this.$t('common.period') + ':',
						formatTimestamp(d.valueOf(), this.userProperties) + ' - ' + formatTimestamp(Date.now(), this.userProperties)
					];
				}

				categories = this.advancedProps.categories;

				// VISUALIZATION - NO STATUS RANGES
				headerRow = [this.$t('rasterTools.date')];
				columnsConfig = [
					{
						key: headerRow[0],
						width: 26,
						style: { font: { name: 'Montserrat Medium' } }
					}
				];

				optionSeries.forEach((serie) => {
					headerRow.push(serie.name);
					columnsConfig.push({
						key: serie.name,
						width: serie.name.length * 2,
						style: { font: { name: 'Montserrat Medium' } }
					});
				});

				ws_data = [
					...categories.map((category, index) => {
						let row = [category];
						optionSeries.forEach((serie) => {
							if (serie.id == 'avg') {
								row.push(this.advancedProps.avg[index][1]);
							} else {
								row.push(this.advancedProps.values[serie.id][index]);
							}
						});
						return row;
					})
				];

				// Create Workbook and add Worksheet
				const workbook = new ExcelJS.Workbook();
				const worksheet = workbook.addWorksheet(this.$t('panels.configpannel.vectorialLayers'));

				// Format title region (logo and app name)
				worksheet.mergeCells('A1:A4');
				worksheet.mergeCells('B1:F4');
				const headerCell = worksheet.getCell('B1');
				headerCell.value = appData.appname;
				headerCell.font = { name: 'Montserrat Black', size: 16, bold: true };
				headerCell.alignment = { vertical: 'middle', horizontal: 'center' };
				// Add period row values
				worksheet.getRow(5).values = periodRow;
				worksheet.mergeCells('B5:F5');
				// Add headers row values
				worksheet.getRow(6).values = headerRow;
				worksheet.columns = columnsConfig;

				// Insertar data starting on row 7
				worksheet.addRows(ws_data);
				/* if (ws_data[0].length > 2) {
					ws_data.forEach((item, index) => {
						const cell = worksheet.getCell(`D${index + 7}`);
						cell.value = item[3];
						cell.font = { color: { argb: 'FF' + item[3].substring(1, 7) }, name: 'Montserrat Medium' };
					});
				} */
				// Format & Style
				worksheet.getRow(5).font = { name: 'Montserrat', size: 12, bold: true };
				worksheet.getRow(6).font = { name: 'Montserrat Black', size: 12, bold: true };
				worksheet.getRow(6).alignment = { vertical: 'middle', horizontal: 'center' };
				worksheet.getCell('B5').alignment = { vertical: 'middle', horizontal: 'center' };

				if (appData) {
					const response = await fetch(appData.urlbase + appData.iconroute);
					//const response = await fetch(logo_multireload); // LOCAL TESTING
					const arrayBuffer = await response.arrayBuffer();
					const imageId = workbook.addImage({
						buffer: Buffer.from(arrayBuffer),
						extension: 'png'
					});

					worksheet.addImage(imageId, {
						tl: { col: 0.1, row: 0.1 }, // Slightly offset to avoid overlap
						ext: { width: 75, height: 75 } // Adjust size to fit within the cell
					});
				}

				// Agregar la imagen de la visualización
				if (this.chartInstance && typeof this.chartInstance.getDataURL === 'function') {
					//Agregar fondo blanco a las celdas que contendrán la imagen
					for (let row = 7; row <= 21; row++) {
						for (let col = 4 + imageOffset; col <= 16 + imageOffset; col++) {
							const cell = worksheet.getCell(row, col);
							cell.fill = {
								type: 'pattern',
								pattern: 'solid',
								fgColor: { argb: 'FFFFFFFF' } // Fondo blanco
							};
						}
					}
					const image = this.chartInstance.getDataURL();
					const imageId = workbook.addImage({
						base64: image,
						extension: 'png'
					});
					worksheet.addImage(imageId, {
						tl: { col: 3 + imageOffset, row: 6 }, // F4 (0-based index, so F is 5 and 4 is 3)
						br: { col: 16 + imageOffset, row: 21 } // R20 (0-based index, so R is 17 and 20 is 19)
					});
				} else {
					console.error('chartInstance is not available or getDataURL is not a function');
					console.log('chartInstance is not available or getDataURL is not a function');
				}

				// Generate file
				const buffer = await workbook.xlsx.writeBuffer();
				const blob = new Blob([buffer], { type: 'application/octet-stream' });
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				if (this.designOption.name.includes('ADVANCED')) {
					link.download = this.pollutant
						? this.designOption.name + '_' + this.pollutant.name + '_' + formatTimestamp(Date.now(), this.userProperties) + '.xlsx'
						: this.designOption.name + '_' + formatTimestamp(Date.now(), this.userProperties) + '.xlsx';
				} else {
					link.download = this.pollutant
						? this.pollutant.name + '_' + this.designOption.name + '_' + formatTimestamp(Date.now(), this.userProperties) + '.xlsx'
						: this.designOption.name + '_' + formatTimestamp(Date.now(), this.userProperties) + '.xlsx';
				}

				link.click();
				window.URL.revokeObjectURL(url);
				this.saving = false;
				trackEvent('Exportar', 'Exportar Excel', link.download);
				link.remove();
			} else {
				console.error('Error: Incorrect data for Excel Export');
			}
		},
		async downloadExcelMobile() {
			console.log('download EXCEL MOBILE', this.option, this.zoomedProps, this.designOption, this.pollutant);
			const pollutantSymbol = this.pollutant.symbol == '' || !this.pollutant.symbol ? '' : ' (' + this.pollutant.symbol + ')';
			this.saving = true;
			this.mobileProps = this.mobilePropsObj;
			if (this.option && this.designOption) {
				let ws_data = [];
				let headerRow = [];
				let periodRow = [];
				let columnsConfig = [];
				let categories = null;
				let appData = this.$store.getters.getApplication;

				// Acceder a los datos del gráfico
				let option = this.option;
				if (this.zoomedProps && this.zoomedProps.length > 0) {
					if (this.zoomedProps.length == 4) {
						option = this[this.zoomedProps[0]](this.zoomedProps[1], this.zoomedProps[2], this.zoomedProps[3]);
					} else if (this.zoomedProps.length == 1) {
						option = this.zoomedProps[0];
					}
				}
				const optionSeries = option.series.filter((serie) => serie.type !== 'pie');
				// Set date period
				if (Array.isArray(this.dateInterval)) {
					// HISTORIC NOTIFICATION: START DATE <-> END DATE
					periodRow = [
						this.$t('common.period') + ':',
						formatTimestamp(this.dateInterval[0], this.userProperties) +
							' - ' +
							formatTimestamp(this.dateInterval[1], this.userProperties)
					];
				} else {
					// HISTORIC CHART: (NOW - DATE INTERVAL) <-> NOW
					let d = new Date();
					d.setDate(d.getDate() - this.dateInterval);
					periodRow = [
						this.$t('common.period') + ':',
						formatTimestamp(d.valueOf(), this.userProperties) + ' - ' + formatTimestamp(Date.now(), this.userProperties)
					];
				}

				categories = this.mobileProps.categories;

				headerRow = [this.$t('rasterTools.date'), this.$t('map.area'), this.pollutant.name + pollutantSymbol];
				columnsConfig = [
					{
						key: headerRow[0],
						width: 26,
						style: { font: { name: 'Montserrat Medium' } }
					},
					{
						key: headerRow[1],
						width: 26,
						style: { font: { name: 'Montserrat Medium' } }
					},
					{
						key: headerRow[2],
						width: 32,
						style: { font: { name: 'Montserrat Medium' } }
					}
				];

				optionSeries.forEach((serie) => {
					columnsConfig.push({
						key: serie.name,
						width: serie.name.length * 2,
						style: { font: { name: 'Montserrat Medium' } }
					});
				});

				ws_data = [
					...categories.map((category) => {
						let row = [category];
						optionSeries.forEach((serie) => {
							if (this.mobileProps.values[serie.id][category]) {
								row.push(serie.name);
								row.push(this.mobileProps.values[serie.id][category]);
							}
						});
						return row;
					})
				];

				// Create Workbook and add Worksheet
				const workbook = new ExcelJS.Workbook();
				const worksheet = workbook.addWorksheet(this.$t('panels.configpannel.vectorialLayers'));

				// Format title region (logo and app name)
				worksheet.mergeCells('A1:A4');
				worksheet.mergeCells('B1:F4');
				const headerCell = worksheet.getCell('B1');
				headerCell.value = appData.appname;
				headerCell.font = { name: 'Montserrat Black', size: 16, bold: true };
				headerCell.alignment = { vertical: 'middle', horizontal: 'center' };
				// Add period row values
				worksheet.getRow(5).values = periodRow;
				worksheet.mergeCells('B5:F5');
				// Add headers row values
				worksheet.getRow(6).values = headerRow;
				worksheet.columns = columnsConfig;

				// Insertar data starting on row 7
				worksheet.addRows(ws_data);
				/* if (ws_data[0].length > 2) {
					ws_data.forEach((item, index) => {
						const cell = worksheet.getCell(`D${index + 7}`);
						cell.value = item[3];
						cell.font = { color: { argb: 'FF' + item[3].substring(1, 7) }, name: 'Montserrat Medium' };
					});
				} */
				// Format & Style
				worksheet.getRow(5).font = { name: 'Montserrat', size: 12, bold: true };
				worksheet.getRow(6).font = { name: 'Montserrat Black', size: 12, bold: true };
				worksheet.getRow(6).alignment = { vertical: 'middle', horizontal: 'center' };
				worksheet.getCell('B5').alignment = { vertical: 'middle', horizontal: 'center' };

				if (appData) {
					const response = await fetch(appData.urlbase + appData.iconroute);
					//const response = await fetch(logo_multireload); // LOCAL TESTING
					const arrayBuffer = await response.arrayBuffer();
					const imageId = workbook.addImage({
						buffer: Buffer.from(arrayBuffer),
						extension: 'png'
					});

					worksheet.addImage(imageId, {
						tl: { col: 0.1, row: 0.1 }, // Slightly offset to avoid overlap
						ext: { width: 75, height: 75 } // Adjust size to fit within the cell
					});
				}

				// Agregar la imagen de la visualización
				if (this.chartInstance && typeof this.chartInstance.getDataURL === 'function') {
					//Agregar fondo blanco a las celdas que contendrán la imagen
					for (let row = 7; row <= 20; row++) {
						for (let col = 6; col <= 17; col++) {
							const cell = worksheet.getCell(row, col);
							cell.fill = {
								type: 'pattern',
								pattern: 'solid',
								fgColor: { argb: 'FFFFFFFF' } // Fondo blanco
							};
						}
					}
					const image = this.chartInstance.getDataURL();
					const imageId = workbook.addImage({
						base64: image,
						extension: 'png'
					});
					worksheet.addImage(imageId, {
						tl: { col: 5, row: 6 }, // F4 (0-based index, so F is 5 and 4 is 3)
						br: { col: 17, row: 20 } // R20 (0-based index, so R is 17 and 20 is 19)
					});
				} else {
					console.error('chartInstance is not available or getDataURL is not a function');
					console.log('chartInstance is not available or getDataURL is not a function');
				}

				// Generate file
				const buffer = await workbook.xlsx.writeBuffer();
				const blob = new Blob([buffer], { type: 'application/octet-stream' });
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				if (this.designOption.name.includes('ADVANCED')) {
					link.download = this.pollutant
						? this.designOption.name + '_' + this.pollutant.name + '_' + formatTimestamp(Date.now(), this.userProperties) + '.xlsx'
						: this.designOption.name + '_' + formatTimestamp(Date.now(), this.userProperties) + '.xlsx';
				} else {
					link.download = this.pollutant
						? this.pollutant.name + '_' + this.designOption.name + '_' + formatTimestamp(Date.now(), this.userProperties) + '.xlsx'
						: this.designOption.name + '_' + formatTimestamp(Date.now(), this.userProperties) + '.xlsx';
				}

				link.click();
				window.URL.revokeObjectURL(url);
				this.saving = false;
				trackEvent('Exportar', 'Exportar Excel', link.download);
				link.remove();
			} else {
				console.error('Error: Incorrect data for Excel Export');
			}
		},
		getStatusInRange(data, parameterStatus) {
			let activeStatus = getStatusProperties(parameterStatus, data.value);
			return activeStatus ? activeStatus.name : '-';
		}
	}
};
</script>
