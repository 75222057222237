<template>
	<v-card class="pa-0 device-historic" v-if="this.deviceObject.viz.featureProperties.model == 'pmtidepoints'">
		<v-card-text class="pa-0">
			<v-window v-model="selectedTabHistoric">
				<v-card flat>
					<tidepoints-chart
						v-if="this.chartOption"
						:feature="deviceObject"
						:chartOptions="this.chartOption"
						:displayLocation="displayLocation"
					/>
				</v-card>
			</v-window>
		</v-card-text>
	</v-card>
	<v-card v-else class="switchoff d-flex flex-column align-center justify-center">
		<v-icon size="2rem"> fal fa-inbox </v-icon>
		<div class="switchoff-text">{{ $t('common.noData') }}</div>
	</v-card>
</template>

<script>
import TidePointsChart from '@/components/echart/TidePointsChart.vue';
import { lastData } from '@/api/databases_API';
// eslint-disable-next-line no-unused-vars
import { search, obtainIndexName, ChartType } from '@/api/common';
import { query_FirstDataByDevice } from '@/utils/queries';

export default {
	components: {
		'tidepoints-chart': TidePointsChart
	},
	props: {
		deviceObject: {
			type: Object,
			required: true
		},
		displayLocation: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			tab: 0,
			primaryColor: this.$store.state.global.primaryColor,
			chartOption: null,
		};
	},
	computed: {
		selectedTabHistoric: {
			get() {
				return this.$store.getters.getSelectedTabHistoric;
			},
			set(value) {
				this.$store.commit('setSelectedTabHistoric', value);
			}
		},
		displayMode() {
			return this.$store.getters.getDisplayMode;
		},
	},
	watch: {},
	created() {
		let visual = null;
		let acronym = null;
		let elasticacronym = null;
		let index = null;
		switch (this.deviceObject.viz.featureProperties.model) {
			// Hacer un case para cada spatial model
			case 'pmtidepoints':
				visual = 'TAREA';
				acronym = 'tide';
				elasticacronym = 'tide';
				index = 'tidepoints'
				break;

		}

		search(ChartType(visual)).then(chartType => {
            this.chartOption = {
                acronym: acronym,
                disabled: 0,
                elasticacronym: elasticacronym,
				index: index,
				chartacronym: chartType.data.data[0].acronym,
				chartconfiguration: chartType.data.data[0].configuration,
				visualizationtype: chartType.data.data[0].visualizationtype,
				applyranges: chartType.data.data[0].applyranges,
				exportable: chartType.data.data[0].exportable,
				showintervals: chartType.data.data[0].showintervals,
				isdynamic: chartType.data.data[0].isdynamic,
				chartname: chartType.data.data[0].visualizationname,
				chartdescription: chartType.data.data[0].description,
            };
            console.log('Fake chartOption', this.chartOption);
        });

	},
	mounted() {
		console.log('mounted device historic', this.deviceObject);
		const promise = lastData(
			obtainIndexName(this.deviceObject),
		);
		promise.then((elasticData) => {
			console.log('QUERY: ', query_FirstDataByDevice(this.deviceObject.code));

			if (typeof elasticData.message[0] == 'object') {
				let timestamp = elasticData.message[0]['_source']['dateObserved_timestamp'];
				let fecha = new Date(timestamp);
				console.log('date obtained', timestamp, fecha);
			}
		});
	},
	updated() {
	},
	methods: {
	}
};
</script>

<style lang="postcss">
.switchoff {
	color: var(--gris-300);
	& .switchoff_icon {
		font-size: 2rem;
	}
	& .switchoff-text {
		font-size: 1rem;
	}
}
</style>
