import constants from '../../../utils/constants';

export default {
	computed: {
		enableShowIntervals() {
			return this.form.designOption
				? this.form.designOption.showintervals == 1 && this.pollutant.thresholdminvalue !== null && this.pollutant.thresholdmaxvalue !== null
				: false;
		},
		enableShowIntervalsDatasource() {
			return this.designOption
				? this.designOption.showintervals == 1 && this.pollutant.thresholdminvalue !== null && this.pollutant.thresholdmaxvalue !== null
				: false;
		}
	},
	methods: {
		toggleShowIntervals(initMethod = 'initChart', deleteMethod = 'deleteChart') {
			console.log('design option intervals', this.form.designOption);
			console.log('toggleShowIntervals event', this.form.showIntervals, initMethod, this.form, this.form.showIntervals);
			this.form.showIntervals = !this.form.showIntervals;
			if (this.form.showIntervals) {
				this[deleteMethod]();
				if (this.form.designOption.id == constants.VISUALIZATIONS.BAR_MOBILE) {
					this.form.option = this.initBarMobile(this.pollutant, this.form.chartData, this.form.designOption, true);
				} else if (this.form.designOption.id == constants.VISUALIZATIONS.TIME_MOBILE) {
					this.form.option = this.initTimeMobile(this.pollutant, this.form.chartData, this.form.designOption, true);
				} else {
					this.form.option = this[initMethod](this.pollutant, this.form.chartData, this.form.designOption, true);
				}
				console.log('intervals check', this.form.showIntervals, this.form.option);
			} else {
				this[deleteMethod]();
				this.loadingAnimation = true;
				setTimeout(() => {
					if (this.form.designOption.id == constants.VISUALIZATIONS.BAR_MOBILE) {
						this.form.option = this.initBarMobile(this.pollutant, this.form.chartData, this.form.designOption, false);
					} else if (this.form.designOption.id == constants.VISUALIZATIONS.TIME_MOBILE) {
						this.form.option = this.initTimeMobile(this.pollutant, this.form.chartData, this.form.designOption, false);
					} else {
						this.form.option = this[initMethod](this.pollutant, this.form.chartData, this.form.designOption, false);
					}
				}, 100);
				this.loadingAnimation = false;

				console.log('intervals check', this.form.showIntervals, this.form.option);
			}
		},
		toggleShowIntervalsDatasource(initMethod = 'initChart', deleteMethod = 'deleteChart') {
			console.log('toggleShowIntervalsDatasource event', this.showIntervals, this.chartData);
			this.showIntervals = !this.showIntervals;
			if (this.showIntervals) {
				this[deleteMethod]();
				this.option = this[initMethod](this.pollutant, this.chartData, this.designOption, true);
				console.log('intervals check', this.showIntervals, this.option);
			} else {
				this[deleteMethod]();
				this.loadingAnimation = true;
				setTimeout(() => {
					this.option = this[initMethod](this.pollutant, this.chartData, this.designOption, false);
				}, 100);
				this.loadingAnimation = false;

				console.log('intervals check', this.showIntervals, this.option);
			}
		}
	}
};
