<template>
	<v-dialog v-if="dialogModel" v-model="dialog" :class="className" persistent max-width="85vw">
		<v-card>
			<v-card-title v-if="source['rule_name']" class="py-3 px-4 d-flex justify-start align-center">
				<v-img class="mr-2" :src="getNotificationIcon(source.pmseverityid)" max-width="24" aspect-ratio="1" contain />
				<span class="fs-lg fw-semi color-black">{{ getRuleName(source['rule_name']) }}</span>
				<v-tooltip v-if="getRuleDescription(source.pmrulesid)" top transition="none" color="var(--gris-secondary)">
					<template v-slot:activator="{ on, attrs }">
						<v-icon size="18" class="fw-regular pl-2" v-bind="attrs" v-on="on">fak fa-line-info-2</v-icon>
					</template>
					<span class="fs-regular fw-regular">{{ getRuleDescription(source.pmrulesid) }}</span>
				</v-tooltip>
			</v-card-title>

			<v-card-text v-if="getFilteredPollutants(pollutants)" class="pa-0 px-4 notification-dialog-height">
				<v-tabs center-active centered height="30" v-model="selectedTabNotificationDialog" show-arrows>
					<v-tab
						v-for="pollutant in getFilteredPollutants(pollutants)"
						:key="getFilteredPollutants(pollutants).indexOf(pollutant)"
						:value="getFilteredPollutants(pollutants).indexOf(pollutant)"
					>
						<v-tooltip top color="var(--gris-secondary)">
							<template v-slot:activator="{ on, attrs }">
								<span class="width-100" v-bind="attrs" v-on="on">{{ pollutant.acronym }}</span>
							</template>
							<span class="fs-regular">{{ pollutant.name }}</span>
						</v-tooltip>
					</v-tab>
				</v-tabs>

				<v-window v-model="selectedTabNotificationDialog">
					<v-window-item
						v-for="pollutant in getFilteredPollutants(pollutants)"
						:key="getFilteredPollutants(pollutants).indexOf(pollutant)"
						:value="getFilteredPollutants(pollutants).indexOf(pollutant)"
					>
						<v-card flat>
							<historic-notification-chart
								:feature="deviceObject"
								:pollutant="pollutant"
								:notificationSource="source"
								displayLocation="dialog"
							/>
						</v-card>
					</v-window-item>
				</v-window>
			</v-card-text>
			<v-card-actions class="justify-end px-4 py-3">
				<v-btn @click="$emit('update:historicDialog', false)" elevation="0" color="primary">{{ $t('common.close') }}</v-btn>
			</v-card-actions>
		</v-card>

		<div class="pa-0 notification-historic-dialog h-100" elevation="0" v-if="getFilteredPollutants(pollutants)">
			<!-- <v-card-title v-if="source['rule_name']" class="px-3 pb-2 pt-0 d-flex justify-start align-center">
				<v-img class="mr-2" :src="getNotificationIcon(source.pmseverityid)" max-width="24" aspect-ratio="1" contain />
				<span class="fs-lg fw-semi color-black">{{ getRuleName(source['rule_name']) }}</span>
				<v-tooltip v-if="getRuleDescription(source.pmrulesid)" top transition="none" color="var(--gris-secondary)">
					<template v-slot:activator="{ on, attrs }">
						<v-icon size="18" class="fw-regular pl-2" v-bind="attrs" v-on="on">fak fa-line-info-2</v-icon>
					</template>
					<span class="fs-regular fw-regular">{{ getRuleDescription(source.pmrulesid) }}</span>
				</v-tooltip>
			</v-card-title> -->
		</div>
		<v-card v-else class="switchoff d-flex flex-column align-center justify-center">
			<v-icon size="2rem"> fal fa-inbox </v-icon>
			<div class="switchoff-text">{{ $t('common.noData') }}</div>
		</v-card>
	</v-dialog>
</template>

<script>
import HistoricNotificationChart from '@/components/echart/HistoricNotificationChart.vue';
import { getStringFeatureImage } from '@/api/common';

export default {
	name: 'NotificationHistoricDialog',
	components: { HistoricNotificationChart },
	props: {
		dialog: {
			default: false
		},
		source: {
			type: Object,
			required: true
		},
		deviceObject: {
			type: Object,
			required: false
		},
		className: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			dialogModel: false,
			elasticDetailRounded: null,
			primaryColor: this.$store.state.global.primaryColor + 'd9'
		};
	},
	computed: {
		pollutants() {
			return this.$store.getters.getParameters.filter((parameter) => parameter.pmorganizationid == this.userProperties.organizationid);
		},
		selectedTabNotificationDialog: {
			get() {
				return this.$store.getters.getSelectedTabNotificationDialog;
			},
			set(value) {
				this.$store.commit('setSelectedTabNotificationDialog', value);
			}
		}
	},
	watch: {
		dialog() {
			this.dialogModel = this.dialog;
		}
	},
	mounted() {
		console.log('debug hist dialog mounted', this.deviceObject, this.pollutants, this.source);
		/* const modalHeadlines = document.getElementsByClassName('headline');

		console.log('debug modalHeadlines', modalHeadlines);
		if (modalHeadlines.length > 0) {
			for (let i = 0; i < modalHeadlines.length; i++) {
				modalHeadlines[i].remove();
			}
		} */
	},
	updated() {
		console.log('debug hist dialog updated', this.deviceObject, this.pollutants, this.source);
		console.log('debug 123', document.getElementsByClassName('headline'), this.deviceObject, this.pollutants);
		/* const modalHeadlines = document.getElementsByClassName('headline');
		console.log('debug modalHeadlines', modalHeadlines);
		if (modalHeadlines.length > 0) {
			for (let i = 0; i < modalHeadlines.length; i++) {
				modalHeadlines[i].remove();
			}
		} */
	},
	destroyed() {
		console.info('destroyed histNotifications');
		this.$puiEvents.$off('pui-modalDialog-detailNotificationDialog-ok');
	},
	methods: {
		getElasticHeaders() {
			let filteredParameters = this.$store.getters.getParameters.filter(
				(parameter) => parameter.pmdatasourcetypeid == this.deviceObject.pmdatasourcetypeid
			);
			var details = [];
			let obj = {};
			console.log('info: Reading promise', filteredParameters);
			obj = this.source;

			filteredParameters.forEach((parameter) => {
				if (Object.hasOwn(obj, parameter.elasticacronym)) {
					details.push({
						text: parameter.name,
						value: parameter.elasticacronym
					});
				}
			});
			console.log('debug: getElasticHeaders', details);
			this.getElasticData();
			return details;
		},
		getElasticData() {
			let elasticKeys = Object.keys(this.source);
			//let elasticDetailRounded = {};
			let elasticDetailRounded = {};
			elasticKeys.forEach((key) => {
				console.log('test ', key, isNaN(parseInt(this.source[key])));
				if (!isNaN(parseInt(this.source[key]))) {
					elasticDetailRounded[key] =
						parseInt(this.source[key]) == this.source[key] ? parseInt(this.source[key]) : parseFloat(this.source[key]).toFixed(2);
				} else {
					elasticDetailRounded[key] = this.source[key];
				}
			});

			//return elasticDetailRounded;
			this.elasticDetailRounded = elasticDetailRounded;
			console.log('debug: getElasticData', this.elasticDetailRounded);
		},
		getNotificationIcon(severityid) {
			let severity = this.$store.getters.getSeverities.find((severity) => severity.pmseverityid == severityid);
			console.log('severity debug', severity, severityid, this.$store.getters.getSeverities);
			if (severity) return getStringFeatureImage(this.$store.getters.getUrlBaseIcons, severity.iconroute);

			return '';
		},
		getFilteredPollutants(pollutants) {
			if (Object.hasOwn(this.source, 'datasourcetype_id'))
				return pollutants.filter((pollutant) => pollutant.pmdatasourcetypeid == this.source['datasourcetype_id']);

			return [];
		},
		getRuleDescription(ruleId) {
			let rule = this.$store.getters.getRules.find((rule) => rule.pmrulesid == ruleId);
			if (rule && rule.description) return rule.description;
			return false;
		},
		getRuleName(ruleAcronym) {
			let rule = this.$store.getters.getRules.find((rule) => rule.acronym == ruleAcronym);
			if (rule && rule.name) return rule.name;
			return false;
		}
	}
};
</script>

<style lang="postcss">
.notification-dialog-height {
	height: var(--notification-dialog-height);
}
.v-data-table {
	& span {
		font-size: 1rem;
	}
	& td {
		font-size: 1rem;
	}
}
.v-dialog {
	.v-card.v-sheet {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	/*
	.v-card__title.headline.lighten-2 {
		padding: 0px !important;
	}*/
}
</style>
