var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$store.getters.getPermissions['APP_EXCEL_EXPORT'])?_c('v-tooltip',{attrs:{"top":"","color":_vm.tooltipColor,"transition":"none"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-1 elevation-0 export-btn",class:_vm.spacingClass,attrs:{"color":_vm.bgColor,"height":_vm.height,"min-width":_vm.width,"disabled":_vm.saving || !_vm.isExportable},on:{"click":() => {
					_vm.$emit('customEvent', 'test');
					if (_vm.advancedPropsObj) {
						_vm.downloadExcelAdvanced();
					} else if (_vm.mobilePropsObj) {
						_vm.downloadExcelMobile();
					} else {
						_vm.option && _vm.option.series.length > 1 ? _vm.downloadExcelMulti() : _vm.downloadExcel();
					}
				}}},'v-btn',attrs,false),on),[(!_vm.saving)?_c('i',{staticClass:"fas fa-file-export",style:({ color: _vm.iconColor })}):_c('v-progress-circular',{staticStyle:{"width":"24px"},style:({ color: _vm.iconColor }),attrs:{"indeterminate":""}})],1)]}}],null,false,3188862470)},[_c('span',{staticClass:"fs-regular"},[_vm._v(_vm._s(_vm.$t('map.exportExcel')))])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }