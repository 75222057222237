import constants from '@/utils/constants';
import { formatTimestamp, capitalize } from '@/api/common';

export default {
	data() {
		return { noStatusColor: '#6f7480' };
	},
	methods	: {
		initChart(chartOptions, elasticData, designOption = null, showIntervals = false) {
			//Proceso datos historicos del contaminante
			const visualizationAcronym = chartOptions.acronym;
			const capitalizedAcronym = capitalize(visualizationAcronym);
			switch(visualizationAcronym) {
				case 'tide': {
					let tideValues = [];
					let tideTimes = [];
					// console.info('debug chartOptions', chartOptions);
					// console.info('debug historic data', elasticData, this.chartType, designOption.id);

					if (elasticData && typeof elasticData.message !== 'string') {
						let documents = [];
						if (Array.isArray(elasticData)) documents = elasticData;
						else documents = elasticData.message.map((hit) => hit._source);
						if (visualizationAcronym === 'tide') {
							documents.forEach((item) => {
								console.log('Item', item['day'][0].tide);
								item['day'][0].tide.forEach((tide) => {
									tideTimes.push(
										formatTimestamp(tide.tide_time, this.userProperties)
									);
									tideValues.push({ value: tide.tide_height_mt, type: tide.tide_type, date: formatTimestamp(tide.tide_time, this.userProperties), hour: tide.tide_time.substring(11)});
								});

							});
						}
					}

					//Configuro opciones del grafico
					let option = designOption ? designOption.config : this.parameterConfiguration;
					// console.info('info: chart option', option, designOption, this.parameterConfiguration);
					if (option['series'].length > 0) option['series'][0].name = capitalizedAcronym;
					else option['series'][0] = { name: capitalizedAcronym, type: 'line', data: [] };

					// console.log('apply ranges init', tideValues, designOption, option['xAxis'], option['series'][0]);
					// Pintar los puntos
					option['series'][0].lineStyle = { color: '#4e4e94d9', width: 2};
					option['series'][0].smooth = true;
					option['xAxis']['data'] = tideTimes;
					option['yAxis']['data'] = tideValues;

					option['series'][0].data = tideValues.map((tide) => {
						return {
							value: tide.value,
							itemStyle: {
								color: tide.type == 'HIGH' ? 'rgba(0,191,255,0.8)' : 'rgba(0,51,153,0.8)',
								borderColor: tide.type == 'HIGH' ? 'rgba(0,191,255,0.8)' : 'rgba(0,51,153,0.8)',
								borderWidth: 2
							},
							symbol: 'circle',
							symbolSize: 10
						};
					});

					option['series'][0].markPoint = {
						data: [
							{ type: 'max', name: 'Max',
								itemStyle: {
									color: 'rgba(0,191,255,0.8)',

								}
							},
							{ type: 'min', name: 'Min',
								itemStyle: {
									color: 'rgba(0,51,153,0.8)'
								}
							}
						],
						symbol: 'roundRect',
						symbolSize: [50, 20],
						itemStyle: {
							color: '#808080'
						}
						/* label: {
							formatter: pollutantSymbol ? '{@score}' + pollutantSymbol : '{@score}'
						} */
					};

					option['legend'] = {
						show: true,
						top: '10%',
						left: 'center',
						textStyle: {
							color: '#333',
							fontSize: 12
						},
						data: [capitalizedAcronym]
					};
					console.log('Legends', option['legend']);
					// Tooltip

					option['tooltip'] = {
						trigger: 'axis',
						formatter: function (params) {
							let tidePoint = params.find(p => p.seriesName === "Tide");
							// console.log('params', tidePoint);
							if (tidePoint) {
								const tide = tideValues.find(tide => tide.date === tidePoint.axisValue);
								// console.log('tide', tide);
								return `<b>${tide.date}</b><br/>
										${tidePoint.marker} Nivel de Marea: ${tidePoint.value} m<br/>
										Tipo: <b>${tide.type}</b>`;
							}
							return '';
						}
					};

					// Lineas discontinuas

					// let markLines = [];
					// for (let i = 1; i < tideTimes.length; i++) {
					// 	console.log('tideValues', tideValues[i].type);
					// 	if (tideValues[i].type !== tideValues[i-1].type)  {
					// 		console.log('insert into markLines', tideTimes[i]);
					// 		markLines.push({
					// 			xAxis: tideTimes[i]
					// 		});
					// 	}
					// }
					// option['series'][0].markLine = {
					// 	silent: true,
					// 	symbol: 'none',
					// 	lineStyle: { type: 'dashed', color: '#666', width: 1.5 },
					// 	data: markLines
					// }

					if (option.toolbox && Object.hasOwn(option.toolbox, 'feature')) {
						option.toolbox.top = 15;
						option.toolbox.right = 30;
						if (Object.hasOwn(option.toolbox.feature, 'dataView')) {
							option['toolbox']['feature']['dataView']['title'] = this.$t('charts.title');
							option['toolbox']['feature']['dataView']['lang'] = [
								this.$t('charts.dataView'),
								this.$t('charts.closeView'),
								this.$t('charts.updateData')
							];

							option.toolbox.feature.dataView.optionToContent = (opt) => {
								const optionSeries = opt.series.filter((serie) => serie.type !== 'pie');
								let categories = Array.isArray(option.xAxis) ? option.xAxis[0].data : option.xAxis.data;
								let ws_data = [];

								optionSeries.forEach((serie) => {
									serie.data.forEach((value, i) => {
										ws_data.push([serie.name, categories[i], value]);
									});
								});

								ws_data.sort((a, b) => {
									if (a[1] < b[1]) {
										return -1;
									}
									if (a[1] > b[1]) {
										return 1;
									}
									return 0;
								});
								ws_data.sort((a, b) => {
									if (a[0] < b[0]) {
										return -1;
									}
									if (a[0] > b[0]) {
										return 1;
									}
									return 0;
								});

								let table =
									'<table style="width:100%;text-align:start;" class="data-view-table mb-4 cell-border hover stripe nowrap full-width dataTable no-footer">' +
									'<thead class=""><tr>' +
									'<th>' +
									this.$t('rasterTools.date') +
									'</th>' +
									'<th>' +
									'Tide Value (m)' +
									'</th>' +
									'</tr></thead><tbody>';
								let odd = false;
								ws_data.forEach((item) => {
									let rowClass = odd ? 'odd' : 'even';
									odd = !odd;
									table +=
										'<tr class="' +
										rowClass +
										'">' +
										'<td>' +
										item[1] +
										'</td>' +
										'<td>' +
										(item[2].value !== null ? item[2].value : '-') +
										'</td>' +
										'</tr>';
								});

								table += '</tbody></table>';
								return table;
							};
						}
						if (Object.hasOwn(option.toolbox.feature, 'restore')) {
							option['toolbox']['feature']['restore']['title'] = this.$t('charts.restore');
						}
						if (Object.hasOwn(option.toolbox.feature, 'saveAsImage')) {
							option['toolbox']['feature']['saveAsImage']['title'] = this.$t('charts.saveAsImage');
						}
					}
					if (showIntervals) {
						option['series'][0].markArea = {
							silent: true,
							data: [[{ yAxis: chartOptions.thresholdminvalue }, { yAxis: chartOptions.thresholdmaxvalue }]],
							itemStyle: { borderType: 'dashed', borderWidth: 2, color: '#014efa33', borderColor: '#014efa' }
						};
					} else {
						if (Object.hasOwn(option['series'][0], 'markArea')) delete option['series'][0].markArea;
					}
					option['textStyle'] = {
						fontFamily: 'Montserrat, sans-serif',
						fontSize: 14
					};
					option.grid = {
						bottom: 70,
						left: '4%',
						width: '87%'
					};
					// console.log('set historic option: ', this.displayLocation, option, JSON.stringify(option), option.series[0].data.length);
					return option;
				}
			}
		},
		setOptionToContent(visualization, chartOptions) {
			let optionToContent = null;
			if (
				visualization == constants.VISUALIZATIONS.TIME_SERIES ||
				visualization == constants.VISUALIZATIONS.HISTOGRAM ||
				visualization == constants.VISUALIZATIONS.AREA_CHART
			) {
				optionToContent = (opt) => {
					try {
						const parameterStatus = chartOptions
							? this.$store.getters.getParameterStatus.find((status) => status.pmparameterid === chartOptions.pmparameterid)
							: false;
						let seriesData = opt.series[0].data;
						let seriesName = opt.series[0].name;
						let categories = Array.isArray(opt.xAxis) ? opt.xAxis[0].data : opt.xAxis.data;
						let ws_data = [];
						let table = '';
						if (!parameterStatus) {
							ws_data = [...categories.map((category, index) => [category, seriesData[index].value])];
							table =
								'<table style="width:100%;text-align:start;" class="data-view-table mb-4 cell-border hover stripe nowrap full-width dataTable no-footer">' +
								'<thead class=""><tr>' +
								'<th>' +
								this.$t('rasterTools.date') +
								'</th>' +
								'<th>' +
								seriesName +
								'</th>' +
								'</tr></thead><tbody>';
							let odd = false;

							ws_data.forEach((item) => {
								let rowClass = odd ? 'odd' : 'even';
								odd = !odd;
								table += '<tr class="' + rowClass + '">' + '<td>' + item[0] + '</td>' + '<td>' + item[1] + '</td>' + '</tr>';
							});
						} else {
							ws_data = [
								...categories.map((category, index) => [
									category,
									seriesData[index].value,
									this.getStatusInRange(seriesData[index], parameterStatus),
									seriesData[index].itemStyle.color
								])
							];
							table =
								'<table style="width:100%;text-align:start;" class="data-view-table mb-4 cell-border hover stripe nowrap full-width dataTable no-footer">' +
								'<thead class=""><tr>' +
								'<th>' +
								this.$t('rasterTools.date') +
								'</th>' +
								'<th>' +
								seriesName +
								'</th>' +
								'<th>' +
								this.$t('form.pmstatus') +
								'</th>' +
								'<th>' +
								this.$t('pmstatus.color') +
								'</th>' +
								'</tr></thead><tbody>';
							let odd = false;
							ws_data.forEach((item) => {
								let rowClass = odd ? 'odd' : 'even';
								odd = !odd;
								table +=
									'<tr class="' +
									rowClass +
									'">' +
									'<td>' +
									item[0] +
									'</td>' +
									'<td>' +
									(item[1] !== null ? item[1] : '-') +
									'</td>' +
									'<td>' +
									item[2] +
									'</td>' +
									'<td style="color: ' +
									item[3] +
									'">' +
									item[3] +
									'</td>' +
									'</tr>';
							});
						}

						table += '</tbody></table>';
						return table;
					} catch (error) {
						console.error('ERROR: optionToContent', error);
						return;
					}
				};
			} else if (visualization == constants.VISUALIZATIONS.FREQUENCY_HISTOGRAM) {
				optionToContent = (opt) => {
					console.info('debug freq opt', opt, opt.xAxis);
					try {
						let seriesData = opt.series[0].data;
						let categories = Array.isArray(opt.xAxis) ? opt.xAxis[0].data : opt.xAxis.data;
						let headerRow = [this.$t('panels.notifications.interval'), this.$t('map.items')];
						let ws_data = [...categories.map((category, index) => [category, seriesData[index]])];
						let table =
							'<table style="width:100%;text-align:start;" class="data-view-table mb-4 cell-border hover stripe nowrap full-width dataTable no-footer">' +
							'<thead class=""><tr>' +
							'<th>' +
							headerRow[0] +
							'</th>' +
							'<th>' +
							headerRow[1] +
							'</th>' +
							'</tr></thead><tbody>';
						let odd = false;
						ws_data.forEach((item) => {
							let rowClass = odd ? 'odd' : 'even';
							odd = !odd;
							table += '<tr class="' + rowClass + '">' + '<td>' + item[0] + '</td>' + '<td>' + item[1] + '</td>' + '</tr>';
						});

						table += '</tbody></table>';
						return table;
					} catch (error) {
						console.error('ERROR: optionToContent', error);
						return;
					}
				};
			} else if (visualization == constants.VISUALIZATIONS.FREQUENCY_PIE) {
				optionToContent = (opt) => {
					try {
						let seriesData = opt.series[0].data;
						let categories = seriesData.map((item) => item.name);
						let headerRow = [this.$t('panels.notifications.interval'), this.$t('map.items')];
						let ws_data = [...categories.map((category, index) => [category, seriesData[index].value])];
						let table =
							'<table style="width:100%;text-align:start;" class="data-view-table mb-4 cell-border hover stripe nowrap full-width dataTable no-footer">' +
							'<thead class=""><tr>' +
							'<th>' +
							headerRow[0] +
							'</th>' +
							'<th>' +
							headerRow[1] +
							'</th>' +
							'</tr></thead><tbody>';
						let odd = false;
						ws_data.forEach((item) => {
							let rowClass = odd ? 'odd' : 'even';
							odd = !odd;
							table += '<tr class="' + rowClass + '">' + '<td>' + item[0] + '</td>' + '<td>' + item[1] + '</td>' + '</tr>';
						});

						table += '</tbody></table>';
						return table;
					} catch (error) {
						console.error('ERROR: optionToContent', error);
						return;
					}
				};
			}
			return optionToContent;
		},
	},
}
