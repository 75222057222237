<template>
	<div>
		<v-tooltip v-if="$store.getters.getPermissions['APP_MEASURE_LINE']" top color="var(--gris-secondary)" transition="none">
			<template v-slot:activator="{ on, attrs }">
				<v-btn text class="btnmeasure" @click="interaction(lineMeasurement)" v-bind="attrs" v-on="on">
					<v-icon class="fw-bold" :color="isToolLineActive ? 'var(--azul-primary-100)' : '#000'" size="16"
						>fak fa-line-distance-measurement
					</v-icon>
				</v-btn>
			</template>
			<span class="fs-regular">{{ $t('map.measureLine') }}</span>
		</v-tooltip>
		<v-tooltip v-if="$store.getters.getPermissions['APP_MEASURE_AREA']" top color="var(--gris-secondary)" transition="none">
			<template v-slot:activator="{ on, attrs }">
				<v-btn text class="btnmeasure" @click="interaction(polygonMeasurement)" v-bind="attrs" v-on="on">
					<v-icon class="fw-bold" :color="isToolPolygonActive ? 'var(--azul-primary-100)' : '#000'" size="16"
						>fak fa-line-area-measurement
					</v-icon>
				</v-btn>
			</template>
			<span class="fs-regular">{{ $t('map.measureArea') }}</span>
		</v-tooltip>
	</div>
</template>

<script>
import ol2map from './ol2map';
import constants from '@/utils/constants';

import 'ol/ol.css';

import { Vector as VectorLayer } from 'ol/layer.js';
import { Vector as VectorSource } from 'ol/source.js';
import { Draw, Modify } from 'ol/interaction.js';
import { Circle as CircleStyle, Fill, Stroke, Style, Text, RegularShape } from 'ol/style.js';
import { LineString, Point } from 'ol/geom.js';
import { getArea, getLength } from 'ol/sphere.js';

const style = new Style({
	fill: new Fill({
		color: 'rgba(255, 255, 255, 0.2)'
	}),
	stroke: new Stroke({
		color: 'rgba(0, 0, 0, 0.5)',
		lineDash: [10, 10],
		width: 2
	}),
	image: new CircleStyle({
		radius: 5,
		stroke: new Stroke({
			color: 'rgba(0, 0, 0, 0.7)'
		}),
		fill: new Fill({
			color: 'rgba(255, 255, 255, 0.2)'
		})
	})
});

const labelStyle = new Style({
	text: new Text({
		font: '14px Calibri,sans-serif',
		fill: new Fill({
			color: 'rgba(255, 255, 255, 1)'
		}),
		backgroundFill: new Fill({
			color: 'rgba(0, 0, 0, 0.7)'
		}),
		padding: [3, 3, 3, 3],
		textBaseline: 'bottom',
		offsetY: -15
	}),
	image: new RegularShape({
		radius: 8,
		points: 3,
		angle: Math.PI,
		displacement: [0, 10],
		fill: new Fill({
			color: 'rgba(0, 0, 0, 0.7)'
		})
	})
});

const tipStyle = new Style({
	text: new Text({
		font: '12px Calibri,sans-serif',
		fill: new Fill({
			color: 'rgba(255, 255, 255, 1)'
		}),
		backgroundFill: new Fill({
			color: 'rgba(0, 0, 0, 0.4)'
		}),
		padding: [2, 2, 2, 2],
		textAlign: 'left',
		offsetX: 15
	})
});

const segmentStyle = new Style({
	text: new Text({
		font: '12px Calibri,sans-serif',
		fill: new Fill({
			color: 'rgba(255, 255, 255, 1)'
		}),
		backgroundFill: new Fill({
			color: 'rgba(0, 0, 0, 0.4)'
		}),
		padding: [2, 2, 2, 2],
		textBaseline: 'bottom',
		offsetY: -12
	}),
	image: new RegularShape({
		radius: 6,
		points: 3,
		angle: Math.PI,
		displacement: [0, 8],
		fill: new Fill({
			color: 'rgba(0, 0, 0, 0.4)'
		})
	})
});

const segmentStyles = [segmentStyle];

let tipPoint;

const modifyStyle = new Style({
	image: new CircleStyle({
		radius: 5,
		stroke: new Stroke({
			color: 'rgba(0, 0, 0, 0.7)'
		}),
		fill: new Fill({
			color: 'rgba(0, 0, 0, 0.4)'
		})
	}),
	text: new Text({
		text: 'Drag to modify',
		font: '12px Calibri,sans-serif',
		fill: new Fill({
			color: 'rgba(255, 255, 255, 1)'
		}),
		backgroundFill: new Fill({
			color: 'rgba(0, 0, 0, 0.7)'
		}),
		padding: [2, 2, 2, 2],
		textAlign: 'left',
		offsetX: 15
	})
});

const source = new VectorSource();

const modify = new Modify({ source: source, style: modifyStyle });

const formatLength = function (line) {
	const length = getLength(line);
	let output;
	if (length > 100) {
		output = Math.round((length / 1000) * 100) / 100 + ' km';
	} else {
		output = Math.round(length * 100) / 100 + ' m';
	}
	return output;
};

const formatArea = function (polygon) {
	const area = getArea(polygon);
	let output;
	if (area > 10000) {
		output = Math.round((area / 1000000) * 100) / 100 + ' km\xB2';
	} else {
		output = Math.round(area * 100) / 100 + ' m\xB2';
	}
	return output;
};

function styleFunction(feature, segments, drawType, tip) {
	const styles = [];
	const geometry = feature.getGeometry();
	const type = geometry.getType();
	let point, label, line;
	if (!drawType || drawType === type || type === 'Point') {
		styles.push(style);
		if (type === 'Polygon') {
			point = geometry.getInteriorPoint();
			label = formatArea(geometry);
			line = new LineString(geometry.getCoordinates()[0]);
		} else if (type === 'LineString') {
			point = new Point(geometry.getLastCoordinate());
			label = formatLength(geometry);
			line = geometry;
		}
	}
	if (segments && line) {
		let count = 0;
		line.forEachSegment(function (a, b) {
			const segment = new LineString([a, b]);
			const label = formatLength(segment);
			if (segmentStyles.length - 1 < count) {
				segmentStyles.push(segmentStyle.clone());
			}
			const segmentPoint = new Point(segment.getCoordinateAt(0.5));
			segmentStyles[count].setGeometry(segmentPoint);
			segmentStyles[count].getText().setText(label);
			styles.push(segmentStyles[count]);
			count++;
		});
	}
	if (label) {
		labelStyle.setGeometry(point);
		labelStyle.getText().setText(label);
		styles.push(labelStyle);
	}
	if (tip && type === 'Point' && !modify.getOverlay().getSource().getFeatures().length) {
		tipPoint = geometry;
		tipStyle.getText().setText(tip);
		styles.push(tipStyle);
	}

	feature.setProperties({
		id: 'MeasureTool'
		//code: "MeasureTool",
		//type: type
	});

	return styles;
}

const vector = new VectorLayer({
	source: source,
	style: (feature) => styleFunction(feature, true)
});

export default {
	name: 'mapmeasuretool',
	data() {
		return {
			toolLineActive: false,
			toolPolygonActive: false,
			lineMeasurement: constants.MEASUREMENT_LINE,
			polygonMeasurement: constants.MEASUREMENT_POLYGON,
			map: null,
			draw: null,
			modify: null,
			primaryColor: this.$store.state.global.primaryColor + 'd9'
		};
	},
	mounted() {},
	computed: {
		isToolLineActive() {
			return !!this.toolLineActive;
		},
		isToolPolygonActive() {
			return !!this.toolPolygonActive;
		}
	},
	watch: {
		toolLineActive: {
			handler(newValue, oldValue) {
				console.warn('toolLineActive watcher', oldValue, newValue);
			}
		}
	},
	methods: {
		interaction(type) {
			console.log('En la vista. Tipo: ', type);
			switch (type) {
				case constants.MEASUREMENT_LINE:
					this.toolLineActive = !this.toolLineActive;
					this.toolPolygonActive = false;
					this.activateTool(this.toolLineActive, type);
					break;
				case constants.MEASUREMENT_POLYGON:
					this.toolPolygonActive = !this.toolPolygonActive;
					this.toolLineActive = false;
					this.activateTool(this.toolPolygonActive, type);
					break;
			}
		},
		activateTool(activate, type) {
			if (this.draw) {
				this.removeInteraction();
			}
			if (activate) {
				this.addInteraction(type);
			}
		},
		removeInteraction() {
			source.clear();
			ol2map.getMapInstance().removeInteraction(this.draw);
		},
		addInteraction(type) {
			ol2map.getMapInstance().removeLayer(vector);

			const drawType = type;
			const activeTip = 'Click to continue drawing the ' + (drawType === 'Polygon' ? 'polygon' : 'line');
			const idleTip = 'Click to start measuring';
			let tip = idleTip;
			this.draw = new Draw({
				source: source,
				type: drawType,
				style: (feature) => styleFunction(feature, true, drawType, tip)
			});
			this.draw.on('drawstart', function () {
				//if (clearPrevious.checked) {
				//this.source.clear();
				//}
				source.clear();
				modify.setActive(false);
				console.log('source drawstart', source);
				tip = activeTip;
			});

			this.draw.on('drawend', function () {
				//source.clear();
				console.log('source drawend', source);
				modifyStyle.setGeometry(tipPoint);
				modify.setActive(true);

				ol2map.getMapInstance().once('pointermove', function () {
					modifyStyle.setGeometry();
				});
				tip = idleTip;
			});

			ol2map.getMapInstance().addInteraction(modify);
			ol2map.getMapInstance().addLayer(vector);
			modify.setActive(true);
			ol2map.getMapInstance().addInteraction(this.draw);
		}
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../styles/eiffel-variables.pcss';

.maptools {
	& .btnmeasure {
		margin: 0px;
		width: 32px;
		min-width: 32px !important;
		height: 32px;
		background-color: var(--negative);
	}
}
</style>
