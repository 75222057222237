import ol2map from '@/components/map/sections/map/subcomponents/ol2map';
import constants from '@/utils/constants';

/** Styles */
import areaNotificationStylePolygon from '@/components/map/sections/map/styles/areaNotificationStylePolygon';

export default {
	computed: {
		layers() {
			return this.$store.getters.layers;
		}
	},
	methods: {
		addAreaNotificationLayer() {
			const areaNotificationLayer = ol2map.createEmptyVectorLayer(constants.AREA_NOTIFICATION);
			ol2map.getMapInstance().addLayer(areaNotificationLayer);
			this.addAreasToLayer();
		},
		addAreasToLayer() {
			let selectedAreas = this.$store.getters.getSelectedAreas;
			if (selectedAreas.length > 0) {
				const areas = this.$store.getters.getAreas;
				const areaNotificationLayer = ol2map.getLayerById(constants.AREA_NOTIFICATION);
				if (!areaNotificationLayer) {
					return;
				}
				const sourceAreaNotification = areaNotificationLayer.getSource();
				selectedAreas.forEach(idArea => {
					let areaData = areas.find(area => area.pmareaid == idArea);
					let areaDefinition = {
						name: areaData.name,
						color: areaData.color,
						polygon: JSON.parse(areaData.areapoints)
					};

					// Invertir las coordenadas del polígono (de [lat, lon] a [lon, lat]) y crear la geometría
					const invertedPolygon = areaDefinition.polygon.map(coord => coord.reverse());
					let polygonGeometry = ol2map.createPolygonGeometry(invertedPolygon);
					polygonGeometry.transform('EPSG:4326', 'EPSG:3857');
					let feature = ol2map.createMapFeature(areaDefinition, polygonGeometry);

					// Aplicar estilo al feature basado en el color y agregarlo a la fuente de la capa
					feature.setStyle(areaNotificationStylePolygon);
					sourceAreaNotification.addFeature(feature);

					const newTemporalLayer = {
						active: true,
						datasourcetypeicon: '',
						identifier: areaDefinition.name,
						model: constants.AREA_NOTIFICATION,
						title: areaDefinition.name,
						color: areaDefinition.color
					};
					this.$set(this.layers, areaDefinition.name, newTemporalLayer); // ESTO NUNCA SE DEBERIA DE HACER ASII!!!
				});
			}
		},
		centerAreaMap(areaId) {
			let areas = this.$store.getters.getAreas;
			let selectedArea = areas.find(area => area.pmareaid == areaId);
			if (selectedArea && selectedArea.areapoints) {
				let areaDefinition = {
					name: selectedArea.name,
					color: selectedArea.color,
					polygon: JSON.parse(selectedArea.areapoints)
				};
				const areaNotificationLayer = ol2map.getLayerById(constants.AREA_NOTIFICATION);

				if (areaNotificationLayer) {
					const sourceAreaNotification = areaNotificationLayer.getSource();
					if (sourceAreaNotification && typeof sourceAreaNotification.addFeature === 'function') {
						// Invertir las coordenadas del polígono (de [lat, lon] a [lon, lat]) y crear la geometría
						const invertedPolygon = areaDefinition.polygon.map(coord => coord.reverse());
						let polygonGeometry = ol2map.createPolygonGeometry(invertedPolygon);
						polygonGeometry.transform('EPSG:4326', 'EPSG:3857');
						// Buscar si ya existe un polígono con las mismas propiedades
						let existingFeature = null;
						sourceAreaNotification.getFeatures().forEach(feature => {
							if (
								feature.get('name') === areaDefinition.name &&
								feature
									.getGeometry()
									.getCoordinates()
									.toString() === polygonGeometry.getCoordinates().toString() &&
								feature.get('color') === areaDefinition.color
							) {
								existingFeature = feature;
							}
						});

						if (existingFeature) {
							sourceAreaNotification.removeFeature(existingFeature);
							this.$delete(this.layers, areaDefinition.name);
						} else {
							// Crear un feature con la geometría y las propiedades
							let feature = ol2map.createMapFeature(areaDefinition, polygonGeometry);

							// Aplicar estilo al feature basado en el color y agregarlo a la fuente de la capa
							feature.setStyle(areaNotificationStylePolygon);
							sourceAreaNotification.addFeature(feature);

							const newTemporalLayer = {
								active: true,
								datasourcetypeicon: '',
								identifier: areaDefinition.name,
								model: constants.AREA_NOTIFICATION,
								title: areaDefinition.name,
								color: areaDefinition.color
							};
							this.$set(this.layers, areaDefinition.name, newTemporalLayer); // ESTO NUNCA SE DEBERIA DE HACER ASII!!!

							ol2map.zoomToTransformedExtent(ol2map.boundingExtent(invertedPolygon), 'EPSG:4326', 'EPSG:3857');
							this.$puiNotify.info(this.$t('map.areaSelected'), areaDefinition.name);
						}
					} else {
						console.error("La fuente de 'areaNotificationLayer' no es válida o no tiene 'addFeature'");
					}
				} else {
					console.error("No se encontró la capa 'areaNotificationLayer'");
				}
				return areaDefinition;
			} else {
				this.$puiNotify.warning(this.$t('map.areaNotFound'), this.$t('status.warning'));
			}
		}
	}
};
