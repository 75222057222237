<template>
	<div class="mapbaselayercontrol rounded elevation-1 pa-0">
		<v-menu v-model="menu" :close-on-content-click="false" offset-x nudge-right="5" :open-on-hover="true" close-delay="100">
			<template v-slot:activator="{ on, attrs }">
				<!-- <v-btn color="indigo" dark v-bind="attrs" v-on="on"> Menu as Popover </v-btn> -->
				<v-btn v-bind="attrs" v-on="on" id="image-btn" @click="buttonClicked">
					<v-img :src="baseLayersControl[baseLayerId].src" width="75" height="75" class="rounded"></v-img>
				</v-btn>
			</template>
			<v-container class="custom-menu-container" flat style="height: 75px">
				<v-row no-gutters>
					<v-col v-for="n in this.numberOfLayers" :key="n" cols="12" sm="6">
						<div
							class="card-container d-flex flex-column align-center justify-center"
							@click="
								() => {
									if (baseLayerId !== n - 1) cardClicked(n - 1);
								}
							"
						>
							<v-card max-width="50" flat>
								<v-img :src="baseLayersControl[n - 1].src" width="50" height="50" class="rounded"></v-img>
							</v-card>
							<span class="fw-600 fs-sm">{{ baseLayersControl[n - 1].title }}</span>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</v-menu>
	</div>
</template>

<script>
import ol2map from '@/components/map/sections/map/subcomponents/ol2map';

/** Mixins */
import BaseLayersMixin from '@/mixins/BaseLayersMixin';

/** Constants */
import { BASEMAP_ID } from '@/components/map/sections/map/subcomponents/mapVariables';
import {
	SETTINGS_PANEL_ID,
	NOTIFICATIONS_PANEL_ID,
	DEVICES_PANEL_ID,
	AQIPOINTS_PANEL_ID,
	SATELLITE_PANEL_ID,
	COMPAREMAPS_PANEL_ID
} from '@/lib/variables/panels';
export default {
	data: () => ({
		menu: false,
		baseLayerId: 0
	}),

	mixins: [BaseLayersMixin],

	computed: {
		numberOfLayers() {
			//console.log(' this.baseLayersControl', this.baseLayersControl);
			return this.baseLayersControl.length;
		},
		isOpened() {
			return this.$store.state.settingsPanel.isOpened.layersrasterSettings;
		},
		activePanel() {
			return this.$store.getters.activePanel;
		}
	},
	watch: {
		isOpened: {
			handler(val, prev) {
				console.log('isOpened', val, prev, this.baseLayerId, this.activePanel);
				if (this.activePanel == COMPAREMAPS_PANEL_ID) return;

				if (val && this.baseLayerId == 0) {
					this.cardClicked(1);
				} else if (!val && this.baseLayerId == 1) {
					this.cardClicked(0);
				}
			}
		},
		activePanel: {
			handler(val) {
				//console.log('activePanel', val, COMPAREMAPS_PANEL_ID, this.baseLayerId);
				switch (val) {
					case SETTINGS_PANEL_ID:
						if (this.baseLayerId == 1 && !this.isOpened) {
							this.cardClicked(0);
						}
						if (this.baseLayerId == 0 && this.isOpened) {
							this.cardClicked(1);
						}
						break;
					case NOTIFICATIONS_PANEL_ID:
						if (this.baseLayerId == 1) {
							this.cardClicked(0);
						}
						break;
					case DEVICES_PANEL_ID:
						if (this.baseLayerId == 1) {
							this.cardClicked(0);
						}
						break;
					case AQIPOINTS_PANEL_ID:
						if (this.baseLayerId == 1) {
							this.cardClicked(0);
						}
						break;
					case SATELLITE_PANEL_ID:
						/* var divMapContainer = document.getElementById('div-map-compare-container');
						if (divMapContainer) {
							divMapContainer.style.display = 'block';
						} */
						if (this.baseLayerId == 0) {
							this.cardClicked(1);
						}
						break;
					case COMPAREMAPS_PANEL_ID:
						/* var divMapContainer = document.getElementById('div-map-compare-container');
						if (divMapContainer) {
							divMapContainer.style.display = 'block';
						} */
						if (this.baseLayerId == 0) {
							this.cardClicked(1);
						}
						break;
					default:
						break;
				}
			}
		}
	},

	methods: {
		buttonClicked() {
			console.log('buttonClicked', this.baseLayerId);
			this.incrementBaseLayerId();
			this.changeBaseLayer(this.baseLayerId);
		},

		cardClicked(id) {
			console.log('cardClicked', id);
			this.changeBaseLayer(id);
		},

		changeBaseLayer(id) {
			// Eliminar la capa base anterior
			console.log('this.baseLayerId', id, this.baseLayerId);
			this.baseLayerId = id;
			ol2map.removeBaseLayerById(BASEMAP_ID);

			// Añadir la nueva capa base
			ol2map.insertLayerAtPosition(0, this.baseLayersControl[id].layer);
		},
		incrementBaseLayerId() {
			const baseLayerId = this.baseLayerId + 1;
			if (baseLayerId > this.numberOfLayers - 1) {
				this.baseLayerId = 0;
			} else {
				this.baseLayerId++;
			}
		}
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../styles/app-variables.pcss';

.mapbaselayercontrol {
	position: absolute;
	bottom: 1rem;
	z-index: 2;
	left: 1.5rem;
	background-color: var(--negative);
	opacity: 0.9 !important;
	padding: 3px;
}

#image-btn {
	height: 75px;
	width: 75px;
	padding: 0px;
	margin: 0px;
}

.custom-menu-container {
	display: flex;
	justify-content: center;
	background-color: whitesmoke;
	padding: 0px;
	margin: 0px;
	width: fit-content;
	overflow: hidden;
}

.card {
	margin: 2px;
	padding: 0px;
}

.card-container {
	cursor: pointer;
	padding: 5px;
	transition:
		transform 0.2s,
		box-shadow 0.2s;
}

.card-container:hover {
	transform: scale(1.05);
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
</style>
