import { formatTimestamp, getStatusProperties, isPointInPolygon } from '@/api/common';
import constants from '@/utils/constants';
import moment from 'moment-timezone';
export default {
	data() {
		return { noStatusColor: '#6f7480' };
	},
	/* computed: {
		fullscreenDialog() {
			return this.$store.getters.getFullscreenDialog;
		}
	}, */
	methods: {
		initChart(pollutant, historicPollutantData, designOption = null, showIntervals = false) {
			//Proceso datos historicos del contaminante
			const pollutantAcronym = pollutant.acronym;
			const pollutantSymbol = pollutant.symbol;
			const elasticacronym = pollutant.elasticacronym;
			let pollutantValues = [];
			let pollutantDays = [];

			console.info('debug historic data', historicPollutantData, this.chartType, designOption.id);
			if (historicPollutantData && typeof historicPollutantData.message !== 'string') {
				let documents = [];
				if (Array.isArray(historicPollutantData)) documents = historicPollutantData;
				else documents = historicPollutantData.message.map((hit) => hit._source);
				documents.forEach((item) => {
					pollutantDays.push(
						formatTimestamp(item[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties, false, item['timezone'])
					);
					pollutantValues.push(parseFloat(item[elasticacronym].toFixed(2)));
				});
			}

			//Configuro opciones del grafico
			let option = designOption ? designOption.config : this.parameterConfiguration;
			console.info('info: chart option', option, designOption, this.parameterConfiguration);

			let pollutantTitle =
				pollutantSymbol && pollutantSymbol.trim().length > 0 ? pollutantAcronym + ' (' + pollutantSymbol + ')' : pollutantAcronym;
			if (option['series'].length > 0) option['series'][0].name = pollutantAcronym;
			else option['series'][0] = { name: pollutantAcronym, type: 'line', data: [] };

			console.log('apply ranges init', pollutantValues, designOption, option['xAxis'], option['series'][0]);
			if (Object.hasOwn(option, 'xAxis') && typeof option['xAxis']['data'] === 'string') {
				// FREQUENCY (HISTOGRAM)
				const xAxisFunction = eval(option['xAxis']['data']);
				option['xAxis']['data'] = xAxisFunction(pollutantValues);
				if (typeof option['series'][0].data === 'string') {
					const seriesFunction = eval(option['series'][0].data);
					option['series'][0].data = seriesFunction(pollutantValues);
				}
				option['series'][0].itemStyle = {
					color: this.noStatusColor
				};
			} else if (option['series'].length > 0 && typeof option['series'][0]['data'] === 'string') {
				// FREQUENCY (PIE CHART)
				const seriesFunction = eval(option['series'][0].data);
				option['series'][0].data = seriesFunction(pollutantValues);
				option.tooltip.formatter = eval(option.tooltip.formatter);
			} else {
				option['legend'] = { data: [pollutantTitle], top: '3%' };
				option['series'][0].name = pollutantTitle;
				if (Object.hasOwn(option, 'xAxis')) option['xAxis']['data'] = pollutantDays;

				let pieces = [];
				let parameterStatus = this.$store.getters.getParameterStatus.find((status) => status.pmparameterid === pollutant.pmparameterid);

				if (parameterStatus && designOption.applyranges === 1) {
					const statusArray = JSON.parse(parameterStatus.status);
					const colors = JSON.parse(parameterStatus.color);
					statusArray.sort((a, b) => {
						const minimumA = a.min.data;
						const minimumB = b.min.data;
						if (minimumA < minimumB) {
							return -1;
						}
						if (minimumA > minimumB) {
							return 1;
						}
						return 0; // Objects have the same minimum (statement should be unreachable)
					});
					statusArray.forEach((status, i) => {
						pieces.push({
							/* gte: status.minvalue,
							lte: status.maxvalue, */
							[status.min.op]: status.min.data,
							[status.max.op]: status.max.data,
							color: colors[i]
						});
					});

					option['visualMap'] = {
						top: '20%',
						right: '3%',
						pieces: pieces,
						outOfRange: {
							color: this.noStatusColor
						}
					};
				} else {
					option['series'][0].lineStyle = {
						color: this.noStatusColor
					};
					if (Object.hasOwn(option.series[0], 'areaStyle')) option.series[0].areaStyle = { color: this.noStatusColor };
				}

				option['series'][0].data = pollutantValues.map((value) => {
					const piece = pieces.find((p) => {
						let min = null;
						let max = null;
						if (p.gt) min = 'gt';
						if (p.gte) min = 'gte';
						if (p.lt) max = 'lt';
						if (p.lte) max = 'lte';
						const isEqual = p[min] === p[max];
						if (isEqual) {
							// Aplicar tolerancia de 0.1 si los límites son iguales
							return value >= p[min] && value - 0.1 <= p[max];
						} else {
							if (min && max) {
								return value >= p[min] && value <= p[max];
							} else if (min) {
								return value >= p[min];
							} else if (max) {
								return value <= p[max];
							}
						}
					});

					return {
						value: value,
						itemStyle: {
							color: piece ? piece.color : this.noStatusColor
						}
					};
				});
				option['series'][0].markPoint = {
					data: [
						{ type: 'max', name: 'Max' },
						{ type: 'min', name: 'Min' }
					],
					symbol: 'roundRect',
					symbolSize: [50, 20],
					itemStyle: {
						color: '#303e5a'
					}
					/* label: {
						formatter: pollutantSymbol ? '{@score}' + pollutantSymbol : '{@score}'
					} */
				};
				option['series'][0].markLine = {
					data: [{ type: 'average', name: 'Avg' }]
				};
			}
			if (option.toolbox && Object.hasOwn(option.toolbox, 'feature')) {
				option.toolbox.top = 15;
				option.toolbox.right = 30;
				if (Object.hasOwn(option.toolbox.feature, 'dataView')) {
					option['toolbox']['feature']['dataView']['title'] = this.$t('charts.title');
					option['toolbox']['feature']['dataView']['lang'] = [
						this.$t('charts.dataView'),
						this.$t('charts.closeView'),
						this.$t('charts.updateData')
					];

					option.toolbox.feature.dataView.optionToContent = this.setOptionToContent(designOption.id);
				}
				if (Object.hasOwn(option.toolbox.feature, 'restore')) {
					option['toolbox']['feature']['restore']['title'] = this.$t('charts.restore');
				}
				if (Object.hasOwn(option.toolbox.feature, 'saveAsImage')) {
					option['toolbox']['feature']['saveAsImage']['title'] = this.$t('charts.saveAsImage');
				}
			}

			//option['series'][0].type = this.chartType;
			//console.log('show intervals init', this.form.showIntervals, option);
			if (showIntervals) {
				option['series'][0].markArea = {
					silent: true,
					data: [[{ yAxis: pollutant.thresholdminvalue }, { yAxis: pollutant.thresholdmaxvalue }]],
					itemStyle: { borderType: 'dashed', borderWidth: 2, color: '#014efa33', borderColor: '#014efa' }
				};
			} else {
				if (Object.hasOwn(option['series'][0], 'markArea')) delete option['series'][0].markArea;
			}
			option['textStyle'] = {
				fontFamily: 'Montserrat, sans-serif',
				fontSize: 14
			};
			option.grid = {
				//height: this.fullscreenDialog ? 'calc(100% - 200px)' : '60%',
				bottom: 70,
				left: '4%',
				width: '87%'
			};
			//console.log('set historic option: ', this.displayLocation, option, JSON.stringify(option), option.series[0].data.length);
			return option;
		},
		initHeatMap(pollutant, historicPollutantData, designOption = null) {
			const pollutantAcronym = pollutant.acronym;
			let pollutantSymbol = pollutant.symbol;
			const elasticacronym = pollutant.elasticacronym;
			console.info('debug historic data HMAP', historicPollutantData, this.chartType, designOption);
			let minValue = 0;
			let maxValue = 300;
			const dayCount = this.form.dateInterval + 1;
			const hourLabels = Array.from({ length: 24 }, (_, i) => i);
			const dayLabels = Array.from({ length: dayCount }, (_, i) =>
				formatTimestamp(Date.now() - i * 24 * 60 * 60 * 1000, this.userProperties, true)
			);

			let valueCount = {};
			let heatmapData = {};
			dayLabels.forEach((day) => {
				valueCount[day] = Array(24).fill(0);
				heatmapData[day] = Array(24).fill(0);
			});

			console.log('debug heatmap data', heatmapData, hourLabels, dayLabels, dayCount);
			console.log('debug heatmap data', valueCount, historicPollutantData.message);
			if (historicPollutantData && typeof historicPollutantData.message !== 'string') {
				let documents = [];
				if (Array.isArray(historicPollutantData)) documents = historicPollutantData;
				else documents = historicPollutantData.message.map((hit) => hit._source);
				documents.forEach((item) => {
					//const date = new Date(item._source[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP]);
					//const hour = date.getHours();
					let localTime = moment.tz(item[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties.timezoneidentifier);
					const hour = localTime.hours();
					console.log('debug local time', localTime, localTime.format(this.userProperties.constants), hour);
					const dayIndex = dayLabels.indexOf(formatTimestamp(item[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties, true));
					const value = item[elasticacronym];
					if (dayIndex !== -1) {
						heatmapData[dayLabels[dayIndex]][hour] += value;
						valueCount[dayLabels[dayIndex]][hour] += 1; // Contador
					}
				});
				if (Object.hasOwn(historicPollutantData, 'aggs')) {
					minValue = historicPollutantData.aggs['min_' + elasticacronym].value;
					maxValue = historicPollutantData.aggs['max_' + elasticacronym].value;
				}
			}

			// Calcular la media
			let formattedHeatmapData = [];
			dayLabels.forEach((day) => {
				heatmapData[day].forEach((hourValue, hourIndex) => {
					formattedHeatmapData.push([hourIndex, day, hourValue ? parseFloat((hourValue / valueCount[day][hourIndex]).toFixed(2)) : null]);
				});
			});

			/* heatmapData.forEach((data, index) => {
				const hour = Math.floor(index / dayCount);
				const dayIndex = index % dayCount;
				if (valueCount[hour][dayIndex] > 0) {
					data[2] /= valueCount[data][dayIndex]; // Obtengo la media
				} else {
					data[2] = 0; // Si no hay valores, pinto un 0
				}
			}); */
			let pollutantTitle =
				pollutantSymbol && pollutantSymbol.trim().length > 0 ? pollutantAcronym + ' (' + pollutantSymbol + ')' : pollutantAcronym;
			let option = designOption ? designOption.config : this.parameterConfiguration;

			if (!pollutantSymbol) pollutantSymbol = '';
			option.tooltip.formatter = (params) => {
				return (
					'Hour: ' +
					(params.data[0] < 10 ? '0' : '') +
					params.data[0] +
					':00<br/>Day: ' +
					params.data[1] +
					'<br/>Average value: ' +
					params.data[2] +
					' ' +
					pollutantSymbol
				);
			};
			option.xAxis.data = hourLabels.map((hour) => {
				if (hour < 10) return '0' + hour + ':00';
				else return hour + ':00';
			});
			option.yAxis.data = dayLabels;
			option.visualMap.min = minValue;
			option.visualMap.max = maxValue;
			option.series[0].name = pollutantTitle;
			option.series[0].data = formattedHeatmapData;
			if (option.toolbox && Object.hasOwn(option.toolbox, 'feature')) {
				option.toolbox.top = 15;
				option.toolbox.right = 30;
				if (Object.hasOwn(option.toolbox.feature, 'dataView')) {
					option['toolbox']['feature']['dataView']['title'] = this.$t('charts.title');
					option['toolbox']['feature']['dataView']['lang'] = [
						this.$t('charts.dataView'),
						this.$t('charts.closeView'),
						this.$t('charts.updateData')
					];

					option.toolbox.feature.dataView.optionToContent = (opt) => {
						let seriesData = opt.series[0].data;
						let seriesName = opt.series[0].name;
						seriesData.reverse();
						let categories = seriesData.map((item) => (item[0] < 10 ? '0' + item[0] : item[0]) + ':00');
						let ws_data = [...categories.map((category, index) => [seriesData[index][1], category, seriesData[index][2]])];
						ws_data.sort((a, b) => {
							if (a[1] < b[1]) {
								return -1;
							}
							if (a[1] > b[1]) {
								return 1;
							}
							return 0;
						});
						ws_data.sort((a, b) => {
							if (a[0] < b[0]) {
								return -1;
							}
							if (a[0] > b[0]) {
								return 1;
							}
							return 0;
						});

						let table =
							'<table style="width:100%;text-align:start;" class="data-view-table mb-4 cell-border hover stripe nowrap full-width dataTable no-footer">' +
							'<thead class=""><tr>' +
							'<th>' +
							this.$t('rasterTools.date') +
							'</th>' +
							'<th>' +
							this.$t('panels.device.hour') +
							'</th>' +
							'<th>' +
							seriesName +
							'</th>' +
							'</tr></thead><tbody>';
						let odd = false;
						ws_data.forEach((item) => {
							let rowClass = odd ? 'odd' : 'even';
							odd = !odd;
							table +=
								'<tr class="' +
								rowClass +
								'">' +
								'<td>' +
								item[0] +
								'</td>' +
								'<td>' +
								item[1] +
								'</td>' +
								'<td>' +
								(item[2] !== null ? item[2] : '-') +
								'</td>' +
								'</tr>';
						});

						table += '</tbody></table>';
						return table;
					};
				}
				if (Object.hasOwn(option.toolbox.feature, 'restore')) {
					option['toolbox']['feature']['restore']['title'] = this.$t('charts.restore');
				}
				if (Object.hasOwn(option.toolbox.feature, 'saveAsImage')) {
					option['toolbox']['feature']['saveAsImage']['title'] = this.$t('charts.saveAsImage');
				}
			}
			return option;
		},
		initStackedSeries(pollutant, historicPollutantData, designOption = null) {
			const pollutantAcronym = pollutant.acronym;
			const pollutantSymbol = pollutant.symbol;
			const elasticacronym = pollutant.elasticacronym;
			const dayCount = this.form.dateInterval + 1;
			const hourLabels = Array.from({ length: 24 }, (_, i) => i);
			const dayLabels = Array.from({ length: dayCount }, (_, i) =>
				formatTimestamp(Date.now() - i * 24 * 60 * 60 * 1000, this.userProperties, true)
			);
			let stackedData = {};
			let valueCount = {};
			dayLabels.forEach((day) => {
				valueCount[day] = Array(24).fill(0);
				stackedData[day] = Array(24).fill(0);
			});
			console.info(
				'debug historic data STACK',
				pollutantAcronym,
				pollutantSymbol,
				historicPollutantData,
				this.chartType,
				designOption.id,
				valueCount
			);
			if (historicPollutantData && typeof historicPollutantData.message !== 'string') {
				let documents = [];
				if (Array.isArray(historicPollutantData)) documents = historicPollutantData;
				else documents = historicPollutantData.message.map((hit) => hit._source);
				documents.forEach((item) => {
					let localTime = moment.tz(item[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties.timezoneidentifier);
					const hour = localTime.hours();
					const dayIndex = dayLabels.indexOf(formatTimestamp(item[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties, true));
					const value = item[elasticacronym];
					if (dayIndex !== -1) {
						stackedData[dayLabels[dayIndex]][hour] += value;
						valueCount[dayLabels[dayIndex]][hour] += 1;
					}
				});
			}
			//Configuro opciones del grafico
			let option = designOption ? designOption.config : this.parameterConfiguration;

			option.legend = { data: dayLabels, top: '3%' };
			if (dayLabels.length > 10) option.legend.right = '8%';
			option.xAxis.data = hourLabels.map((hour) => {
				if (hour < 10) return '0' + hour + ':00';
				else return hour + ':00';
			});
			if (typeof option.tooltip.formatter == 'string') option.tooltip.formatter = eval(option.tooltip.formatter);
			option.series = [];
			dayLabels.forEach((day) => {
				option.series.push({
					name: day,
					type: 'line',
					stack: 'total',
					//tooltip: { valueFormatter: (value) => (value ? value + ' ' + pollutantSymbol : '-') },
					data: stackedData[day].map((hour, i) => (hour ? parseFloat((hour / valueCount[day][i]).toFixed(2)) : null))
				});
			});
			option.grid = {
				//height: this.fullscreenDialog ? '100%' : dayLabels.length > 10 ? '50%' : '60%',
				bottom: 70,
				left: '4%',
				width: '87%'
			};
			if (option.toolbox && Object.hasOwn(option.toolbox, 'feature')) {
				option.toolbox.top = 15;
				option.toolbox.right = 30;
				if (Object.hasOwn(option.toolbox.feature, 'dataView')) {
					option['toolbox']['feature']['dataView']['title'] = this.$t('charts.title');
					option['toolbox']['feature']['dataView']['lang'] = [
						this.$t('charts.dataView'),
						this.$t('charts.closeView'),
						this.$t('charts.updateData')
					];

					option.toolbox.feature.dataView.optionToContent = (opt) => {
						const optionSeries = opt.series.filter((serie) => serie.type !== 'pie');
						let categories = Array.isArray(option.xAxis) ? option.xAxis[0].data : option.xAxis.data;
						let ws_data = [];

						optionSeries.forEach((serie) => {
							serie.data.forEach((value, i) => {
								ws_data.push([serie.name, categories[i], value]);
							});
						});

						ws_data.sort((a, b) => {
							if (a[1] < b[1]) {
								return -1;
							}
							if (a[1] > b[1]) {
								return 1;
							}
							return 0;
						});
						ws_data.sort((a, b) => {
							if (a[0] < b[0]) {
								return -1;
							}
							if (a[0] > b[0]) {
								return 1;
							}
							return 0;
						});

						let table =
							'<table style="width:100%;text-align:start;" class="data-view-table mb-4 cell-border hover stripe nowrap full-width dataTable no-footer">' +
							'<thead class=""><tr>' +
							'<th>' +
							this.$t('rasterTools.date') +
							'</th>' +
							'<th>' +
							this.$t('panels.device.hour') +
							'</th>' +
							'<th>' +
							pollutant.name +
							'</th>' +
							'</tr></thead><tbody>';
						let odd = false;
						ws_data.forEach((item) => {
							let rowClass = odd ? 'odd' : 'even';
							odd = !odd;
							table +=
								'<tr class="' +
								rowClass +
								'">' +
								'<td>' +
								item[0] +
								'</td>' +
								'<td>' +
								item[1] +
								'</td>' +
								'<td>' +
								(item[2] !== null ? item[2] : '-') +
								'</td>' +
								'</tr>';
						});

						table += '</tbody></table>';
						return table;
					};
				}
				if (Object.hasOwn(option.toolbox.feature, 'restore')) {
					option['toolbox']['feature']['restore']['title'] = this.$t('charts.restore');
				}
				if (Object.hasOwn(option.toolbox.feature, 'saveAsImage')) {
					option['toolbox']['feature']['saveAsImage']['title'] = this.$t('charts.saveAsImage');
				}
			}
			return option;
		},
		initChartDatasource(pollutant, elasticData, designOption = null, showIntervals = false) {
			const pollutantAcronym = pollutant.acronym;
			let pollutantSymbol = pollutant.symbol;
			const elasticacronym = pollutant.elasticacronym;
			let pollutantValues = {};
			let pollutantDays = {};
			let pollutantData = {};
			let allDates = [];
			let averageSum = {};
			let averageCount = {};

			let minValue = 0;
			let maxValue = 0;
			let minValueDevice = '';
			let maxValueDevice = '';
			let minValueDate = '';
			let maxValueDate = '';
			let globalSum = 0;
			let globalCount = 0;
			let isCardLocation = this.displayLocation == 'card';

			console.log('initChartDatasource ', pollutantAcronym, elasticacronym, elasticData.message, designOption);
			if (elasticData && typeof elasticData.message !== 'string') {
				let documents = elasticData.message.map((hit) => hit._source);
				let documentsByDevice = this.groupByDevice(documents);
				if (Object.hasOwn(elasticData, 'aggs')) {
					minValue = parseFloat(elasticData.aggs['min_' + elasticacronym].value.toFixed(2));
					maxValue = parseFloat(elasticData.aggs['max_' + elasticacronym].value.toFixed(2));
				}

				globalCount = documents.length;
				documents.forEach((doc) => {
					if (!allDates.includes(formatTimestamp(doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties)))
						allDates.push(formatTimestamp(doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties));
					if (parseFloat(doc[elasticacronym].toFixed(2)) == minValue) {
						minValueDevice = doc.device;
						minValueDate = formatTimestamp(doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties);
					}
					if (parseFloat(doc[elasticacronym].toFixed(2)) == maxValue) {
						maxValueDevice = doc.device;
						maxValueDate = formatTimestamp(doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties);
					}
					globalSum += doc[elasticacronym];
					if (Object.hasOwn(averageSum, doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP])) {
						averageSum[doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP]] += doc[elasticacronym];
						averageCount[doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP]]++;
					} else {
						averageSum[doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP]] = doc[elasticacronym];
						averageCount[doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP]] = 1;
					}
				});
				console.log(
					'debug average serie data: ',
					averageSum,
					averageCount,
					Object.keys(averageSum).map((dateKey) => [
						parseInt(dateKey),
						parseFloat((averageSum[dateKey] / averageCount[dateKey]).toFixed(2))
					])
				);
				console.log('debug initChartDatasource', documents, documentsByDevice, allDates, minValueDevice, maxValueDevice);
				documentsByDevice.forEach((deviceGroup) => {
					pollutantDays[deviceGroup.device] = [];
					pollutantValues[deviceGroup.device] = [];
					pollutantData[deviceGroup.device] = [];
					deviceGroup.group.forEach((item) => {
						pollutantDays[deviceGroup.device].push(formatTimestamp(item[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties));
						pollutantValues[deviceGroup.device].push(parseFloat(item[elasticacronym].toFixed(2)));
						pollutantData[deviceGroup.device].push([
							item[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP],
							parseFloat(item[elasticacronym].toFixed(2))
						]);
					});
				});

				documentsByDevice.forEach((deviceGroup) => {
					if (allDates.length != pollutantDays[deviceGroup.device].length) {
						allDates.forEach((date, i) => {
							if (!pollutantDays[deviceGroup.device].includes(date)) {
								pollutantDays[deviceGroup.device] = pollutantDays[deviceGroup.device].toSpliced(i, 0, date);
								pollutantValues[deviceGroup.device] = pollutantValues[deviceGroup.device].toSpliced(i, 0, null);
							}
						});
					}
				});
			}

			//Configuro opciones del grafico
			let option = designOption ? designOption.config : this.parameterConfiguration;
			console.info('info: chart option', option, designOption, this.parameterConfiguration, minValue, maxValue);

			//let pollutantTitle =pollutantSymbol && pollutantSymbol.trim().length > 0 ? pollutantAcronym + ' (' + pollutantSymbol + ')' : pollutantAcronym;
			this.metricAverage =
				(globalSum > 0
					? parseInt(globalSum / globalCount) === globalSum / globalCount
						? globalSum / globalCount
						: (globalSum / globalCount).toFixed(2)
					: '-') +
				' ' +
				(pollutantSymbol || '');
			this.metricMin = {
				value: minValue + ' ' + (pollutantSymbol || ''),
				device: minValueDevice,
				date: minValueDate
			};
			this.metricMax = {
				value: maxValue + ' ' + (pollutantSymbol || ''),
				device: maxValueDevice,
				date: maxValueDate
			};
			if (Object.keys(pollutantValues).length > 1) {
				option['legend'] = {
					data: [this.$t('panels.notifications.average_operator')].concat(Object.keys(pollutantValues)),
					top: isCardLocation ? '3%' : '1%'
				};
			} else {
				option['legend'] = {
					data: Object.keys(pollutantValues),
					top: isCardLocation ? '3%' : '1%'
				};
			}

			option.tooltip.formatter = (params) => {
				let dateLabel = formatTimestamp(params[0].axisValue, this.userProperties);
				let element = '<div class="pa-0"><span class="pb-2">' + dateLabel + '</span>';
				params.forEach((seriesParams) => {
					element +=
						'<div class="d-flex justify-space-between"><span class="pr-2">' +
						seriesParams.marker +
						' ' +
						seriesParams.seriesName +
						'</span><b>' +
						seriesParams.data[1] +
						' ' +
						(pollutantSymbol ? pollutantSymbol : '') +
						'</b></div>';
				});

				element += '</div>';

				return element;
			};
			option.series = [];
			if (Object.keys(pollutantValues).length > 1) {
				option.series.push({
					name: this.$t('panels.notifications.average_operator'),
					id: 'avg',
					type: 'line',
					smooth: false,
					emphasis: {
						focus: 'series'
					},
					data: Object.keys(averageSum).map((dateKey) => [
						parseInt(dateKey),
						parseFloat((averageSum[dateKey] / averageCount[dateKey]).toFixed(2))
					])
				});
			}

			Object.keys(pollutantValues).forEach((device) => {
				option.series.push({
					name: device,
					id: device,
					type: 'line',
					smooth: false,
					emphasis: {
						focus: 'series'
					},
					data: pollutantData[device]
				});
			});

			option.xAxis = {
				type: 'time', // Eje X de tipo tiempo
				boundaryGap: false,
				splitLine: {
					show: true
				}
			};
			option.yAxis = {
				type: 'value',
				splitLine: {
					show: true
				}
			};

			/* if (Object.hasOwn(option, 'xAxis')) option['xAxis']['data'] = allDates; */
			console.log('debug min and max', minValue, maxValue, minValueDevice, maxValueDevice, option.series[0].name);
			/* option.series.forEach((serie) => {
				if (minValueDevice == serie.name) {
					if (Object.hasOwn(serie, 'markPoint')) {
						serie.markPoint.data
							? serie.markPoint.data.push({ type: 'min', name: 'Min' })
							: (serie.markPoint.data = [{ type: 'min', name: 'Min' }]);
					} else {
						serie.markPoint = {
							data: [{ type: 'min', name: 'Min' }],
							symbol: 'pin',
							symbolSize: 50
						};
					}
				}

				if (maxValueDevice == serie.name) {
					if (Object.hasOwn(serie, 'markPoint')) {
						serie.markPoint.data
							? serie.markPoint.data.push({ type: 'max', name: 'Max' })
							: (serie.markPoint.data = [{ type: 'max', name: 'Max' }]);
					} else {
						serie.markPoint = {
							data: [{ type: 'max', name: 'Max' }],
							symbol: 'pin',
							symbolSize: 50
						};
					}
				}
			}); */

			if (option.series.length == 1) {
				option.series[0].markLine = {
					data: [{ type: 'average', name: 'Avg' }]
				};
			}

			if (option.toolbox && Object.hasOwn(option.toolbox, 'feature')) {
				option.toolbox.top = 15;
				option.toolbox.right = 30;
				if (Object.hasOwn(option.toolbox.feature, 'dataView')) {
					option['toolbox']['feature']['dataView']['title'] = this.$t('charts.title');
					option['toolbox']['feature']['dataView']['lang'] = [
						this.$t('charts.dataView'),
						this.$t('charts.closeView'),
						this.$t('charts.updateData')
					];

					option.toolbox.feature.dataView.optionToContent = this.setOptionToContentDatasource(
						allDates,
						pollutantValues,
						pollutantSymbol || ''
					);
				}
				if (Object.hasOwn(option.toolbox.feature, 'restore')) {
					option['toolbox']['feature']['restore']['title'] = this.$t('charts.restore');
				}
				if (Object.hasOwn(option.toolbox.feature, 'saveAsImage')) {
					option['toolbox']['feature']['saveAsImage']['title'] = this.$t('charts.saveAsImage');
				}
			}

			//option['series'][0].type = this.chartType;
			//console.log('show intervals init', this.form.showIntervals, option);
			if (showIntervals) {
				option['series'][0].markArea = {
					silent: true,
					data: [[{ yAxis: pollutant.thresholdminvalue }, { yAxis: pollutant.thresholdmaxvalue }]],
					itemStyle: { borderType: 'dashed', borderWidth: 2, color: '#014efa33', borderColor: '#014efa' }
				};
			} else {
				if (Object.hasOwn(option['series'][0], 'markArea')) delete option['series'][0].markArea;
			}
			option['textStyle'] = {
				fontFamily: 'Montserrat, sans-serif',
				fontSize: 14
			};
			option.grid = {
				//height: this.fullscreenDialog ? '100%' : isCardLocation ? '60%' : '64%',
				bottom: 70,
				left: '4%',
				width: '87%'
			};
			console.log('set historic option: ', this.displayLocation, option, option.series[0].data.length);
			this.advancedProps = { categories: allDates, values: pollutantValues, avg: option.series[0].data };
			return option;
		},
		initChartDatasourceDevices(pollutant, elasticData, designOption = null, showIntervals = false) {
			const pollutantAcronym = pollutant.acronym;
			let pollutantSymbol = pollutant.symbol;
			const elasticacronym = pollutant.elasticacronym;
			let pollutantValues = {};
			let pollutantDays = {};
			let pollutantData = {};
			let allDates = [];
			let averageSum = {};
			let averageCount = {};

			let minValue = 0;
			let maxValue = 0;
			let minValueDevice = '';
			let maxValueDevice = '';
			let minValueDate = '';
			let maxValueDate = '';
			let globalSum = 0;
			let globalCount = 0;
			let isCardLocation = this.displayLocation == 'card';

			console.log('initChartDatasource ', pollutantAcronym, elasticacronym, elasticData.message, designOption);
			if (elasticData && typeof elasticData.message !== 'string') {
				let documents = [];
				if (Array.isArray(elasticData)) documents = elasticData;
				else documents = elasticData.message.map((hit) => hit._source);
				let documentsByDevice = this.groupByDevice(documents);
				if (Object.hasOwn(elasticData, 'aggs')) {
					minValue = parseFloat(elasticData.aggs['min_' + elasticacronym].value.toFixed(2));
					maxValue = parseFloat(elasticData.aggs['max_' + elasticacronym].value.toFixed(2));
				}

				globalCount = documents.length;
				documents.forEach((doc) => {
					if (!allDates.includes(formatTimestamp(doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties)))
						allDates.push(formatTimestamp(doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties));
					if (parseFloat(doc[elasticacronym].toFixed(2)) == minValue) {
						minValueDevice = doc.device;
						minValueDate = formatTimestamp(doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties);
					}
					if (parseFloat(doc[elasticacronym].toFixed(2)) == maxValue) {
						maxValueDevice = doc.device;
						maxValueDate = formatTimestamp(doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties);
					}
					globalSum += doc[elasticacronym];
					if (Object.hasOwn(averageSum, doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP])) {
						averageSum[doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP]] += doc[elasticacronym];
						averageCount[doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP]]++;
					} else {
						averageSum[doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP]] = doc[elasticacronym];
						averageCount[doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP]] = 1;
					}
				});
				console.log(
					'debug average serie data: ',
					averageSum,
					averageCount,
					Object.keys(averageSum).map((dateKey) => [
						parseInt(dateKey),
						parseFloat((averageSum[dateKey] / averageCount[dateKey]).toFixed(2))
					])
				);
				console.log('debug initChartDatasource', documents, documentsByDevice, allDates, minValueDevice, maxValueDevice);
				documentsByDevice.forEach((deviceGroup) => {
					pollutantDays[deviceGroup.device] = [];
					pollutantValues[deviceGroup.device] = [];
					pollutantData[deviceGroup.device] = [];
					deviceGroup.group.forEach((item) => {
						pollutantDays[deviceGroup.device].push(formatTimestamp(item[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties));
						pollutantValues[deviceGroup.device].push(parseFloat(item[elasticacronym].toFixed(2)));
						pollutantData[deviceGroup.device].push([
							item[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP],
							parseFloat(item[elasticacronym].toFixed(2))
						]);
					});
				});

				documentsByDevice.forEach((deviceGroup) => {
					if (allDates.length != pollutantDays[deviceGroup.device].length) {
						allDates.forEach((date, i) => {
							if (!pollutantDays[deviceGroup.device].includes(date)) {
								pollutantDays[deviceGroup.device] = pollutantDays[deviceGroup.device].toSpliced(i, 0, date);
								pollutantValues[deviceGroup.device] = pollutantValues[deviceGroup.device].toSpliced(i, 0, null);
							}
						});
					}
				});
			}

			//Configuro opciones del grafico
			let option = designOption ? designOption.config : this.parameterConfiguration;
			console.info('info: chart option', option, designOption, this.parameterConfiguration, minValue, maxValue);

			//let pollutantTitle =pollutantSymbol && pollutantSymbol.trim().length > 0 ? pollutantAcronym + ' (' + pollutantSymbol + ')' : pollutantAcronym;
			this.metricAverage =
				(globalSum > 0
					? parseInt(globalSum / globalCount) === globalSum / globalCount
						? globalSum / globalCount
						: (globalSum / globalCount).toFixed(2)
					: '-') +
				' ' +
				(pollutantSymbol || '');
			this.metricMin = {
				value: minValue + ' ' + (pollutantSymbol || ''),
				device: minValueDevice,
				date: minValueDate
			};
			this.metricMax = {
				value: maxValue + ' ' + (pollutantSymbol || ''),
				device: maxValueDevice,
				date: maxValueDate
			};

			if (Object.keys(pollutantValues).length > 1) {
				option['legend'] = {
					data: [this.$t('panels.notifications.average_operator')].concat(Object.keys(pollutantValues)),
					top: isCardLocation ? '3%' : '1%'
				};
			} else {
				option['legend'] = {
					data: Object.keys(pollutantValues),
					top: isCardLocation ? '3%' : '1%'
				};
			}
			option.tooltip.formatter = (params) => {
				let dateLabel = formatTimestamp(params[0].axisValue, this.userProperties);
				let element = '<div class="pa-0"><span class="pb-2">' + dateLabel + '</span>';
				params.forEach((seriesParams) => {
					element +=
						'<div class="d-flex justify-space-between"><span class="pr-2">' +
						seriesParams.marker +
						' ' +
						seriesParams.seriesName +
						'</span><b>' +
						seriesParams.data[1] +
						' ' +
						(pollutantSymbol ? pollutantSymbol : '') +
						'</b></div>';
				});

				element += '</div>';

				return element;
			};
			option.series = [];
			if (Object.keys(pollutantValues).length > 1) {
				option.series.push({
					name: this.$t('panels.notifications.average_operator'),
					id: 'avg',
					type: 'line',
					smooth: false,
					emphasis: {
						focus: 'series'
					},
					data: Object.keys(averageSum).map((dateKey) => [
						parseInt(dateKey),
						parseFloat((averageSum[dateKey] / averageCount[dateKey]).toFixed(2))
					])
				});
			}

			Object.keys(pollutantValues).forEach((device) => {
				option.series.push({
					name: device,
					id: device,
					type: 'line',
					smooth: false,
					emphasis: {
						focus: 'series'
					},
					data: pollutantData[device]
				});
			});
			if (option.series.length > 0) {
				option.xAxis = {
					type: 'time', // Eje X de tipo tiempo
					boundaryGap: false,
					splitLine: {
						show: true
					}
				};
				option.yAxis = {
					type: 'value',
					splitLine: {
						show: true
					}
				};
				console.log('debug min and max', minValue, maxValue, minValueDevice, maxValueDevice);

				if (option.series.length == 1) {
					option.series[0].markLine = {
						data: [{ type: 'average', name: 'Avg' }]
					};
				}

				if (option.toolbox && Object.hasOwn(option.toolbox, 'feature')) {
					option.toolbox.top = 15;
					option.toolbox.right = 30;
					if (Object.hasOwn(option.toolbox.feature, 'dataView')) {
						option['toolbox']['feature']['dataView']['title'] = this.$t('charts.title');
						option['toolbox']['feature']['dataView']['lang'] = [
							this.$t('charts.dataView'),
							this.$t('charts.closeView'),
							this.$t('charts.updateData')
						];
						option.toolbox.feature.dataView.optionToContent = this.setOptionToContentDatasource(
							allDates,
							pollutantValues,
							pollutantSymbol || ''
						);
					}
					if (Object.hasOwn(option.toolbox.feature, 'restore')) {
						option['toolbox']['feature']['restore']['title'] = this.$t('charts.restore');
					}
					if (Object.hasOwn(option.toolbox.feature, 'saveAsImage')) {
						option['toolbox']['feature']['saveAsImage']['title'] = this.$t('charts.saveAsImage');
					}
				}

				//option['series'][0].type = this.chartType;
				//console.log('show intervals init', this.form.showIntervals, option);
				if (showIntervals) {
					option['series'][0].markArea = {
						silent: true,
						data: [[{ yAxis: pollutant.thresholdminvalue }, { yAxis: pollutant.thresholdmaxvalue }]],
						itemStyle: { borderType: 'dashed', borderWidth: 2, color: '#014efa33', borderColor: '#014efa' }
					};
				} else {
					if (Object.hasOwn(option['series'][0], 'markArea')) delete option['series'][0].markArea;
				}
				option['textStyle'] = {
					fontFamily: 'Montserrat, sans-serif',
					fontSize: 14
				};
				option.grid = {
					//height: this.fullscreenDialog ? '100%' : isCardLocation ? '60%' : '64%',
					bottom: 70,
					left: '4%',
					width: '87%'
				};
				console.log('set historic option: ', this.displayLocation, option);
				this.advancedProps = { categories: allDates, values: pollutantValues, avg: option.series[0].data };
			} else {
				console.log('set historic option NO DATA: ', this.displayLocation, option, option.series);
				this.advancedProps = { categories: allDates, values: pollutantValues, avg: [] };
			}
			return option;
		},
		initChartAdvanced(selectedPollutants, pollutantObjList, elasticData) {
			let days = {};
			let values = {};
			let allDates = [];
			let pollutantData = {};
			let isCardLocation = this.displayLocation == 'card';
			if (!elasticData) elasticData = this.$store.getters.getAdvancedHistoricElasticData;

			selectedPollutants.forEach((pollutant) => {
				days[pollutant] = [];
				values[pollutant] = [];
				pollutantData[pollutant] = [];

				if (elasticData) {
					elasticData[pollutant].forEach((doc) => {
						let date = formatTimestamp(doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties, false, doc.timezone);
						if (!allDates.includes(date)) allDates.push(date);
						days[pollutant].push(date);
						values[pollutant].push(parseFloat(doc[pollutant].toFixed(2)));
						pollutantData[pollutant].push([doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], parseFloat(doc[pollutant].toFixed(2))]);
					});
				}
			});
			allDates.sort((a, b) => {
				return a.localeCompare(b);
			});
			//console.log('debug days and values 1', days, values, allDates);
			selectedPollutants.forEach((pollutant) => {
				if (allDates.length != days[pollutant].length) {
					allDates.forEach((date, i) => {
						if (!days[pollutant].includes(date)) {
							days[pollutant] = days[pollutant].toSpliced(i, 0, date);
							values[pollutant] = values[pollutant].toSpliced(i, 0, null);
						}
					});
				}
			});
			//console.log('debug days and values 2', days, values, allDates, this.pollutantObjList);

			let option = {
				toolbox: {
					feature: {
						restore: {
							title: this.$t('charts.restore'),
							show: 'true'
						},
						saveAsImage: {
							title: this.$t('charts.saveAsImage'),
							show: 'true'
						},
						dataView: {
							title: this.$t('charts.title'),
							lang: [this.$t('charts.dataView'), this.$t('charts.closeView'), this.$t('charts.updateData')],
							show: 'true',
							readOnly: 'true'
						}
					}
				},
				dataZoom: [
					{
						type: 'slider',
						xAxisIndex: 0,
						filterMode: 'true'
					},
					{
						type: 'slider',
						yAxisIndex: 0,
						filterMode: 'true'
					},
					{
						type: 'inside',
						xAxisIndex: 0,
						filterMode: 'none'
					},
					{
						type: 'inside',
						yAxisIndex: 0,
						filterMode: 'none'
					}
				],
				xAxis: {
					type: 'time', // Eje X de tipo tiempo
					boundaryGap: false,
					splitLine: {
						show: true
					}
				},
				yAxis: {
					type: 'value',
					splitLine: {
						show: true
					}
				},
				tooltip: {
					trigger: 'axis',
					formatter: (params) => {
						let dateLabel = formatTimestamp(params[0].axisValue, this.userProperties);
						let element = '<div class="pa-0"><span class="pb-2">' + dateLabel + '</span>';
						params.forEach((seriesParams) => {
							element +=
								'<div class="d-flex justify-space-between"><span class="pr-2">' +
								seriesParams.marker +
								' ' +
								seriesParams.seriesName +
								'</span><b>' +
								seriesParams.data[1] +
								'</b></div>';
						});

						element += '</div>';

						return element;
					}
				},
				legend: { top: isCardLocation ? '3%' : '1%' },
				series: []
			};
			option.toolbox.top = 10;
			option.toolbox.right = 30;
			selectedPollutants.forEach((pollutant) => {
				let pollutantObj = pollutantObjList[pollutant];
				let pollutantTitle =
					pollutantObj.symbol && pollutantObj.symbol.trim().length > 0
						? pollutantObj.name + ' (' + pollutantObj.symbol + ')'
						: pollutantObj.name;
				option.series.push({
					name: pollutantTitle,
					id: pollutant,
					type: 'line',
					smooth: false,
					emphasis: {
						focus: 'series'
					},
					markLine: {
						data: [{ type: 'average', name: 'Avg' }]
					},
					/* label: {
						formatter: pollutantObj.symbol ? '{@score}' + pollutantObj.symbol : '{@score}'
					}, */
					data: pollutantData[pollutant]
				});
			});
			option['textStyle'] = {
				fontFamily: 'Montserrat, sans-serif',
				fontSize: 14
			};
			option.toolbox.feature.dataView.optionToContent = this.setOptionToContentAdvanced(allDates, values);
			this.advancedProps = { categories: allDates, values: values };
			option.grid = {
				//height: isCardLocation ? '60%' : '75%',
				bottom: 70 /* isCardLocation ? '25%' : '15%' */,
				left: '4%',
				width: '90%'
			};
			//console.log('option log', option);
			return option;
		},
		initChartNotification(pollutant, rule, historicPollutantData, designOption = null, showIntervals = false) {
			const pollutantAcronym = pollutant.acronym;
			const pollutantSymbol = pollutant.symbol;
			const elasticacronym = pollutant.elasticacronym;
			let pollutantValues = [];
			let pollutantDays = [];
			console.info('debug historic data', historicPollutantData);
			if (historicPollutantData && typeof historicPollutantData.message !== 'string') {
				let documents = [];
				if (Array.isArray(historicPollutantData)) documents = historicPollutantData;
				else documents = historicPollutantData.message.map((hit) => hit._source);
				documents.forEach((item) => {
					pollutantDays.push(formatTimestamp(item[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties, false, item.timezone));
					pollutantValues.push(parseFloat(item[elasticacronym].toFixed(2)));
				});
			}

			let option = designOption ? designOption.config : this.parameterConfiguration;
			console.info('info: chart option', option, designOption, this.parameterConfiguration);

			let pollutantTitle =
				pollutantSymbol && pollutantSymbol.trim().length > 0 ? pollutantAcronym + ' (' + pollutantSymbol + ')' : pollutantAcronym;
			option['series'][0].name = pollutantAcronym;

			option['legend'] = { data: [pollutantTitle], top: '2%' };
			option['series'][0].name = pollutantTitle;
			if (Object.hasOwn(option, 'xAxis')) option['xAxis']['data'] = pollutantDays;

			let pieces = [];
			let parameterStatus = this.$store.getters.getParameterStatus.find((status) => status.pmparameterid === this.pollutant.pmparameterid);

			if (parameterStatus && designOption.applyranges === 1) {
				const statusArray = JSON.parse(parameterStatus.status);
				const colors = JSON.parse(parameterStatus.color);
				statusArray.sort((a, b) => {
					const minimumA = a.min.data;
					const minimumB = b.min.data;
					if (minimumA < minimumB) {
						return -1;
					}
					if (minimumA > minimumB) {
						return 1;
					}
					return 0; // Objects have the same minimum (statement should be unreachable)
				});
				statusArray.forEach((status, i) => {
					pieces.push({
						/* gte: status.minvalue,
							lte: status.maxvalue, */
						[status.min.op]: status.min.data,
						[status.max.op]: status.max.data,
						color: colors[i]
					});
				});

				option['visualMap'] = {
					top: '20%',
					right: '3%',
					pieces: pieces,
					outOfRange: {
						color: this.noStatusColor
					}
				};
			} else {
				option['series'][0].lineStyle = {
					color: this.noStatusColor
				};
				if (Object.hasOwn(option.series[0], 'areaStyle')) option.series[0].areaStyle = { color: this.noStatusColor };
			}

			option['series'][0].data = pollutantValues.map((value) => {
				const piece = pieces.find((p) => {
					let min = null;
					let max = null;
					if (p.gt) min = 'gt';
					if (p.gte) min = 'gte';
					if (p.lt) max = 'lt';
					if (p.lte) max = 'lte';
					const isEqual = p[min] === p[max];
					if (isEqual) {
						// Aplicar tolerancia de 0.1 si los límites son iguales
						return value >= p[min] && value - 0.1 <= p[max];
					} else {
						if (min && max) {
							return value >= p[min] && value <= p[max];
						} else if (min) {
							return value >= p[min];
						} else if (max) {
							return value <= p[max];
						}
					}
				});

				return {
					value: value,
					itemStyle: {
						color: piece ? piece.color : this.noStatusColor
					}
				};
			});
			option['series'][0].markPoint = {
				data: [
					{ type: 'max', name: 'Max' },
					{ type: 'min', name: 'Min' }
				],
				symbol: 'roundRect',
				symbolSize: [50, 20],
				itemStyle: {
					color: '#303e5a'
				}
				/* label: {
					formatter: pollutantSymbol ? '{@score}' + pollutantSymbol : '{@score}'
				} */
			};
			option.series[0].markLine = {
				label: { fontFamily: 'Montserrat, sans-serif', color: rule.color ? rule.color : 'inherit' },
				data: [{ yAxis: rule.filtervalue ? rule.filtervalue : 50, name: 'Limit' }]
			};
			if (rule.isaverage == 1) option['series'][0]['markLine'].data.push({ type: 'average', name: 'Avg' });

			if (option.toolbox && Object.hasOwn(option.toolbox, 'feature')) {
				option.toolbox.top = 15;
				option.toolbox.right = 30;
				if (Object.hasOwn(option.toolbox.feature, 'dataView')) {
					option['toolbox']['feature']['dataView']['title'] = this.$t('charts.title');
					option['toolbox']['feature']['dataView']['lang'] = [this.$t('charts.dataView'), 'X', this.$t('charts.updateData')];

					option.toolbox.feature.dataView.optionToContent = this.setOptionToContentHistoric();
				}

				option['toolbox']['feature']['restore']['title'] = this.$t('charts.restore');
				option['toolbox']['feature']['restore'].icon =
					'path://M15.6992 10.2163C15.8936 10.5941 15.7492 11.0608 15.3769 11.2608C15.2658 11.3219 15.138 11.3442 15.0213 11.3442C14.7435 11.3442 14.4769 11.1997 14.3435 10.933L13.8379 9.96072C13.399 10.9552 12.7045 11.8164 11.8044 12.4776C10.7265 13.2554 9.46533 13.6666 8.17078 13.6666C7.83186 13.6666 7.49295 13.6388 7.15403 13.5832C4.78161 13.1888 2.82034 11.4275 2.15917 9.09399C2.04805 8.69395 2.27585 8.26614 2.68144 8.14391C3.08703 8.03279 3.50373 8.26614 3.62041 8.67173C4.12045 10.4441 5.6039 11.7664 7.3985 12.0665C8.64304 12.2609 9.88759 11.972 10.9099 11.2275C11.5711 10.7386 12.06 10.1052 12.3989 9.37734L11.4155 9.72737C11.0155 9.86627 10.5876 9.6607 10.4432 9.25511C10.3098 8.85508 10.5099 8.41615 10.9099 8.2717L13.4657 7.35495C13.8212 7.22717 14.2213 7.38273 14.3991 7.72165L15.6936 10.1996V10.2107L15.6992 10.2163ZM2.72589 6.67156C2.80923 6.67156 2.89257 6.6549 2.98146 6.62712L5.53723 5.71037C5.93726 5.56592 6.13728 5.12699 6.00394 4.72696C5.85948 4.32693 5.42611 4.12135 5.03163 4.2547L4.03155 4.61584C4.90384 2.73235 6.93735 1.5767 9.05974 1.93228C10.8543 2.23231 12.3378 3.55464 12.8378 5.32701C12.9545 5.73815 13.3823 5.96595 13.7768 5.85483C14.1824 5.7326 14.4102 5.30478 14.2991 4.90475C13.6323 2.56567 11.6766 0.798857 9.29865 0.415492C6.45953 -0.0512134 3.75375 1.51003 2.61477 4.04357L2.10917 3.07127C1.91471 2.69346 1.448 2.549 1.07575 2.74902C0.703498 2.94348 0.559042 3.41574 0.753502 3.79355L2.04805 6.27153C2.1814 6.52711 2.44809 6.68268 2.72589 6.68268V6.67156Z';
				option['toolbox']['feature']['restore'].iconStyle = {
					color: '#303E5A',
					borderColor: '#303E5A',
					borderWidth: 0
				};
				option['toolbox']['feature']['saveAsImage']['title'] = this.$t('charts.saveAsImage');
				option['toolbox']['feature']['saveAsImage'].icon =
					'path://M11.4441 9.41675V9.78133C11.4441 10.3852 10.9464 10.8751 10.333 10.8751H1.44412C0.830693 10.8751 0.333008 10.3852 0.333008 9.78133V3.948C0.333008 3.34416 0.830693 2.85425 1.44412 2.85425H1.81449V7.59383C1.81449 8.59871 2.64551 9.41675 3.66634 9.41675H11.4441ZM13.6663 7.59383V1.7605C13.6663 1.15666 13.1687 0.666748 12.5552 0.666748H3.66634C3.05292 0.666748 2.55523 1.15666 2.55523 1.7605V7.59383C2.55523 8.19767 3.05292 8.68758 3.66634 8.68758H12.5552C13.1687 8.68758 13.6663 8.19767 13.6663 7.59383ZM6.25893 2.85425C6.25893 3.45809 5.76125 3.948 5.14782 3.948C4.5344 3.948 4.03671 3.45809 4.03671 2.85425C4.03671 2.25041 4.5344 1.7605 5.14782 1.7605C5.76125 1.7605 6.25893 2.25041 6.25893 2.85425ZM4.03671 6.1355L5.32143 4.87085C5.43023 4.76375 5.60616 4.76375 5.71495 4.87085L6.6293 5.77091L9.76588 2.68335C9.87467 2.57625 10.0506 2.57625 10.1594 2.68335L12.1849 4.67716V7.22925H4.03671V6.1355Z';
				option['toolbox']['feature']['saveAsImage'].iconStyle = {
					color: '#303E5A',
					borderColor: '#303E5A',
					borderWidth: 0
				};
			}

			//console.log('show intervals init', this.form.showIntervals, option);
			if (showIntervals) {
				option['series'][0].markArea = {
					silent: true,
					data: [[{ yAxis: this.pollutant.thresholdminvalue }, { yAxis: this.pollutant.thresholdmaxvalue }]],
					itemStyle: { borderType: 'dashed', borderWidth: 2, color: '#014efa33', borderColor: '#014efa' }
				};
			} else {
				if (Object.hasOwn(option['series'][0], 'markArea')) delete option['series'][0].markArea;
			}
			option['textStyle'] = {
				fontFamily: 'Montserrat, sans-serif',
				fontSize: 14
			};
			/* option.grid = {
				height: '60%',
				bottom: '23%',
				left: '4%',
				width: '87%'
			}; */
			//option && this.chart.setOption(option);
			console.log('set historic option: ', this.displayLocation, option, option.series[0].data.length);
			return option;
		},
		init3DGraph(pollutant, elasticData, designOption) {
			const pollutantAcronym = pollutant.acronym ?? this.$t('map.value');
			const pollutantSymbol = pollutant.symbol == '' || !pollutant.symbol ? '' : pollutant.symbol;
			const elasticacronym = pollutant.elasticacronym;
			let documents = [];
			let minLat = null;
			let maxLat = null;
			let allDates = [];
			let areasData = {};

			if (elasticData && typeof elasticData.message !== 'string') {
				if (Array.isArray(elasticData)) documents = elasticData;
				else documents = elasticData.message.map((hit) => hit._source);
			}
			let storedAreas = this.$store.getters.getAreas;
			let areas = [];
			areasData['NONE'] = {};
			let areaSeries = {
				NONE: {
					name: this.$t('map.none'),
					id: 'NONE',
					type: 'scatter3D',
					data: [],
					symbolSize: 10,
					itemStyle: {
						color: '#6f7480'
					}
				}
			};
			storedAreas.forEach((area) => {
				areas.push({ name: area.name, color: area.color, polygon: JSON.parse(area.areapoints), acronym: area.acronym });
				areasData[area.acronym] = {};
				areaSeries[area.acronym] = {
					name: area.name,
					id: area.acronym,
					type: 'scatter3D',
					data: [],
					symbolSize: 10,
					itemStyle: {
						color: area.color
					}
				};
			});

			documents.forEach((doc) => {
				let coordinates = doc['location'].coordinates;
				let time = formatTimestamp(doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties, false, doc['timezone']);
				if (!allDates.includes(time)) allDates.push(time);
				if (coordinates[1] < minLat || !minLat) minLat = coordinates[1];
				if (coordinates[1] > maxLat || !maxLat) maxLat = coordinates[1];

				let point = {
					time: time,
					timestamp: doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP],
					value: parseFloat(doc[elasticacronym].toFixed(2)),
					lat: coordinates[1],
					lon: coordinates[0]
				};
				let noArea = true;
				for (const area of areas) {
					if (isPointInPolygon(point.lat, point.lon, area.polygon)) {
						const areaName = area.name;
						areasData[area.acronym][point.time] = point.value;
						areaSeries[area.acronym].data.push({
							value: [point.timestamp, point.value, point.lat],
							original: {
								time: point.time,
								timestamp: point.timestamp,
								value: point.value,
								lat: point.lat,
								lon: point.lon,
								areaName
							}
						});
						noArea = false;
						break;
					}
				}
				if (noArea) {
					areasData['NONE'][point.time] = point.value;
					areaSeries['NONE'].data.push({
						value: [point.timestamp, point.value, point.lat],
						original: {
							time: point.time,
							timestamp: point.timestamp,
							value: point.value,
							lat: point.lat,
							lon: point.lon,
							areaName: this.$t('map.none')
						}
					});
				}
			});

			let option = designOption ? designOption.config : this.parameterConfiguration;
			if (option.toolbox && Object.hasOwn(option.toolbox, 'feature')) {
				option.toolbox.top = 15;
				option.toolbox.right = 30;
				if (Object.hasOwn(option.toolbox.feature, 'dataView')) {
					option['toolbox']['feature']['dataView']['title'] = this.$t('charts.title');
					option['toolbox']['feature']['dataView']['lang'] = [
						this.$t('charts.dataView'),
						this.$t('charts.closeView'),
						this.$t('charts.updateData')
					];

					option.toolbox.feature.dataView.optionToContent = this.setOptionToContentMobile(
						allDates,
						areasData,
						pollutantSymbol,
						pollutantAcronym
					);
				}

				option['toolbox']['feature']['restore']['title'] = this.$t('charts.restore');
				option['toolbox']['feature']['restore'].icon =
					'path://M15.6992 10.2163C15.8936 10.5941 15.7492 11.0608 15.3769 11.2608C15.2658 11.3219 15.138 11.3442 15.0213 11.3442C14.7435 11.3442 14.4769 11.1997 14.3435 10.933L13.8379 9.96072C13.399 10.9552 12.7045 11.8164 11.8044 12.4776C10.7265 13.2554 9.46533 13.6666 8.17078 13.6666C7.83186 13.6666 7.49295 13.6388 7.15403 13.5832C4.78161 13.1888 2.82034 11.4275 2.15917 9.09399C2.04805 8.69395 2.27585 8.26614 2.68144 8.14391C3.08703 8.03279 3.50373 8.26614 3.62041 8.67173C4.12045 10.4441 5.6039 11.7664 7.3985 12.0665C8.64304 12.2609 9.88759 11.972 10.9099 11.2275C11.5711 10.7386 12.06 10.1052 12.3989 9.37734L11.4155 9.72737C11.0155 9.86627 10.5876 9.6607 10.4432 9.25511C10.3098 8.85508 10.5099 8.41615 10.9099 8.2717L13.4657 7.35495C13.8212 7.22717 14.2213 7.38273 14.3991 7.72165L15.6936 10.1996V10.2107L15.6992 10.2163ZM2.72589 6.67156C2.80923 6.67156 2.89257 6.6549 2.98146 6.62712L5.53723 5.71037C5.93726 5.56592 6.13728 5.12699 6.00394 4.72696C5.85948 4.32693 5.42611 4.12135 5.03163 4.2547L4.03155 4.61584C4.90384 2.73235 6.93735 1.5767 9.05974 1.93228C10.8543 2.23231 12.3378 3.55464 12.8378 5.32701C12.9545 5.73815 13.3823 5.96595 13.7768 5.85483C14.1824 5.7326 14.4102 5.30478 14.2991 4.90475C13.6323 2.56567 11.6766 0.798857 9.29865 0.415492C6.45953 -0.0512134 3.75375 1.51003 2.61477 4.04357L2.10917 3.07127C1.91471 2.69346 1.448 2.549 1.07575 2.74902C0.703498 2.94348 0.559042 3.41574 0.753502 3.79355L2.04805 6.27153C2.1814 6.52711 2.44809 6.68268 2.72589 6.68268V6.67156Z';
				option['toolbox']['feature']['restore'].iconStyle = {
					color: '#303E5A',
					borderColor: '#303E5A',
					borderWidth: 0
				};
				option['toolbox']['feature']['saveAsImage']['title'] = this.$t('charts.saveAsImage');
				option['toolbox']['feature']['saveAsImage'].icon =
					'path://M11.4441 9.41675V9.78133C11.4441 10.3852 10.9464 10.8751 10.333 10.8751H1.44412C0.830693 10.8751 0.333008 10.3852 0.333008 9.78133V3.948C0.333008 3.34416 0.830693 2.85425 1.44412 2.85425H1.81449V7.59383C1.81449 8.59871 2.64551 9.41675 3.66634 9.41675H11.4441ZM13.6663 7.59383V1.7605C13.6663 1.15666 13.1687 0.666748 12.5552 0.666748H3.66634C3.05292 0.666748 2.55523 1.15666 2.55523 1.7605V7.59383C2.55523 8.19767 3.05292 8.68758 3.66634 8.68758H12.5552C13.1687 8.68758 13.6663 8.19767 13.6663 7.59383ZM6.25893 2.85425C6.25893 3.45809 5.76125 3.948 5.14782 3.948C4.5344 3.948 4.03671 3.45809 4.03671 2.85425C4.03671 2.25041 4.5344 1.7605 5.14782 1.7605C5.76125 1.7605 6.25893 2.25041 6.25893 2.85425ZM4.03671 6.1355L5.32143 4.87085C5.43023 4.76375 5.60616 4.76375 5.71495 4.87085L6.6293 5.77091L9.76588 2.68335C9.87467 2.57625 10.0506 2.57625 10.1594 2.68335L12.1849 4.67716V7.22925H4.03671V6.1355Z';
				option['toolbox']['feature']['saveAsImage'].iconStyle = {
					color: '#303E5A',
					borderColor: '#303E5A',
					borderWidth: 0
				};
			}
			option.tooltip.formatter = (params) => {
				const point = params.data.original;
				const formattedTime = formatTimestamp(point.timestamp, this.userProperties, false);

				return (
					'<b>' +
					this.$t('map.time') +
					': </b>' +
					formattedTime +
					'<br><b>' +
					pollutantAcronym +
					': </b>' +
					point.value +
					' ' +
					pollutantSymbol +
					'<br><b>' +
					this.$t('map.lat') +
					': </b>' +
					point.lat +
					'<br><b>' +
					this.$t('map.lon') +
					': </b>' +
					point.lon +
					'<br><b>' +
					this.$t('map.area') +
					': </b>' +
					point.areaName
				);
			};
			option.legend = {
				top: '2%',
				data: [this.$t('map.none')].concat(areas.map((area) => area.name))
			};
			option.xAxis3D = {
				type: 'time',
				name: this.$t('map.time'),
				axisLabel: {
					show: true,
					color: '#666',
					rotate: 45,
					formatter: function (value) {
						const date = new Date(value);
						const hours = date.getHours().toString().padStart(2, '0');
						const minutes = date.getMinutes().toString().padStart(2, '0');
						const time = `${hours}:${minutes}`;
						return time;
					}
				}
			};
			option.yAxis3D = {
				type: 'value',
				name: pollutantAcronym + (pollutantSymbol ? ' (' + pollutantSymbol + ')' : ''),
				min: 0
			};
			option.zAxis3D = {
				type: 'value',
				name: this.$t('map.lat'),
				min: minLat,
				max: maxLat
			};
			option['textStyle'] = {
				fontFamily: 'Montserrat, sans-serif',
				fontSize: 14
			};
			option.grid = {
				bottom: 120,
				left: '4%',
				height: '87%'
			};

			option.series = Object.values(areaSeries);
			this.mobileProps = { categories: allDates, values: areasData };
			return option;
		},
		initBarMobile(pollutant, elasticData, designOption, showIntervals = false) {
			const pollutantAcronym = pollutant.acronym ?? this.$t('map.value');
			const pollutantSymbol = pollutant.symbol == '' || !pollutant.symbol ? '' : pollutant.symbol;
			const elasticacronym = pollutant.elasticacronym;
			let documents = [];
			let allDates = [];
			let areasData = {};

			if (elasticData && typeof elasticData.message !== 'string') {
				if (Array.isArray(elasticData)) documents = elasticData;
				else documents = elasticData.message.map((hit) => hit._source);
			}
			let storedAreas = this.$store.getters.getAreas;
			let areas = [];
			areasData['NONE'] = {};
			let areaSeries = {
				NONE: {
					name: this.$t('map.none'),
					id: 'NONE',
					type: 'bar',
					stack: 'total',
					data: [],
					itemStyle: {
						color: '#6f7480'
					}
				}
			};

			storedAreas.forEach((area) => {
				areas.push({ name: area.name, color: area.color, polygon: JSON.parse(area.areapoints), acronym: area.acronym });
				areasData[area.acronym] = {};
				areaSeries[area.acronym] = {
					name: area.name,
					id: area.acronym,
					type: 'bar',
					stack: 'total',
					data: [],
					itemStyle: {
						color: area.color
					}
				};
			});

			documents.forEach((doc) => {
				let coordinates = doc['location'].coordinates;
				let time = formatTimestamp(doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties, false, doc['timezone']);
				if (!allDates.includes(time)) allDates.push(time);
				let point = {
					time: time,
					timestamp: doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP],
					value: parseFloat(doc[elasticacronym].toFixed(2)),
					lat: coordinates[1],
					lon: coordinates[0]
				};
				let noArea = true;
				for (const area of areas) {
					if (isPointInPolygon(point.lat, point.lon, area.polygon)) {
						areaSeries[area.acronym].data.push([point.time, point.value]);
						areasData[area.acronym][point.time] = point.value;
						noArea = false;
						break;
					}
				}
				if (noArea) {
					areaSeries['NONE'].data.push([point.time, point.value]);
					areasData['NONE'][point.time] = point.value;
				}
			});

			let option = designOption ? designOption.config : this.parameterConfiguration;
			if (option.toolbox && Object.hasOwn(option.toolbox, 'feature')) {
				option.toolbox.top = 15;
				option.toolbox.right = 30;
				if (Object.hasOwn(option.toolbox.feature, 'dataView')) {
					option['toolbox']['feature']['dataView']['title'] = this.$t('charts.title');
					option['toolbox']['feature']['dataView']['lang'] = [
						this.$t('charts.dataView'),
						this.$t('charts.closeView'),
						this.$t('charts.updateData')
					];

					option.toolbox.feature.dataView.optionToContent = this.setOptionToContentHistoric();
				}

				option['toolbox']['feature']['restore']['title'] = this.$t('charts.restore');
				option['toolbox']['feature']['restore'].icon =
					'path://M15.6992 10.2163C15.8936 10.5941 15.7492 11.0608 15.3769 11.2608C15.2658 11.3219 15.138 11.3442 15.0213 11.3442C14.7435 11.3442 14.4769 11.1997 14.3435 10.933L13.8379 9.96072C13.399 10.9552 12.7045 11.8164 11.8044 12.4776C10.7265 13.2554 9.46533 13.6666 8.17078 13.6666C7.83186 13.6666 7.49295 13.6388 7.15403 13.5832C4.78161 13.1888 2.82034 11.4275 2.15917 9.09399C2.04805 8.69395 2.27585 8.26614 2.68144 8.14391C3.08703 8.03279 3.50373 8.26614 3.62041 8.67173C4.12045 10.4441 5.6039 11.7664 7.3985 12.0665C8.64304 12.2609 9.88759 11.972 10.9099 11.2275C11.5711 10.7386 12.06 10.1052 12.3989 9.37734L11.4155 9.72737C11.0155 9.86627 10.5876 9.6607 10.4432 9.25511C10.3098 8.85508 10.5099 8.41615 10.9099 8.2717L13.4657 7.35495C13.8212 7.22717 14.2213 7.38273 14.3991 7.72165L15.6936 10.1996V10.2107L15.6992 10.2163ZM2.72589 6.67156C2.80923 6.67156 2.89257 6.6549 2.98146 6.62712L5.53723 5.71037C5.93726 5.56592 6.13728 5.12699 6.00394 4.72696C5.85948 4.32693 5.42611 4.12135 5.03163 4.2547L4.03155 4.61584C4.90384 2.73235 6.93735 1.5767 9.05974 1.93228C10.8543 2.23231 12.3378 3.55464 12.8378 5.32701C12.9545 5.73815 13.3823 5.96595 13.7768 5.85483C14.1824 5.7326 14.4102 5.30478 14.2991 4.90475C13.6323 2.56567 11.6766 0.798857 9.29865 0.415492C6.45953 -0.0512134 3.75375 1.51003 2.61477 4.04357L2.10917 3.07127C1.91471 2.69346 1.448 2.549 1.07575 2.74902C0.703498 2.94348 0.559042 3.41574 0.753502 3.79355L2.04805 6.27153C2.1814 6.52711 2.44809 6.68268 2.72589 6.68268V6.67156Z';
				option['toolbox']['feature']['restore'].iconStyle = {
					color: '#303E5A',
					borderColor: '#303E5A',
					borderWidth: 0
				};
				option['toolbox']['feature']['saveAsImage']['title'] = this.$t('charts.saveAsImage');
				option['toolbox']['feature']['saveAsImage'].icon =
					'path://M11.4441 9.41675V9.78133C11.4441 10.3852 10.9464 10.8751 10.333 10.8751H1.44412C0.830693 10.8751 0.333008 10.3852 0.333008 9.78133V3.948C0.333008 3.34416 0.830693 2.85425 1.44412 2.85425H1.81449V7.59383C1.81449 8.59871 2.64551 9.41675 3.66634 9.41675H11.4441ZM13.6663 7.59383V1.7605C13.6663 1.15666 13.1687 0.666748 12.5552 0.666748H3.66634C3.05292 0.666748 2.55523 1.15666 2.55523 1.7605V7.59383C2.55523 8.19767 3.05292 8.68758 3.66634 8.68758H12.5552C13.1687 8.68758 13.6663 8.19767 13.6663 7.59383ZM6.25893 2.85425C6.25893 3.45809 5.76125 3.948 5.14782 3.948C4.5344 3.948 4.03671 3.45809 4.03671 2.85425C4.03671 2.25041 4.5344 1.7605 5.14782 1.7605C5.76125 1.7605 6.25893 2.25041 6.25893 2.85425ZM4.03671 6.1355L5.32143 4.87085C5.43023 4.76375 5.60616 4.76375 5.71495 4.87085L6.6293 5.77091L9.76588 2.68335C9.87467 2.57625 10.0506 2.57625 10.1594 2.68335L12.1849 4.67716V7.22925H4.03671V6.1355Z';
				option['toolbox']['feature']['saveAsImage'].iconStyle = {
					color: '#303E5A',
					borderColor: '#303E5A',
					borderWidth: 0
				};
			}
			option.legend = {
				top: '2%',
				data: [this.$t('map.none')].concat(areas.map((area) => area.name))
			};
			option.xAxis.name = this.$t('map.time');
			option.xAxis.data = allDates;
			option.yAxis.name = pollutantAcronym + (pollutantSymbol ? ' (' + pollutantSymbol + ')' : '');
			option.series = Object.values(areaSeries);
			option.tooltip.formatter = (params) => {
				return params
					.map(
						(p) =>
							'<b>' +
							this.$t('map.area') +
							':</b> ' +
							p.seriesName +
							'<br><b>' +
							this.$t('map.time') +
							':</b> ' +
							p.name +
							'<br><b>' +
							pollutantAcronym +
							':</b> ' +
							p.value[1] +
							' ' +
							pollutantSymbol
					)
					.join('<br>');
			};
			console.log('show intervals initBarMobile', showIntervals);
			if (showIntervals) {
				option['series'][0].markArea = {
					silent: true,
					data: [[{ yAxis: pollutant.thresholdminvalue }, { yAxis: pollutant.thresholdmaxvalue }]],
					itemStyle: { borderType: 'dashed', borderWidth: 2, color: '#014efa33', borderColor: '#014efa' }
				};
			} else {
				if (Object.hasOwn(option['series'][0], 'markArea')) delete option['series'][0].markArea;
			}
			option.toolbox.feature.dataView.optionToContent = this.setOptionToContentMobile(allDates, areasData, pollutantSymbol, pollutantAcronym);
			this.mobileProps = { categories: allDates, values: areasData };
			console.log('initBarMobile option', option);
			return option;
		},
		initTimeMobile(pollutant, elasticData, designOption, showIntervals = false) {
			const pollutantAcronym = pollutant.acronym ?? this.$t('map.value');
			const pollutantSymbol = pollutant.symbol == '' || !pollutant.symbol ? '' : pollutant.symbol;
			const elasticacronym = pollutant.elasticacronym;
			let documents = [];
			let allDates = [];
			let areasData = {};
			if (elasticData && typeof elasticData.message !== 'string') {
				if (Array.isArray(elasticData)) documents = elasticData;
				else documents = elasticData.message.map((hit) => hit._source);
			}
			let storedAreas = this.$store.getters.getAreas;
			let areas = [];
			areasData['NONE'] = {};
			let areaSeries = {
				NONE: {
					name: this.$t('map.none'),
					id: 'NONE',
					type: 'line',
					color: '#6f7480',
					lineStyle: {
						color: '#6f7480'
					},
					itemStyle: {
						color: '#6f7480'
					},
					data: []
				}
			};
			storedAreas.forEach((area) => {
				areas.push({ name: area.name, color: area.color, polygon: JSON.parse(area.areapoints), acronym: area.acronym });
				areasData[area.acronym] = {};
				areaSeries[area.acronym] = {
					name: area.name,
					id: area.acronym,
					type: 'line',
					color: area.color,
					lineStyle: {
						color: area.color
					},
					itemStyle: {
						color: area.color
					},
					data: []
				};
			});

			documents.forEach((doc) => {
				let coordinates = doc['location'].coordinates;
				let time = formatTimestamp(doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties, false, doc['timezone']);
				if (!allDates.includes(time)) allDates.push(time);
				let point = {
					time: time,
					timestamp: doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP],
					value: parseFloat(doc[elasticacronym].toFixed(2)),
					lat: coordinates[1],
					lon: coordinates[0]
				};
				let noArea = true;
				for (const area of areas) {
					if (isPointInPolygon(point.lat, point.lon, area.polygon)) {
						areaSeries[area.acronym].data.push([point.time, point.value]);
						areasData[area.acronym][point.time] = point.value;
						noArea = false;
						break;
					}
				}
				if (noArea) {
					areaSeries['NONE'].data.push([point.time, point.value]);
					areasData['NONE'][point.time] = point.value;
				}
			});

			let option = designOption ? designOption.config : this.parameterConfiguration;
			if (option.toolbox && Object.hasOwn(option.toolbox, 'feature')) {
				option.toolbox.top = 15;
				option.toolbox.right = 30;
				if (Object.hasOwn(option.toolbox.feature, 'dataView')) {
					option['toolbox']['feature']['dataView']['title'] = this.$t('charts.title');
					option['toolbox']['feature']['dataView']['lang'] = [
						this.$t('charts.dataView'),
						this.$t('charts.closeView'),
						this.$t('charts.updateData')
					];

					option.toolbox.feature.dataView.optionToContent = this.setOptionToContentHistoric();
				}

				option['toolbox']['feature']['restore']['title'] = this.$t('charts.restore');
				option['toolbox']['feature']['restore'].icon =
					'path://M15.6992 10.2163C15.8936 10.5941 15.7492 11.0608 15.3769 11.2608C15.2658 11.3219 15.138 11.3442 15.0213 11.3442C14.7435 11.3442 14.4769 11.1997 14.3435 10.933L13.8379 9.96072C13.399 10.9552 12.7045 11.8164 11.8044 12.4776C10.7265 13.2554 9.46533 13.6666 8.17078 13.6666C7.83186 13.6666 7.49295 13.6388 7.15403 13.5832C4.78161 13.1888 2.82034 11.4275 2.15917 9.09399C2.04805 8.69395 2.27585 8.26614 2.68144 8.14391C3.08703 8.03279 3.50373 8.26614 3.62041 8.67173C4.12045 10.4441 5.6039 11.7664 7.3985 12.0665C8.64304 12.2609 9.88759 11.972 10.9099 11.2275C11.5711 10.7386 12.06 10.1052 12.3989 9.37734L11.4155 9.72737C11.0155 9.86627 10.5876 9.6607 10.4432 9.25511C10.3098 8.85508 10.5099 8.41615 10.9099 8.2717L13.4657 7.35495C13.8212 7.22717 14.2213 7.38273 14.3991 7.72165L15.6936 10.1996V10.2107L15.6992 10.2163ZM2.72589 6.67156C2.80923 6.67156 2.89257 6.6549 2.98146 6.62712L5.53723 5.71037C5.93726 5.56592 6.13728 5.12699 6.00394 4.72696C5.85948 4.32693 5.42611 4.12135 5.03163 4.2547L4.03155 4.61584C4.90384 2.73235 6.93735 1.5767 9.05974 1.93228C10.8543 2.23231 12.3378 3.55464 12.8378 5.32701C12.9545 5.73815 13.3823 5.96595 13.7768 5.85483C14.1824 5.7326 14.4102 5.30478 14.2991 4.90475C13.6323 2.56567 11.6766 0.798857 9.29865 0.415492C6.45953 -0.0512134 3.75375 1.51003 2.61477 4.04357L2.10917 3.07127C1.91471 2.69346 1.448 2.549 1.07575 2.74902C0.703498 2.94348 0.559042 3.41574 0.753502 3.79355L2.04805 6.27153C2.1814 6.52711 2.44809 6.68268 2.72589 6.68268V6.67156Z';
				option['toolbox']['feature']['restore'].iconStyle = {
					color: '#303E5A',
					borderColor: '#303E5A',
					borderWidth: 0
				};
				option['toolbox']['feature']['saveAsImage']['title'] = this.$t('charts.saveAsImage');
				option['toolbox']['feature']['saveAsImage'].icon =
					'path://M11.4441 9.41675V9.78133C11.4441 10.3852 10.9464 10.8751 10.333 10.8751H1.44412C0.830693 10.8751 0.333008 10.3852 0.333008 9.78133V3.948C0.333008 3.34416 0.830693 2.85425 1.44412 2.85425H1.81449V7.59383C1.81449 8.59871 2.64551 9.41675 3.66634 9.41675H11.4441ZM13.6663 7.59383V1.7605C13.6663 1.15666 13.1687 0.666748 12.5552 0.666748H3.66634C3.05292 0.666748 2.55523 1.15666 2.55523 1.7605V7.59383C2.55523 8.19767 3.05292 8.68758 3.66634 8.68758H12.5552C13.1687 8.68758 13.6663 8.19767 13.6663 7.59383ZM6.25893 2.85425C6.25893 3.45809 5.76125 3.948 5.14782 3.948C4.5344 3.948 4.03671 3.45809 4.03671 2.85425C4.03671 2.25041 4.5344 1.7605 5.14782 1.7605C5.76125 1.7605 6.25893 2.25041 6.25893 2.85425ZM4.03671 6.1355L5.32143 4.87085C5.43023 4.76375 5.60616 4.76375 5.71495 4.87085L6.6293 5.77091L9.76588 2.68335C9.87467 2.57625 10.0506 2.57625 10.1594 2.68335L12.1849 4.67716V7.22925H4.03671V6.1355Z';
				option['toolbox']['feature']['saveAsImage'].iconStyle = {
					color: '#303E5A',
					borderColor: '#303E5A',
					borderWidth: 0
				};
			}
			option.legend = {
				top: '2%',
				data: [this.$t('map.none')].concat(areas.map((area) => area.name))
			};
			option.xAxis.name = this.$t('map.time');
			option.xAxis.data = allDates;
			option.yAxis.name = pollutantAcronym + (pollutantSymbol ? ' (' + pollutantSymbol + ')' : '');
			option.series = Object.values(areaSeries);
			option.tooltip.formatter = (params) => {
				return params
					.map(
						(p) =>
							'<b>' +
							this.$t('map.area') +
							':</b> ' +
							p.seriesName +
							'<br><b>' +
							this.$t('map.time') +
							':</b> ' +
							formatTimestamp(p.data[0], this.userProperties, false) +
							'<br><b>' +
							pollutantAcronym +
							':</b> ' +
							p.data[1] +
							' ' +
							pollutantSymbol
					)
					.join('<br>');
			};
			console.log('show intervals initTimeMobile', showIntervals);
			if (showIntervals) {
				option['series'][0].markArea = {
					silent: true,
					data: [[{ yAxis: pollutant.thresholdminvalue }, { yAxis: pollutant.thresholdmaxvalue }]],
					itemStyle: { borderType: 'dashed', borderWidth: 2, color: '#014efa33', borderColor: '#014efa' }
				};
			} else {
				if (Object.hasOwn(option['series'][0], 'markArea')) delete option['series'][0].markArea;
			}
			option.toolbox.feature.dataView.optionToContent = this.setOptionToContentMobile(allDates, areasData, pollutantSymbol, pollutantAcronym);
			this.mobileProps = { categories: allDates, values: areasData };
			console.log('initBarMobile option', option);
			return option;
		},
		getStatusInRange(data, parameterStatus) {
			let activeStatus = getStatusProperties(parameterStatus, data.value);
			return activeStatus ? activeStatus.name : '-';
		},
		setOptionToContent(visualization, pollutant) {
			let optionToContent = null;
			if (
				visualization == constants.VISUALIZATIONS.TIME_SERIES ||
				visualization == constants.VISUALIZATIONS.HISTOGRAM ||
				visualization == constants.VISUALIZATIONS.AREA_CHART
			) {
				optionToContent = (opt) => {
					try {
						const parameterStatus = pollutant
							? this.$store.getters.getParameterStatus.find((status) => status.pmparameterid === pollutant.pmparameterid)
							: false;
						let seriesData = opt.series[0].data;
						let seriesName = opt.series[0].name;
						let categories = Array.isArray(opt.xAxis) ? opt.xAxis[0].data : opt.xAxis.data;
						let ws_data = [];
						let table = '';
						if (!parameterStatus) {
							ws_data = [...categories.map((category, index) => [category, seriesData[index].value])];
							table =
								'<table style="width:100%;text-align:start;" class="data-view-table mb-4 cell-border hover stripe nowrap full-width dataTable no-footer">' +
								'<thead class=""><tr>' +
								'<th>' +
								this.$t('rasterTools.date') +
								'</th>' +
								'<th>' +
								seriesName +
								'</th>' +
								'</tr></thead><tbody>';
							let odd = false;

							ws_data.forEach((item) => {
								let rowClass = odd ? 'odd' : 'even';
								odd = !odd;
								table += '<tr class="' + rowClass + '">' + '<td>' + item[0] + '</td>' + '<td>' + item[1] + '</td>' + '</tr>';
							});
						} else {
							ws_data = [
								...categories.map((category, index) => [
									category,
									seriesData[index].value,
									this.getStatusInRange(seriesData[index], parameterStatus),
									seriesData[index].itemStyle.color
								])
							];
							table =
								'<table style="width:100%;text-align:start;" class="data-view-table mb-4 cell-border hover stripe nowrap full-width dataTable no-footer">' +
								'<thead class=""><tr>' +
								'<th>' +
								this.$t('rasterTools.date') +
								'</th>' +
								'<th>' +
								seriesName +
								'</th>' +
								'<th>' +
								this.$t('form.pmstatus') +
								'</th>' +
								'<th>' +
								this.$t('pmstatus.color') +
								'</th>' +
								'</tr></thead><tbody>';
							let odd = false;
							ws_data.forEach((item) => {
								let rowClass = odd ? 'odd' : 'even';
								odd = !odd;
								table +=
									'<tr class="' +
									rowClass +
									'">' +
									'<td>' +
									item[0] +
									'</td>' +
									'<td>' +
									(item[1] !== null ? item[1] : '-') +
									'</td>' +
									'<td>' +
									item[2] +
									'</td>' +
									'<td style="color: ' +
									item[3] +
									'">' +
									item[3] +
									'</td>' +
									'</tr>';
							});
						}

						table += '</tbody></table>';
						return table;
					} catch (error) {
						console.error('ERROR: optionToContent', error);
						return;
					}
				};
			} else if (visualization == constants.VISUALIZATIONS.FREQUENCY_HISTOGRAM) {
				optionToContent = (opt) => {
					console.info('debug freq opt', opt, opt.xAxis);
					try {
						let seriesData = opt.series[0].data;
						let categories = Array.isArray(opt.xAxis) ? opt.xAxis[0].data : opt.xAxis.data;
						let headerRow = [this.$t('panels.notifications.interval'), this.$t('map.items')];
						let ws_data = [...categories.map((category, index) => [category, seriesData[index]])];
						let table =
							'<table style="width:100%;text-align:start;" class="data-view-table mb-4 cell-border hover stripe nowrap full-width dataTable no-footer">' +
							'<thead class=""><tr>' +
							'<th>' +
							headerRow[0] +
							'</th>' +
							'<th>' +
							headerRow[1] +
							'</th>' +
							'</tr></thead><tbody>';
						let odd = false;
						ws_data.forEach((item) => {
							let rowClass = odd ? 'odd' : 'even';
							odd = !odd;
							table += '<tr class="' + rowClass + '">' + '<td>' + item[0] + '</td>' + '<td>' + item[1] + '</td>' + '</tr>';
						});

						table += '</tbody></table>';
						return table;
					} catch (error) {
						console.error('ERROR: optionToContent', error);
						return;
					}
				};
			} else if (visualization == constants.VISUALIZATIONS.FREQUENCY_PIE) {
				optionToContent = (opt) => {
					try {
						let seriesData = opt.series[0].data;
						let categories = seriesData.map((item) => item.name);
						let headerRow = [this.$t('panels.notifications.interval'), this.$t('map.items')];
						let ws_data = [...categories.map((category, index) => [category, seriesData[index].value])];
						let table =
							'<table style="width:100%;text-align:start;" class="data-view-table mb-4 cell-border hover stripe nowrap full-width dataTable no-footer">' +
							'<thead class=""><tr>' +
							'<th>' +
							headerRow[0] +
							'</th>' +
							'<th>' +
							headerRow[1] +
							'</th>' +
							'</tr></thead><tbody>';
						let odd = false;
						ws_data.forEach((item) => {
							let rowClass = odd ? 'odd' : 'even';
							odd = !odd;
							table += '<tr class="' + rowClass + '">' + '<td>' + item[0] + '</td>' + '<td>' + item[1] + '</td>' + '</tr>';
						});

						table += '</tbody></table>';
						return table;
					} catch (error) {
						console.error('ERROR: optionToContent', error);
						return;
					}
				};
			}
			return optionToContent;
		},
		setOptionToContentDatasource(allDates, pollutantValues, unit = '') {
			return (opt) => {
				const optionSeries = opt.series.filter((serie) => serie.type !== 'pie');
				let categories = [];
				categories = allDates;
				let headerRow = [this.$t('rasterTools.date')];
				optionSeries.forEach((serie) => headerRow.push(serie.name));
				let ws_data = [];
				if (optionSeries.length > 1) {
					ws_data = [
						...categories.map((category, index) => {
							let row = [category];
							optionSeries.forEach((serie) => {
								if (serie.id == 'avg') {
									row.push(serie.data[index][1]);
								} else {
									row.push(pollutantValues[serie.name][index]);
								}
							});
							return row;
						})
					];
				} else {
					ws_data = [
						...categories.map((category, index) => {
							let row = [category];
							optionSeries.forEach((serie) => row.push(pollutantValues[serie.name][index]));
							return row;
						})
					];
				}
				let table =
					'<table style="width:100%;text-align:start;" class="data-view-table mb-4 cell-border hover stripe nowrap full-width dataTable no-footer">' +
					'<thead class=""><tr>';
				headerRow.forEach((item) => (table += '<th>' + item + '</th>'));
				table += '</tr></thead><tbody>';
				let odd = false;
				ws_data.forEach((row) => {
					let rowClass = odd ? 'odd' : 'even';
					odd = !odd;
					table += '<tr class="' + rowClass + '">';
					row.forEach((item, i) => (table += '<td>' + (i == 0 ? item : item && item >= 0 ? item + ' ' + unit : '-') + '</td>'));
					table += '</tr>';
				});

				table += '</tbody></table>';
				return table;
			};
		},
		setOptionToContentAdvanced(allDates, pollutantValues, unit = '') {
			return (opt) => {
				const optionSeries = opt.series.filter((serie) => serie.type !== 'pie');
				let categories = [];
				categories = allDates;
				let headerRow = [this.$t('rasterTools.date')];
				optionSeries.forEach((serie) => headerRow.push(serie.name));
				let ws_data = [
					...categories.map((category, index) => {
						let row = [category];
						optionSeries.forEach((serie) => row.push(pollutantValues[serie.id][index]));
						return row;
					})
				];
				let table =
					'<table style="width:100%;text-align:start;" class="data-view-table mb-4 cell-border hover stripe nowrap full-width dataTable no-footer">' +
					'<thead class=""><tr>';
				headerRow.forEach((item) => (table += '<th>' + item + '</th>'));
				table += '</tr></thead><tbody>';

				let odd = false;
				ws_data.forEach((row) => {
					let rowClass = odd ? 'odd' : 'even';
					odd = !odd;
					table += '<tr class="' + rowClass + '">';
					row.forEach((item, i) => (table += '<td>' + (i == 0 ? item : item && item >= 0 ? item + ' ' + unit : '-') + '</td>'));
					table += '</tr>';
				});

				table += '</tbody></table>';
				return table;
			};
		},
		setOptionToContentHistoric() {
			return (opt) => {
				const parameterStatus = this.pollutant
					? this.$store.getters.getParameterStatus.find((status) => status.pmparameterid === this.pollutant.pmparameterid)
					: false;
				let seriesData = opt.series[0].data;
				let seriesName = opt.series[0].name;
				let categories = Array.isArray(opt.xAxis) ? opt.xAxis[0].data : opt.xAxis.data;
				let ws_data = [];
				let table = '';
				if (!parameterStatus) {
					ws_data = [...categories.map((category, index) => [category, seriesData[index].value])];
					table =
						'<table style="width:100%;text-align:start;" class="data-view-table mb-4 cell-border hover stripe nowrap full-width dataTable no-footer">' +
						'<thead class=""><tr>' +
						'<th>' +
						this.$t('rasterTools.date') +
						'</th>' +
						'<th>' +
						seriesName +
						'</th>' +
						'</tr></thead><tbody>';
					let odd = false;
					ws_data.forEach((item) => {
						let rowClass = odd ? 'odd' : 'even';
						odd = !odd;
						table += '<tr class="' + rowClass + '">' + '<td>' + item[0] + '</td>' + '<td>' + item[1] + '</td>' + '</tr>';
					});
				} else {
					ws_data = [
						...categories.map((category, index) => [
							category,
							seriesData[index].value,
							this.getStatusInRange(seriesData[index], parameterStatus),
							seriesData[index].itemStyle.color
						])
					];
					table =
						'<table style="width:100%;text-align:start;" class="data-view-table mb-4 cell-border hover stripe nowrap full-width dataTable no-footer">' +
						'<thead class=""><tr>' +
						'<th>' +
						this.$t('rasterTools.date') +
						'</th>' +
						'<th>' +
						seriesName +
						'</th>' +
						'<th>' +
						this.$t('form.pmstatus') +
						'</th>' +
						'<th>' +
						this.$t('pmstatus.color') +
						'</th>' +
						'</tr></thead><tbody>';
					let odd = false;
					ws_data.forEach((item) => {
						let rowClass = odd ? 'odd' : 'even';
						odd = !odd;
						table +=
							'<tr class="' +
							rowClass +
							'">' +
							'<td>' +
							item[0] +
							'</td>' +
							'<td>' +
							(item[1] !== null ? item[1] : '-') +
							'</td>' +
							'<td>' +
							item[2] +
							'</td>' +
							'<td style="color: ' +
							item[3] +
							'">' +
							item[3] +
							'</td>' +
							'</tr>';
					});
				}

				table += '</tbody></table>';
				return table;
			};
		},
		setOptionToContentMobile(allDates, areasValues, unit = '', pollutantAcronym = '') {
			return (opt) => {
				const optionSeries = opt.series.filter((serie) => serie.type !== 'pie');
				console.log('setOptionToContentMobile', optionSeries, allDates, areasValues);
				let categories = [];
				categories = allDates;
				let headerRow = [this.$t('rasterTools.date'), this.$t('map.area'), pollutantAcronym];
				//optionSeries.forEach((serie) => headerRow.push(serie.name));
				let ws_data = [
					...categories.map((category) => {
						let row = [category];
						optionSeries.forEach((serie) => {
							if (areasValues[serie.id][category]) {
								row.push(serie.name);
								row.push(areasValues[serie.id][category]);
							}
						});
						return row;
					})
				];
				let table =
					'<table style="width:100%;text-align:start;" class="data-view-table mb-4 cell-border hover stripe nowrap full-width dataTable no-footer">' +
					'<thead class=""><tr>';
				headerRow.forEach((item) => (table += '<th>' + item + '</th>'));
				table += '</tr></thead><tbody>';

				let odd = false;
				ws_data.forEach((row) => {
					let rowClass = odd ? 'odd' : 'even';
					odd = !odd;
					table += '<tr class="' + rowClass + '">';
					row.forEach(
						(item, i) =>
							(table += '<td>' + (i == 0 || typeof item == 'string' ? item : item && item >= 0 ? item + ' ' + unit : '-') + '</td>')
					);
					table += '</tr>';
				});

				table += '</tbody></table>';
				return table;
			};
		},
		groupByDevice(dataset) {
			const groupedDevices = dataset.reduce((r, { device, ...rest }) => {
				if (!r[device]) {
					r[device] = { device, group: [rest] };
				} else {
					r[device].group.push(rest);
				}
				return r;
			}, {});
			return Object.values(groupedDevices).sort((a, b) => (a.device > b.device ? 1 : b.device > a.device ? -1 : 0));
		}
	}
};
