import { Style, Fill, Circle, Stroke } from 'ol/style';
//import store from '@/store/store';
import Text from 'ol/style/Text';

const notificationStylePointFunction = function (feature) {
	var notificationsCount = feature['values_']['notifications'];
	var notificationColor = feature['values_']['severitycolor'];
	let style = null;

	if (notificationsCount > 0) {
		var text = new Text({
			text: notificationsCount.toString(),
			font: 'bold 12px Montserrat, sans-serif',
			fill: new Fill({ color: 'white' }),
			padding: [2, 2, 2, 2],
			offsetY: -15,
			offsetX: 15
		});

		var pointStyle = new Style({
			image: new Circle({
				radius: 10,
				fill: new Fill({
					color: notificationColor ?? '#0C1A36'
				}),
				stroke: new Stroke({
					color: notificationColor ?? '#0C1A36',
					width: 2
				}),
				displacement: [15, 15]
			}),
			text: text
		});
		style = pointStyle;
	}
	return style;
};

export default notificationStylePointFunction;
