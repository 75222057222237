<template>
	<div class="d-flex flex-column max-height-100">
		<!-- Cabecera -->
		<!--<map-panel-header :height="headerHeight" />-->

		<!-- Info y mapa -->
		<div id="div-info-mapa" class="d-flex flex-grow-1 fill-height" :style="{ height: `calc(100vh - ${headerHeight}px)` }">
			<div id="map-panel-info" :style="{ width: collapsed ? '42px' : 'max(360px, 20vw)' }" style="z-index: 1">
				<map-panel-info :selectedtab="selectedtab" />
			</div>
			<div id="div-maps" class="flex-grow-1 d-flex">
				<div id="div-map-container" class="flex-grow-1">
					<map-panel-map />
				</div>

				<div v-show="isActiveTabCompareMaps" id="div-map-compare-container" class="flex-grow-1">
					<map-compare-panel-map />
				</div>
			</div>
		</div>
		<!-- <configurations-panel /> -->
	</div>
</template>
<script>
//import MapPanelHeader from '@/components/map/sections/header/MapPanelHeader';
import MapPanelInfo from '@/components/map/sections/info/MapPanelInfo';
import MapPanelMap from '@/components/map/sections/map/MapPanelMap';
import MapComparePanelMap from '@/components/map/sections/map/MapComparePanelMap';
import { DEVICES_PANEL_ID, COMPAREMAPS_PANEL_ID } from '@/lib/variables/panels';
import ol2map from '@/components/map/sections/map/subcomponents/ol2map';

/** Mixins */
import BaseLayersMixin from '@/mixins/BaseLayersMixin';
import AreaMixin from '@/mixins/AreaMixin';
import SpatialTablesMixin from '@/mixins/SpatialTablesMixin';
import QueriesSQLMixin from '@/mixins/QueriesSQLMixin';
import ViewsMixin from '@/mixins/ViewsMixin';
import ReloadStoreMixin from '@/mixins/ReloadStoreMixin';
import MapPanelMixin from '@/mixins/MapPanelMixin';

/** Layers */
import { NOTIFICATIONS_LAYER, LAYER_NAMES_KPIS_AREA, LAYER_NAMES_KPIS_DATASOURCETYPE } from '@/lib/constants/layers';

import { search, isSuperAdmin } from '@/api/common';
import { KPI_EXTRACTOR_AREA, KPI_EXTRACTOR_DATASOURCETYPE } from '@/lib/utils/kpis';

/** 3rd-party */
//import debounce from 'debounce';

export default {
	name: 'MapPanel',

	components: {
		//MapPanelHeader,
		MapPanelInfo,
		MapPanelMap,
		MapComparePanelMap
	},

	mixins: [BaseLayersMixin, AreaMixin, SpatialTablesMixin, QueriesSQLMixin, ViewsMixin, ReloadStoreMixin, MapPanelMixin],

	props: {
		selectedtab: {
			type: String,
			required: false,
			default: DEVICES_PANEL_ID
		}
	},
	data() {
		return {
			stationsList: [],
			notificationsList: [],
			//refresh: debounce(this.updateLayers, 500)
			refresh: this.updateLayers,
			isSuperAdmin: false,
			mapPanelMapKey: 0,
			MapComparePanelMapKey: 0,
			notificationsLayerId: null,
			storedLayers: {},
			storedDevices: {},
			storedAqiPoints: {}
		};
	},
	computed: {
		isActiveTabCompareMaps() {
			return this.$store.getters.activePanel === COMPAREMAPS_PANEL_ID;
		},
		layers: {
			get() {
				return this.$store.getters.layers;
			},
			set(value) {
				this.$store.commit('setLayers', value);
			}
		},

		mapLoadedLayersFinish() {
			return this.$store.state.map.mapLoadedLayersFinish;
		},
		notificationLayers() {
			return this.$store.getters.notificationLayers;
		},
		headerHeight() {
			return this.$store.state.global.appHeaderMapHeightInPx;
		},
		mapLoaded() {
			return this.$store.state.map.mapLoaded;
		},
		hasDevices() {
			return this.$store.getters.getDevices ? Object.keys(this.$store.getters.getDevices).length > 0 : false;
		},
		hasAqiPoints() {
			return this.$store.getters.getAqiPoints ? Object.keys(this.$store.getters.getAqiPoints).length > 0 : false;
		},
		hasLayers() {
			return this.$store.getters.getHasLayers;
		},
		collapsed() {
			return this.$store.getters.getCollapsedInfo;
		}
	},
	watch: {
		'$store.state.mappanel.bbox': {
			handler() {
				if (this.$store.getters.getPermissions['APP_NOTIFICATIONS']) {
					this.updateKpis_byArea(NOTIFICATIONS_LAYER);
					this.updateKpis_byDatasourcetype(NOTIFICATIONS_LAYER);
					this.updateGlobalCounts_byArea();
					this.updateGlobalCounts_byDatasourcetype();
				}

				this.updateFeatures(this.notificationsLayerId);
			}
		},
		'$store.state.data.notifications.offset': {
			handler() {
				this.addMoreFeatures(NOTIFICATIONS_LAYER);
			}
		}
	},
	beforeCreate() {},
	created() {
		this.notificationsLayerId = NOTIFICATIONS_LAYER + '_' + this.userProperties.acronym.toLowerCase();
		this.$puiEvents.$on('map:updated', this.refresh);
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
	},
	mounted() {
		this.$store.commit('setMapLoadedLayersFinish', false);
		if (document.getElementsByClassName('pui-container__body')) {
			let puiContainerBody = document.getElementsByClassName('pui-container__body')[0];
			puiContainerBody.classList.add('map-view');
		}
		if (document.getElementsByClassName('pui-container--withfooter')[0]) {
			const puiContainer = document.getElementsByClassName('pui-container--withfooter')[0];
			puiContainer.classList.remove('pui-container--withfooter');
		}

		const puiContainerHeader = document.getElementsByClassName('pui-containerHeader')[0];
		//this.$puiEvents.$emit('setContainerHeaderVisibility', false);
		//this.$store.commit('setContainerHeaderVisibility', false);
		//	--> esto solo funciona si el appContainerHeader va separado del pui-containerHeader
		puiContainerHeader.style.display = 'none';
		this.$store.commit('resetLoadingProcess');
		//this.updateLayers();
		//this.updateFeatures(this.notificationsLayerId);
		this.$nextTick(async () => {
			//this.addBaseLayerOSM();
			await this.reloadCriticalStore();
			await this.initializeMapPanel();
			this.reloadSecondaryStore();
		});
	},
	updated() {},
	beforeDestroy() {
		this.$store.commit('setRasterDateSelected', false);
		this.$store.commit('setRasterSelectedImage', []);
		if (document.getElementsByClassName('pui-containerHeader')[0]) {
			document.getElementsByClassName('pui-containerHeader')[0].style.display = '';
		}
	},
	destroyed() {
		this.$puiEvents.$off('map:updated', this.refresh);
	},

	methods: {
		async initializeMapPanel() {
			await Promise.all([this.setupMap(), this.updateLayers(), this.updateFeatures(this.notificationsLayerId)]);
			this.$store.commit('setMapLoadedLayersFinish', true);
		},

		/**
		 * Setup map & layers
		 */
		async setupMap() {
			// 1- Create Base Layer using BaseLayersMixin
			this.addBaseLayerOSM();

			// 2- Add Area Notification Layer using AreaNotificationMixin
			this.addAreaNotificationLayer();

			if (this.hasLayers) {
				// 3 - Get Layers by organization and create spatial layers
				this.getSpatialTablesAndCreateVectorLayers().then(() => {
					this.updateFeatures(this.notificationsLayerId);
				});
			}

			if (this.$store.getters.getPermissions['APP_EXTERNAL_LAYERS']) {
				// 4 - Get External Layers by organization and create spatial layers
				this.getExternalLayersAndCreateVectorLayers();
			}

			if (this.hasDevices) {
				// 5 - Get Devices by organization and create layers
				this.getDevicesAndCreateVectorLayers();
			}

			if (this.$store.getters.getPermissions['APP_AQI'] && this.hasAqiPoints) {
				// 6 - Get AQI Points by organization and create layers
				this.getAqiPointsAndCreateVectorLayers();
			}

			if (this.$store.getters.getPermissions['APP_SATELLITE']) {
				this.getRasterLayers();
			}
			if (this.$store.getters.getPermissions['APP_GEOJSON_LAYERS']) {
				this.getGeoJsonLayers();
			}

			//this.getParameterStatus();
		},

		getViewById(identifier) {
			return this.views[identifier];
		},

		formatView(identifier, view) {
			return `"${view}" AS ${this.preffixes[identifier]}`;
		},

		async updateLayers() {
			let filterDevice = null;
			if (!this.isSuperAdmin) {
				filterDevice = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid },
						{ field: 'disabled', op: 'eq', data: 0 },
						{ field: 'datasourcetypedisabled', op: 'eq', data: 0 }
					]
				};
			} else {
				filterDevice = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'disabled', op: 'eq', data: 0 },
						{ field: 'datasourcetypedisabled', op: 'eq', data: 0 }
					]
				};
			}

			let bodyDevices = {
				model: 'pmdatasourceinstance',
				filter: filterDevice,
				rows: 1000
			};

			let response = await search(bodyDevices);
			let devices = response.data.data;
			Object.keys(this.layers).forEach(idLayer => ol2map.refreshLayer(idLayer, devices));
			if (this.$store.getters.getPermissions['APP_NOTIFICATIONS']) {
				let filterNotification = {};
				if (!this.isSuperAdmin) {
					filterNotification = {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }]
					};
				}
				let body = {
					model: 'pmnotifications',
					filter: filterNotification,
					rows: 10000
				};
				search(body).then(result => {
					if (Object.hasOwn(result, 'data')) {
						let sortedNotifications = result.data.data.sort((a, b) => parseFloat(b.priority) - parseFloat(a.priority));
						this.$store.commit('setNotifications', sortedNotifications);
					}
				});
			}
		},

		/**
		 * Features
		 */
		async updateFeatures(identifier) {
			const promise = this.getFeatures(identifier);
			if (this.$store.getters.getPermissions['APP_NOTIFICATIONS']) {
				let filterNotification = {};
				if (!this.isSuperAdmin) {
					filterNotification = {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }]
					};
				}
				let body = {
					model: 'pmnotifications',
					filter: filterNotification,
					rows: 10000
				};
				search(body).then(({ data }) => {
					if (data && Object.hasOwn(data, 'data')) this.$store.commit('setNotifications', data.data);
					else this.$store.commit('setNotifications', null);
				});
			}
			promise.then(features => {
				//this.$store.dispatch('setFeatures', { identifier, features });
				this.$store.commit('setNotificationsFeatures', features);
			});
			//this.$store.dispatch('setFeatures', { identifier, features });
			this.$store.state.data[NOTIFICATIONS_LAYER].offset = 0;
		},

		async getFeatures(identifier) {
			// TODO: Use limit and offset
			const view = this.formatView(NOTIFICATIONS_LAYER, this.getViewById(NOTIFICATIONS_LAYER));
			const bbox = this.bbox;
			const { limit, offset } = this.$store.state.data[NOTIFICATIONS_LAYER];
			try {
				const rows = await this.getFeaturesByBbox(view, bbox, limit, offset /* , datasourcetypeid */);
				return rows.map(row => ({ properties: { ...row } }));
			} catch (error) {
				console.error(identifier, error);
				return [];
			}
		},

		async addMoreFeatures(identifier) {
			this.notificationLayers.forEach(layer => {
				if (!this.layers[layer.identifier].active) {
					return;
				}
			});
			const promise = this.getFeatures(identifier);
			promise.then(newFeatures => {
				const storedFeatures = this.$store.state.data[identifier].viewportFeatures;
				const features = [...storedFeatures, ...newFeatures];
				this.$store.dispatch('setFeatures', { identifier, features });
			});
		},

		/**
		 * Counts
		 */
		updateGlobalCounts_byArea() {
			LAYER_NAMES_KPIS_AREA.forEach(this.updateGlobalCountsById_Area);
		},

		updateGlobalCounts_byDatasourcetype() {
			LAYER_NAMES_KPIS_DATASOURCETYPE.forEach(this.updateGlobalCountsById_Datasourcetype);
		},

		async updateGlobalCountsById_Area(identifier) {
			const view = this.formatView(identifier, this.getViewById(identifier));

			try {
				const promise = this.getCountByLayer(view);
				promise.then(response => {
					const totals = response.length > 0 ? response[0].count : 100;
					this.$store.commit('setTotals_byArea', { identifier, totals });
				});
			} catch (error) {
				console.error(error);
				this.$store.commit('setTotals_byArea', { identifier, totals: 0 });
			}
		},

		async updateGlobalCountsById_Datasourcetype(identifier) {
			const view = this.formatView(identifier, this.getViewById(identifier));

			try {
				const promise = this.getCountByLayer(view);
				promise.then(response => {
					const totals = response.length > 0 ? response[0].count : 100;

					this.$store.commit('setTotals_byDatasourcetype', { identifier, totals });
				});
			} catch (error) {
				console.error(error);
				this.$store.commit('setTotals_byDatasourcetype', { identifier, totals: 0 });
			}
		},

		getKpisCount_byArea(identifier, bbox) {
			switch (identifier) {
				case NOTIFICATIONS_LAYER:
					return this.getNotificationKpisCount_byArea(bbox);
				default:
					return null;
			}
		},

		getKpisCount_byDatasourcetype(identifier, bbox) {
			switch (identifier) {
				case NOTIFICATIONS_LAYER:
					return this.getNotificationKpisCount_byDatasourcetype(bbox);
				default:
					return null;
			}
		},

		async updateKpis_byArea(identifier) {
			const bbox = this.bbox;

			try {
				const promise = this.getKpisCount_byArea(identifier, bbox);
				promise.then(rows => {
					const statusHistogram_byArea = KPI_EXTRACTOR_AREA.notifications_byArea(rows);
					const viewport = rows.reduce((sum, { count }) => sum + count, 0);
					this.$store.dispatch('setHistogram_byArea', { identifier, statusHistogram_byArea });
					this.$store.dispatch('setCounts_byArea', { identifier, viewport });
				});
			} catch (e) {
				console.error(e);
			} finally {
				this.$store.commit('removeLoadingProcess');
			}
		},

		async updateKpis_byDatasourcetype(identifier) {
			const bbox = this.bbox;

			try {
				const promise = this.getKpisCount_byDatasourcetype(identifier, bbox);
				promise.then(rows => {
					const statusHistogram_byDatasourcetype = KPI_EXTRACTOR_DATASOURCETYPE.notifications_byDatasourcetype(rows);
					const viewport = rows.reduce((sum, { count }) => sum + count, 0);

					this.$store.dispatch('setHistogram_byDatasourcetype', { identifier, statusHistogram_byDatasourcetype });
					this.$store.dispatch('setCounts_byDatasourcetype', { identifier, viewport });
				});
			} catch (e) {
				console.error(e);
			} finally {
				this.$store.commit('removeLoadingProcess');
			}
		},
		groupByDatasource(devicesDataset) {
			const groupedDevices = devicesDataset.reduce((r, { pmdatasourcetypename, pmdatasourcetypeid, ...rest }) => {
				if (!r[pmdatasourcetypeid]) {
					r[pmdatasourcetypeid] = { pmdatasourcetypename, pmdatasourcetypeid, group: [rest] };
				} else {
					r[pmdatasourcetypeid].group.push(rest);
				}
				return r;
			}, {});

			return Object.values(groupedDevices);
		}
	}
};
</script>

<style lang="postcss">
@import '../../styles/eiffel-variables.pcss';
.div-maps {
	display: flex;
	width: 100%;
	height: 100vh;
}
.mappanel {
	&__header {
		border-bottom: 1px solid var(--moderate);
	}

	&--fill-height {
		/*height: calc(100vh - 48px);*/
		height: 100vh;
		/*max-height: calc(100vh - 48px);*/
		max-height: 100vh;
	}
}
.map-container {
	& .ol-viewport {
		overflow-y: visible !important;
		overflow-x: visible !important;
	}
}
</style>
