<template>
	<!-- <div :style="appearance"> -->
	<v-card class="width-100 height-100 overflow-hidden" style="border-top-right-radius: 0px; border-top-left-radius: 0px">
		<!-- TODO: Change tab dynamics based on device model -->
		<div v-if="this.deviceObject.viz.featureProperties.model == satelliteModel || deviceObject.elasticacronym == satelliteType">
			<v-tabs center-active v-model="tab" height="32" class="mt-0 border-b" align-tabs="title">
				<v-tab v-for="item in deviceTabsSatellite" :ripple="false" :key="item.value" :value="item.value" class="v-tab-lg">
					{{ $t(item.label) }}
				</v-tab>
			</v-tabs>

			<v-card-text class="pa-0 overflow-hidden ma-0">
				<v-window v-model="tab">
					<v-window-item v-for="item in deviceTabsSatellite" :key="item.value" :value="item.value">
						<v-card class="pa-0" flat>
							<component :deviceObject="deviceObject" :is="item.component" :displayLocation="displayLocation" />
						</v-card>
					</v-window-item>
				</v-window>
			</v-card-text>
		</div>
		<div v-else-if="this.deviceObject.viz.featureProperties.model == 'pmtidepoints'">
			<v-tabs center-active height="32" class="mt-0 border-b" v-model="selectedTabDevice" align-tabs="title">
				<v-tab
					v-for="item in deviceTabsTidePoints"
					:key="item.value"
					:ripple="false"
					:ref="item.value"
					:href="'#' + item.value"
					class="v-tab-lg"
				>
					{{ $t(item.label) }}
				</v-tab>
			</v-tabs>
			<v-card-text class="pa-0 overflow-hidden ma-0" :style="panelStyle">
				<v-window v-model="selectedTabDevice">
					<v-window-item v-for="item in deviceTabsTidePoints" :key="item.value" :value="item.value">
						<v-card class="pa-0" flat>
							<component
								:deviceObject="deviceObject"
								:notificationObject="deviceObject"
								:is="item.component"
								:displayLocation="displayLocation"
							/>
						</v-card>
					</v-window-item>
				</v-window>
			</v-card-text>
		</div>
		<div v-else>
			<v-tabs center-active height="32" class="mt-0 border-b" v-model="selectedTabDevice" align-tabs="title">
				<v-tab v-for="item in deviceTabs" :key="item.value" :ripple="false" :ref="item.value" :href="'#' + item.value" class="v-tab-lg">
					{{ $t(item.label) }}
				</v-tab>
			</v-tabs>
			<!-- :value="item.value" -->

			<v-card-text class="pa-0 overflow-hidden ma-0" :style="panelStyle">
				<v-window v-model="selectedTabDevice">
					<v-window-item v-for="item in deviceTabs" :key="item.value" :value="item.value">
						<v-card class="pa-0" flat>
							<component
								:deviceObject="deviceObject"
								:notificationObject="deviceObject"
								:is="item.component"
								:displayLocation="displayLocation"
							/>
						</v-card>
						<!-- <v-card
							v-if="item.component == 'NotificationHistoric' && displayLocation == displayMode && selectedTabDevice == 7"
							class="pa-0"
							flat
						>
							<component
								:deviceObject="deviceObject"
								:notificationObject="deviceObject"
								:is="item.component"
								:displayLocation="displayLocation"
							/>
						</v-card>
						<v-card v-else class="pa-0" flat> </v-card> -->
					</v-window-item>
				</v-window>
			</v-card-text>
		</div>
	</v-card>
	<!-- </div> -->
</template>

<script>
import DeviceHistoric from './DeviceHistoric.vue';
import DeviceRealTime from './DeviceRealTime.vue';
import DevicePrediction from './DevicePrediction.vue';
import DeviceXAI from './DeviceXAI.vue';
import DeviceAdvanced from './DeviceAdvanced.vue';
import DeviceHistoricSatellite from './DeviceHistoricSatellite.vue';
import DeviceRealTimeSatellite from './DeviceRealTimeSatellite.vue';
import DevicePredictionSatellite from './DevicePredictionSatellite.vue';
import DeviceCompareAqi from './DeviceCompareAQI.vue';
import DeviceActionsLog from './DeviceActionsLog.vue';
import DeviceTidePoints from './DeviceTidePoint.vue';
import NotificationHistoric from '../notification/NotificationHistoric.vue';
import NotificationActive from '../notification/NotificationActive.vue';
// eslint-disable-next-line no-unused-vars
import { query_LastDataHistoric } from '@/utils/queries';
//import { lastData } from '@/api/databases_API';
import { CARD_CONTAINER_HEIGHT } from '@/lib/variables/panels';
import { obtainPanelRealTimeData, obtainIndexName, obtainPanelHistoricFirstData, obtainPanelPredictionsDateRange } from '@/api/common';
import QueriesSQLMixin from '@/mixins/QueriesSQLMixin';
import constants from '@/utils/constants';

import { lastData } from '@/api/databases_API';

export default {
	name: 'DeviceIndex',
	mixins: [QueriesSQLMixin],
	components: {
		DeviceHistoric,
		DeviceAdvanced,
		DeviceXAI,
		DeviceRealTime,
		DevicePrediction,
		DeviceHistoricSatellite,
		DeviceRealTimeSatellite,
		DevicePredictionSatellite,
		DeviceCompareAqi,
		NotificationHistoric,
		NotificationActive,
		DeviceActionsLog,
		DeviceTidePoints
	},
	props: {
		deviceObject: {
			type: Object,
			required: true
		},
		displayLocation: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			appearance: {
				height: '100%',
				width: '100%',
				overflow: 'hidden'
				//position: 'absolute'
			},
			satelliteType: constants.DATASOURCETYPE_SATELLITE,
			satelliteModel: constants.SATELLITE_MODEL,
			panelStyle: {
				height: CARD_CONTAINER_HEIGHT,
				overflow: 'scroll'
			},
			tab: null,
			tabIndex: null,
			deviceTabs: [
				{ label: 'panels.device.realTime', component: 'DeviceRealTime', value: constants.FEATURE_TABS.REAL_TIME }
				/* { label: 'panels.device.historic', component: 'DeviceHistoric', value: constants.FEATURE_TABS.HISTORIC },
				{ label: 'panels.device.advancedChart', component: 'DeviceAdvanced', value: constants.FEATURE_TABS.ADVANCED } */
			],
			deviceTabsTidePoints: [{ label: 'panels.device.predictions', component: 'DeviceTidePoints', value: constants.FEATURE_TABS.PREDICTIONS }],
			deviceTabsSatellite: [
				/*{ label: 'panels.device.historic', component: 'DeviceHistoricSatellite', value: 0 },
				{ label: 'panels.device.realTime', component: 'DeviceRealTimeSatellite', value: 1 },
				{ label: 'panels.device.predictions', component: 'DevicePredictionSatellite', value: 0 }*/
			],
			formAdvanced: {
				startDate: null,
				endDate: null,
				selectedOptions: [],
				option: null,
				chartData: null,
				designOption: {
					id: constants.VISUALIZATIONS.TIME_SERIES,
					name: 'ADVANCED_CHART_COMPARE_PROPERTIES'
				}
			},
			hasActionLogs: false,
			hasHistoricNotifications: false
		};
	},
	computed: {
		realTimeElasticData: {
			get() {
				return this.$store.getters.getRealTimeElasticData;
			},
			set(value) {
				this.$store.commit('setRealTimeElasticData', value);
			}
		},
		historicFirstDates() {
			return this.$store.getters.getHistoricFirstDates;
		},
		historicLastDates() {
			return this.$store.getters.getHistoricLastDates;
		},
		predictionFirstDates() {
			return this.$store.getters.getPredictionFirstDates;
		},
		predictionLastDates() {
			return this.$store.getters.getPredictionLastDates;
		},
		selectedTabDevice: {
			get() {
				return this.$store.getters.getSelectedTabDevice;
			},
			set(value) {
				this.$store.commit('setSelectedTabDevice', value);
			}
		},
		hasActiveNotifications() {
			return this.featureActiveNotifications || this.calledFromNotification;
		},
		featureActiveNotifications() {
			if (this.$store.getters.getNotifications && this.$store.getters.getNotifications.length > 0) {
				return this.$store.getters.getNotifications.filter(
					(notification) => notification.pmdatasourceinstanceid == this.deviceObject.pmdatasourceinstanceid
				);
			} else {
				return false;
			}
		},
		displayMode() {
			return this.$store.getters.getDisplayMode;
		},
		fullscreenDialog() {
			return this.$store.getters.getFullscreenDialog;
		},
		deviceActionLogs() {
			if (this.$store.getters.getActionsLogs && this.$store.getters.getActionsLogs.length > 0) {
				return this.$store.getters.getActionsLogs.filter((log) => log.pmdatasourceinstanceid == this.deviceObject.pmdatasourceinstanceid);
			}
			return null;
		}
	},
	watch: {
		tab: function () {
			console.log('device tab changed', this.tab);
		},
		selectedTabDevice: function () {
			console.log('selected tab changed', this.selectedTabDevice);
		},
		deviceObject: {
			handler(newValue, oldValue) {
				console.debug('deviceObject watcher', oldValue.code, newValue.code);
				if (newValue && newValue.code != oldValue.code) {
					let indexName = obtainIndexName(this.deviceObject);
					if (indexName) obtainPanelRealTimeData(this.deviceObject.code, this.deviceObject.pmdatasourcetypeid, indexName, this.$store);
					this.handleDeviceChange();
				}
			},
			deep: true
		}
	},
	beforeCreate() {
		this.$vuetify.lang.current = this.$store.getters.getSession.language;
	},
	mounted() {
		console.log('mounted device index', this.displayMode);
		console.log('spatialmodel', this.deviceObject.viz.featureProperties.model);
		this.$store.commit('setFormAdvancedObject', this.formAdvanced);
		this.$store.commit('setAdvancedHistoricElasticData', null);
		this.$store.commit('setAdvancedDatasourceElasticData', null);
		const indexName = obtainIndexName(this.deviceObject);
		console.log('info: indexName: ', indexName);
		if (indexName) {
			if (this.deviceObject.spatialmodel !== this.satelliteModel && this.deviceObject.elasticacronym !== this.satelliteType) {
				//this.obtainLastData(this.deviceObject.code);
				console.warn('realTimeElasticData index', this.realTimeElasticData, this.realTimeElasticData.length);
				if (this.realTimeElasticData.length === 0) {
					obtainPanelRealTimeData(this.deviceObject.code, this.deviceObject.pmdatasourcetypeid, indexName, this.$store);
				}
				if (this.historicFirstDates.length === 0) {
					obtainPanelHistoricFirstData(this.deviceObject.code, this.deviceObject.pmdatasourcetypeid, indexName, this.$store);
				}

				//console.log('mounted device', this.historicFirstDates, this.historicLastDates);
			} else {
				if (this.predictionFirstDates.length == 0 || this.predictionLastDates.length == 0) {
					obtainPanelPredictionsDateRange(this.deviceObject.code, this.deviceObject.pmdatasourcetypeid, indexName, this.$store);
				}
			}
		} else {
			this.$puiNotify.error(this.$t('common.noElasticIndex'));
		}
		if (this.$store.getters.getPermissions['APP_DEVICE_HISTORIC']) {
			this.deviceTabs.push({ label: 'panels.device.historic', component: 'DeviceHistoric', value: constants.FEATURE_TABS.HISTORIC });
		}
		if (this.$store.getters.getPermissions['APP_DEVICE_ADVANCED']) {
			this.deviceTabs.push({ label: 'panels.device.advancedChart', component: 'DeviceAdvanced', value: constants.FEATURE_TABS.ADVANCED });
		}
		//Add predictions XAI
		if (this.$store.getters.getPermissions['APP_DEVICE_PREDICTIONS']) {
			this.deviceTabs.push(
				{ label: 'panels.device.predictions', component: 'DevicePrediction', value: constants.FEATURE_TABS.PREDICTIONS },
				{ label: 'panels.device.xai', component: 'DeviceXAI', value: constants.FEATURE_TABS.XAI }
			);
		}

		if (this.$store.getters.getPermissions['APP_AQI']) {
			this.deviceTabs.push({ label: 'panels.device.compareAQI', component: 'DeviceCompareAqi', value: constants.FEATURE_TABS.AQI });
		}
		if (this.$store.getters.getPermissions['APP_NOTIFICATIONS'] && this.hasActiveNotifications) {
			this.deviceTabs.push({
				label: 'panels.notification.active',
				component: 'NotificationActive',
				value: constants.FEATURE_TABS.ACTIVE_NOTIFICATIONS
			});
			this.displayLocation == 'card' ? this.checkHistoricTabElastic() : this.checkHistoricTabStore();
		}
		this.checkDeviceActions();
		console.log('mounted device ' + this.displayLocation, this.featureActiveNotifications, this.deviceTabs);
	},
	updated() {
		console.info('updated device index: ', this.deviceObject);
	},
	beforeDestroy() {
		console.warn('beforeDestroy device');
	},
	methods: {
		checkHistoricTabElastic() {
			lastData(constants.HISTORICAL_NOTIFICATIONS_INDEX, query_LastDataHistoric(this.deviceObject.code)).then((result) => {
				console.info('checkHistoricTabElastic result', result, result.message.length > 0);
				if (result.message.length > 0 && typeof result.message !== 'string') {
					if (this.hasActionLogs) {
						this.deviceTabs.splice(this.deviceTabs.length - 1, 0, {
							label: 'panels.notification.historic',
							component: 'NotificationHistoric',
							value: constants.FEATURE_TABS.HISTORIC_NOTIFICATIONS
						});
					} else {
						this.deviceTabs.push({
							label: 'panels.notification.historic',
							component: 'NotificationHistoric',
							value: constants.FEATURE_TABS.HISTORIC_NOTIFICATIONS
						});
					}
					/* this.deviceTabs.push({
						label: 'panels.notification.historic',
						component: 'NotificationHistoric',
						value: constants.FEATURE_TABS.HISTORIC_NOTIFICATIONS
					}); */
					this.$store.commit('setHasHistoricNotifications', result.message);
					this.hasHistoricNotifications = true;
				} else {
					this.$store.commit('setHasHistoricNotifications', null);
					this.hasHistoricNotifications = true;
				}
			});
		},
		checkHistoricTabStore() {
			console.info('checkHistoricTabStore result', this.$store.getters.getHasHistoricNotifications);
			if (
				this.$store.getters.getHasHistoricNotifications &&
				this.$store.getters.getHasHistoricNotifications.length > 0 &&
				!this.hasHistoricNotifications
			) {
				if (this.hasActionLogs) {
					this.deviceTabs.splice(this.deviceTabs.length - 1, 0, {
						label: 'panels.notification.historic',
						component: 'NotificationHistoric',
						value: constants.FEATURE_TABS.HISTORIC_NOTIFICATIONS
					});
				} else {
					this.deviceTabs.push({
						label: 'panels.notification.historic',
						component: 'NotificationHistoric',
						value: constants.FEATURE_TABS.HISTORIC_NOTIFICATIONS
					});
				}
				this.hasHistoricNotifications = true;
			} else this.hasHistoricNotifications = false;
		},
		checkDeviceActions() {
			console.log('debug: checkDeviceActions', this.deviceActionLogs, this.hasActionLogs);
			if (this.deviceActionLogs && this.deviceActionLogs.length > 0) {
				console.log('actions log', this.deviceActionLogs);
				if (!this.hasActionLogs) {
					this.deviceTabs.push({
						label: 'panels.device.actionsLog',
						component: 'DeviceActionsLog',
						value: constants.FEATURE_TABS.ACTIONS_LOG
					});
					this.hasActionLogs = true;
				} else {
					this.hasActionLogs = false;
				}
			} else {
				this.hasActionLogs = false;
			}
		},
		handleDeviceChange() {
			this.deviceTabs = [{ label: 'panels.device.realTime', component: 'DeviceRealTime', value: constants.FEATURE_TABS.REAL_TIME }];
			if (this.$store.getters.getPermissions['APP_DEVICE_HISTORIC']) {
				this.deviceTabs.push({ label: 'panels.device.historic', component: 'DeviceHistoric', value: constants.FEATURE_TABS.HISTORIC });
			}
			if (this.$store.getters.getPermissions['APP_DEVICE_ADVANCED']) {
				this.deviceTabs.push({ label: 'panels.device.advancedChart', component: 'DeviceAdvanced', value: constants.FEATURE_TABS.ADVANCED });
			}
			//Add predictions XAI
			if (this.$store.getters.getPermissions['APP_DEVICE_PREDICTIONS']) {
				this.deviceTabs.push(
					{ label: 'panels.device.predictions', component: 'DevicePrediction', value: constants.FEATURE_TABS.PREDICTIONS },
					{ label: 'panels.device.xai', component: 'DeviceXAI', value: constants.FEATURE_TABS.XAI }
				);
				/* this.deviceTabs = [
					{ label: 'panels.device.realTime', component: 'DeviceRealTime', value: constants.FEATURE_TABS.REAL_TIME },
					{ label: 'panels.device.historic', component: 'DeviceHistoric', value: constants.FEATURE_TABS.HISTORIC },
					{ label: 'panels.device.predictions', component: 'DevicePrediction', value: constants.FEATURE_TABS.PREDICTIONS },
					{ label: 'panels.device.advancedChart', component: 'DeviceAdvanced', value: constants.FEATURE_TABS.ADVANCED },
					{ label: 'panels.device.xai', component: 'DeviceXAI', value: constants.FEATURE_TABS.XAI }
				]; */
			}
			// Add notification tabs

			if (this.$store.getters.getPermissions['APP_AQI']) {
				this.deviceTabs.push({ label: 'panels.device.compareAQI', component: 'DeviceCompareAqi', value: constants.FEATURE_TABS.AQI });
			}
			if (this.$store.getters.getPermissions['APP_NOTIFICATIONS'] && this.hasActiveNotifications) {
				this.deviceTabs.push({
					label: 'panels.notification.active',
					component: 'NotificationActive',
					value: constants.FEATURE_TABS.ACTIVE_NOTIFICATIONS
				});
				this.displayLocation == 'card' ? this.checkHistoricTabElastic() : this.checkHistoricTabStore();
			}
			this.checkDeviceActions();
		}
	}
};
</script>

<style lang="postcss" scoped>
.v-tab {
	max-width: 400px;
}
</style>
