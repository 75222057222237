import { search, obtainIndexName } from '@/api/common';
import constants from '@/utils/constants';
import { lastData } from '@/api/databases_API';
import { query_LatestByFieldValue } from '@/utils/queries';
import ol2map from '@/components/map/sections/map/subcomponents/ol2map';

export default {
	data() {
		return {};
	},
	computed: {},
	methods: {
		async getSpatialTablesAndCreateVectorLayers() {
			let filterUser = null;
			if (!this.isSuperAdmin) {
				filterUser = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid },
						{ field: 'usr', op: 'eq', data: this.session.usr },
						{ field: 'idiom', op: 'eq', data: this.session.language },
						{ field: 'disabled', op: 'eq', data: 0 },
						{ field: 'datasourcetypedisabled', op: 'eq', data: 0 }
					]
				};
			}

			const body = {
				model: 'pmspatialtables',
				filter: filterUser
			};

			const { data } = await search(body);
			const layersObject = this.createObjectLayers(data.data);
			this.storedLayers = layersObject;
			this.layers = layersObject;

			if (this.$store.getters.getPermissions['APP_NOTIFICATIONS']) {
				let filterNotification = {};
				if (!this.isSuperAdmin) {
					filterNotification = {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }]
					};
				}
				let body = {
					model: 'pmnotifications',
					filter: filterNotification,
					rows: 10000
				};
				search(body).then((result) => {
					if (Object.hasOwn(result, 'data')) {
						let sortedNotifications = result.data.data.sort((a, b) => parseFloat(b.priority) - parseFloat(a.priority));
						this.$store.commit('setNotifications', sortedNotifications);

						data.data.forEach((layer) => {
							if (layer.model == constants.NOTIFICATIONS_MODEL) {
								layersObject[layer.idlayer].hasNotifications = !!sortedNotifications.find(
									(notification) => notification.pmdatasourcetypeid == layer.pmdatasourcetypeid
								);
							}
						});
					}
				});

				this.layers = layersObject;
			}

			// Create vector layers (SpatialTablesMixin.js)
			let filterDevice = null;
			if (!this.isSuperAdmin) {
				filterDevice = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid },
						{ field: 'disabled', op: 'eq', data: 0 },
						{ field: 'datasourcetypedisabled', op: 'eq', data: 0 }
					]
				};
			} else {
				filterDevice = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'disabled', op: 'eq', data: 0 },
						{ field: 'datasourcetypedisabled', op: 'eq', data: 0 }
					]
				};
			}

			let bodyDevices = {
				model: 'pmdatasourceinstance',
				filter: filterDevice,
				rows: 1000
			};

			let response = await search(bodyDevices);
			try {
				let devices = response.data.data;
				let mobileDevices = devices.filter((device) => device.ismobile === 1);
				let mobileDevicesByDatasource = this.groupByDatasource(mobileDevices);

				let mobilePromises = [];
				mobileDevicesByDatasource.forEach((datasourceGroup) => {
					mobilePromises.push(lastData(obtainIndexName(datasourceGroup.group[0]), query_LatestByFieldValue('device.keyword')));
				});
				Promise.all(mobilePromises).then((resultMobiles) => {
					let mobileCoordinates = {};
					resultMobiles.forEach((response, i) => {
						if (typeof response.message !== 'string' && resultMobiles[i].message.length > 0) {
							response.message.forEach((result) => {
								let code = result.fields['device.keyword'][0];
								let coordinates = result['inner_hits'].latest.hits.hits[0]['_source'].location.coordinates;
								mobileCoordinates[code] = coordinates;
							});
						}
					});

					Object.keys(layersObject).forEach((lyrId) => {
						let datasourceDevices = devices.filter((device) => device.pmdatasourcetypeid == layersObject[lyrId].pmdatasourcetypeid);
						ol2map.createIconVectorLayer(
							lyrId,
							this.getVizLayer(
								layersObject[lyrId].geomtypename,
								layersObject[lyrId].projection,
								layersObject[lyrId].model,
								layersObject[lyrId].active,
								layersObject[lyrId].datasourcetypeicon,
								layersObject[lyrId].datasourcetypedisabled
							),
							datasourceDevices,
							mobileCoordinates
						);
					});
				});
			} catch (error) {
				console.error('Error creating layers: ', error);
			}

			return layersObject;
		},
		async getExternalLayersAndCreateVectorLayers() {
			let filterUser = null;
			if (!this.isSuperAdmin) {
				filterUser = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid },
						{ field: 'disabled', op: 'eq', data: 0 }
					]
				};
			}

			const body = {
				model: 'pmexternalrasterlayers',
				filter: filterUser
			};

			const { data } = await search(body);
			const externalLayersObject = this.createObjectExternalLayers(data.data);
			this.storedLayers = externalLayersObject;
			this.$store.commit('setExternalLayers', externalLayersObject);
		},
		async getDevicesAndCreateVectorLayers() {
			var devicesObject = [];
			if (this.$store.getters.getDevicesData && Object.keys(this.$store.getters.getDevicesData).length > 0) {
				devicesObject = this.createObjectDevices(this.$store.getters.getDevicesData);
			} else {
				let filterUser = null;
				if (!this.isSuperAdmin) {
					filterUser = {
						groups: [],
						groupOp: 'and',
						rules: [
							{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid },
							{ field: 'datasourcetypedisabled', op: 'eq', data: 0 },
							{ field: 'disabled', op: 'eq', data: 0 }
						]
					};
				} else {
					filterUser = {
						groups: [],
						groupOp: 'and',
						rules: [
							{ field: 'datasourcetypedisabled', op: 'eq', data: 0 },
							{ field: 'disabled', op: 'eq', data: 0 }
						]
					};
				}

				const body = {
					model: 'vlupdevicespatialmodel',
					filter: filterUser,
					rows: 999
				};

				const { data } = await search(body);
				if (data.data) {
					this.$store.commit('setDevicesData', data.data);
					devicesObject = this.createObjectDevices(data.data);
				}
			}

			this.storedDevices = devicesObject;

			this.$store.commit('setDevices', devicesObject);
			this.$store.commit('setHasDevices', Object.keys(devicesObject).length > 0);
		},
		async getAqiPointsAndCreateVectorLayers() {
			var aqiPointsObject = [];
			if (this.$store.getters.getAqiPoints.constructor === Array && Object.keys(this.$store.getters.getAqiPoints).length > 0) {
				aqiPointsObject = this.createObjectAqiPoints(this.$store.getters.getAqiPoints);
			} else {
				let filterUser = null;
				if (!this.isSuperAdmin) {
					filterUser = {
						groups: [],
						groupOp: 'and',
						rules: [
							{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid },
							{ field: 'disabled', op: 'eq', data: 0 }
						]
					};
				} else {
					filterUser = {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'disabled', op: 'eq', data: 0 }]
					};
				}

				const body = {
					model: 'pmaqipoints',
					filter: filterUser,
					rows: 999
				};

				const { data } = await search(body);
				if (data) {
					aqiPointsObject = this.createObjectAqiPoints(data.data);
				}
			}
			this.storedAqiPoints = aqiPointsObject;
			this.$store.commit('setAqiPoints', aqiPointsObject);
			this.$store.commit('setHasAqiPoints', Object.keys(aqiPointsObject).length > 0);
		},
		async getRasterLayers() {
			let filter;
			if (!this.isSuperAdmin) {
				filter = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid },
						{ field: 'disabled', op: 'eq', data: 0 },
						{ field: 'satellitedisabled', op: 'eq', data: 0 }
					]
				};
			} else {
				filter = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'disabled', op: 'eq', data: 0 },
						{ field: 'satellitedisabled', op: 'eq', data: 0 }
					]
				};
			}

			const body = {
				model: 'pmsatelliteimages',
				filter: filter,
				rows: 9999 // Para obtener todos los elementos
			};

			const { data } = await search(body);
			this.$store.commit('setRasterLayersData', data.data);
		},
		async getGeoJsonLayers() {
			let filter;
			if (!this.isSuperAdmin) {
				filter = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid },
						{ field: 'disabled', op: 'eq', data: 0 }
					]
				};
			} else {
				filter = {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'disabled', op: 'eq', data: 0 }]
				};
			}

			const body = {
				model: 'pmgeojsonimage',
				filter: filter,
				rows: 9999 // Para obtener todos los elementos
			};

			const { data } = await search(body);
			this.$store.commit('setGeoJsonLayersData', data.data);
		},

		createObjectLayers(data) {
			const object = data.reduce(
				(
					r,
					{
						model,
						idlayer,
						visibility,
						disabled,
						title,
						iconlegend,
						geomtypename,
						projection,
						pmdatasourcetypeid,
						datasourcetypedisabled,
						organizationname,
						datasourcetypeicon,
						description
					}
				) => {
					r[idlayer] = {
						model,
						identifier: idlayer,
						active: visibility == 1 ? true : false,
						disabled,
						disabledItem: disabled == 0 ? false : true /* false */,
						filtered: false,
						title,
						iconlegend,
						geomtypename,
						projection,
						pmdatasourcetypeid,
						datasourcetypedisabled: datasourcetypedisabled == 1 ? true : false,
						organizationname,
						datasourcetypeicon,
						description
					};
					return r;
				},
				{}
			);

			return object;
		},
		createObjectExternalLayers(data) {
			const object = data.reduce((r, { idlayer, visibility, disabled, rastername, url, projection, organizationname, description }) => {
				r[idlayer] = {
					identifier: idlayer,
					active: visibility == 1 ? true : false,
					disabledItem: disabled == 0 ? false : true /* false */,
					filtered: false,
					rastername,
					url,
					projection,
					organizationname,
					description,
					absoluteURL: true
				};
				return r;
			}, {});

			return object;
		},
		createObjectDevices(data) {
			const object = data.reduce(
				(
					r,
					{
						code,
						disabled,
						icon,
						name,
						pmdatasourcetypename,
						pmdatasourcetypeid,
						latitude,
						longitude,
						organizationname,
						datasourcetypeicon,
						pmareaname,
						pmareatypename,
						ismobile
					}
				) => {
					r[code] = {
						identifier: code,
						active: disabled == 0 ? true : false,
						disabledItem: disabled == 0 ? false : true /* false */,
						filtered: false,
						icon,
						name,
						pmdatasourcetypename,
						pmdatasourcetypeid,
						latitude,
						longitude,
						organizationname,
						datasourcetypeicon,
						pmareaname,
						pmareatypename,
						ismobile
					};
					return r;
				},
				{}
			);
			return object;
		},
		createObjectAqiPoints(data) {
			const object = data.reduce((r, { pmaqipointsid, name, longitude, latitude, acronym, organizationname, disabled }) => {
				r[name.toLowerCase().replace(/\s/g, '') + pmaqipointsid] = {
					identifier: name.toLowerCase().replace(/\s/g, '') + pmaqipointsid,
					active: disabled == 0 ? true : false,
					disabledItem: disabled == 0 ? false : true /* false */,
					filtered: false,
					name,
					longitude,
					latitude,
					acronym,
					organizationname,
					disabled
				};
				return r;
			}, {});

			return object;
		}
	}
};
